import React, { useState, useCallback, useRef, useContext,useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
// import Color from 'color';
import { DealCard } from "./DealCard";
import DealsFilter from "./DealsFilter";
import { useHistory } from "react-router-dom";
import { CurrencyValue } from "../shared/TextMask";
import CircularProgress from "@material-ui/core/CircularProgress";
import { SECONDARYCOLOR, BASECOLOR } from "../shared/Constants";
import { FinanceContext } from "../shared/contexts/FinanceContext";
import { useMediaQuery } from "react-responsive";
import Carousel from "../homePage/Carousel";
import carouselImage from "./../shared/assets/home-slide-1.jpg";
import { BASE_URI, PAGE_SIZE, DEALERID, DEALERNAMECLEANED } from "../shared/Constants";
import axios from "axios";
import SlidePromotion from "../homePage/SlidePromotion";

const useStyles = makeStyles((theme) => ({
  background: {

    padding: theme.spacing(10, 0, 6),
  },
  actionArea: {
    borderRadius: 16,
    height: "100%",
    transition: "0.2s",
    "&:hover": {
      transform: "scale(1.05)",
    },
  },
  card: ({ color }) => ({
    minWidth: 256,
    //height: "100%",
    borderRadius: 5,
    boxShadow: "none",
    "&:hover": {
      // boxShadow: `0 6px 12px 0 ${Color(color)
      //   .rotate(-12)
      //   .darken(0.2)
      //   .fade(0.5)}`,
    },
  }),
  content: ({ color }) => {
    return {
      backgroundColor: `${SECONDARYCOLOR}`,
      padding: "1rem 1.5rem 1.5rem",
      textAlign: "center",
      height: "100%",
    };
  },
  title: {
    fontSize: "2rem",
    color: "white",
    textTransform: "uppercase",
  },
  subtitle: {
    color: "white",
    opacity: 0.87,
    marginTop: "1.2rem",
    fontWeight: 500,
    fontSize: 20,
  },
  image: {
    minHeight: 200,
  },
  centerFlex:{
    justifyContent:'center',
    paddingTop: '10px'
  }
}));

const useGridStyles = makeStyles(({ breakpoints }) => ({
  root: {
    [breakpoints.up("md")]: {
      // justifyContent: 'center',
    },
  },
}));

export default function SpecialDealsComponent() {
  const [loadingSlides, setLoadingSlides] = useState(true);
  const classes = useStyles();
  const gridStyles = useGridStyles({ color: "#ff9900" });
  const history = useHistory();
  const [sortDirection, setSortDirection] = useState("Desc");
  const [pageNumber, setPageNumber] = useState(1);
  const { loading, deals, hasMore } = DealsFilter(pageNumber, sortDirection);
  const observer = useRef();
  let { globalVehicles } = useContext(FinanceContext);
  const [slides, setSlides] = useState([]);
  const isMobile = useMediaQuery({ query: "(max-width: 624px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 912px)" });


  useEffect(() => {
  
    const getSlides = async () => {
      try {
        const params = {
          pageNumber: 0,
          pageSize: 100,
          status: "active",
          dealerId: DEALERID,
        };

        const result = await axios({
          method: "GET",
          url: `${BASE_URI}/slide`,
          params,
 
        });

        setLoadingSlides(false);

        var slides = result.data.list.map((itm) => {
          return {
            img: itm.slideImageUrl,
            url: itm.url,
            content: (
              <SlidePromotion
                vehicleName={itm.make}
                tagline={itm.cta}
                price={""}
                url={itm.url}
                callToAction={itm.buttonText}
              />
            ),
          };
        });

        setSlides(slides);
      } catch (error) {
        setLoadingSlides(false);
      }
    };

    getSlides();


  }, []);

  const lastDealRef = useCallback(
    (node) => {
      // if (loading) return
      // if (observer.current) {
      //   observer.current.disconnect()
      // }
      // observer.current = new IntersectionObserver(entries => {
      //   if (entries[0].isIntersecting && hasMore) {
      //     setPageNumber(prevPageNumber => prevPageNumber + 1)
      //   }
      // })
      // if (node) {
      //   observer.current.observe(node)
      // }
    },
    [loading, hasMore]
  );

  const cleanModel = (model) => {
    return model.toLowerCase().replace(/\//g, "-").replace(/ /g, "-");
  };

  const onSlideClick = (index) => {
    let redirectUrl = slides[index].content?.props?.url;
    if (redirectUrl) {
      history.push(redirectUrl.toLowerCase());
    }
  };

  return (
    <React.Fragment>
      <main>
      <Carousel
              naturalSlideWidth={100}
              className={classes.carousel}
              naturalSlideHeight={isMobile ? 105 : isTabletOrMobile ? 38 : 32}
              totalSlides={slides.length}
              isPlaying={true}
              slideRedirect={true}
              infinite
              slides={slides}
              onSlideClick={(index) => onSlideClick(index)}
            />


        <Container maxWidth="lg">
          
          <div className={classes.background}>
          <Typography variant="h1" style={{alignSelf: 'center', fontSize: '42px', fontWeight: 600 }}>
      KIA SPECIALS
     </Typography>
     <hr />
            {/* <Typography
              gutterBottom
              variant="h4"
              color="textPrimary"
              fontWeight="500"
            >
              <Box
                fontWeight={500}
                mb={5}
                style={{ textTransform: "capitalize" }}
              >
                Current financed offers
              </Box>
            </Typography> */}
            <Grid classes={gridStyles} container spacing={4} className={classes.centerFlex}>
              {!loading &&
                deals.length > 0 &&
                deals.map((deal) => (
                  <Grid
                    item
                    key={deal.id}
                    xs={12}
                    sm={6}
                    md={4}
    
                  >
                    <DealCard
                      ref={lastDealRef}
                      onClick={() =>
                        history.push({
                          pathname: `deal/${cleanModel(deal.model)}/${
                            deal.id
                          }`
                        })
                      }
                      classes={classes}
                      title={deal.headline}
                      subtitle={
                        <CurrencyValue
                          value={parseInt(deal.monthlyInstallment)}
                        />
                      }
                      image={deal.imageUrl}
                    />
                  </Grid>
                ))}
              {!loading && deals.length && deals.length === 0 && (
                <Grid item xs={12}>
                  <Typography
                    gutterBottom
                    variant="h5"
                    color="textPrimary"
                    fontWeight="500"
                  >
                    <Box style={{ textTransform: "capitalize" }}>
                      No available deals
                    </Box>
                  </Typography>
                </Grid>
              )}
              {loading && (
                <Grid
                  item
                  container
                  justify="center"
                  alignContent="center"
                  xs={12}
                >
                  <CircularProgress size={80} />
                </Grid>
              )}
            </Grid>
          </div>
        </Container>
      </main>
    </React.Fragment>
  );
}
