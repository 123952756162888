import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import {BASE_URI,MASTER_DEALERID,DEALERID,MOTORGROUPID} from '../Constants.js'

export const VehicleContext = createContext();

export const VehicleProvider = ({ children }) => {

    const [vehiclesList, setVehiclesList] = useState([]);
    const [brandedList, setBrandedList] = useState([]);
    const [newVehiclesList, setNewVehiclesList] = useState([]);
    const [loading, setLoading] = useState(false);

    const dealerList = [20,25,26,27]
    useEffect(() => {
        let arr = []
            const params = {
                pageNumber: 1,
                pageSize: 1000,
                // motorGroupId:MOTORGROUPID - removed since this is not a group site but a single dealer site.
                motorGroupId:MOTORGROUPID
                //dealerId: DEALERID
            }
            // if (globalDealer?.id != DEALERID) {
            //     params.dealerId = globalDealer.id;
            // } else {
            //     params.motorGroupId = MOTORGROUPID;
            // }
            setLoading(true);
            axios({
                method: 'GET',
                url: `${BASE_URI}/stock`,
                params,
            }).then((response) => {
                // Filtering data based on the dealerId using the includes method with dealerList
                const data = response?.data?.list?.filter((v) => dealerList.includes(v?.dealerId));

                //Sets the filtered data to vehiclesList
                setVehiclesList(data);
                //Sets the filtered data to BrandedList
                setBrandedList(data);

            }).catch(error => {
                if (axios.isCancel(error)) return
            }).then(() => {setLoading(false);})
    
    }, [])
    
    useEffect(() => {
        const params = {
            pageNumber: 1,
            dealerId: 286,
            makeName: 'Kia',
            isDash: true,
            pageSize: 1000,
        }
        // if (globalDealer?.id != DEALERID) {
        //     params.dealerId = globalDealer.id;
        // } else {
        //     params.motorGroupId = MOTORGROUPID;
        // }
        setLoading(true);
        axios({
            method: 'GET',
            url: `${BASE_URI}/dealermodels`,
            params,
  
        }).then((response) => {
            setNewVehiclesList(response.data?.list);
    
    
        }).catch(error => {
            if (axios.isCancel(error)) return
            
        }).then(() => {setLoading(false);})

    
}, [])

    return (
        <VehicleContext.Provider value={{ vehiclesList, setVehiclesList, setBrandedList, brandedList, newVehiclesList }}>
            {children}
        </VehicleContext.Provider>
    )
};