import React, { createContext, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

export const FinanceContext = createContext();

export const FinanceProvider = ({ children }) => {
  const getItem = (itm) => {
    let data = localStorage.getItem(itm);

    if (data && data != "undefined") return JSON.parse(data);
    else return {};
  };

  const [globalFinance, setGlobalFinance] = useState(getItem("globalFinance"));
  const [globalVehicle, setGlobalVehicle] = useState(getItem("globalVehicle"));
  const [globalColor, setGlobalColor] = useState(getItem("globalColor"));
  const [globalModel, setGlobalModel] = useState(getItem("globalModel"));
  const [globalVariantId, setGlobalVariantId] = useState(0);
  const [globalLead, setGlobalLead] = useState(getItem("globalLead"));
  const [globalVehicles, setGlobalVehicles] = useState(
    getItem("globalVehicles")
  );

  useEffect(() => {
    localStorage.setItem("globalFinance", JSON.stringify(globalFinance));
  }, [globalFinance]);

  useEffect(() => {
    localStorage.setItem("globalVehicle", JSON.stringify(globalVehicle));
  }, [globalVehicle]);

  useEffect(() => {
    localStorage.setItem("globalLead", JSON.stringify(globalLead));
  }, [globalLead]);

  useEffect(() => {
    localStorage.setItem("globalColor", JSON.stringify(globalColor));
  }, [globalColor]);

  useEffect(() => {
    localStorage.setItem("globalVehicles", JSON.stringify(globalVehicles));
  }, [globalVehicles]);

  return (
    <FinanceContext.Provider
      value={{
        globalFinance,
        setGlobalFinance,
        globalColor,
        setGlobalColor,
        globalVariantId,
        setGlobalVariantId,
        globalVehicle,
        setGlobalVehicle,
        globalLead,
        setGlobalLead,
        globalModel,
        setGlobalModel,
        globalVehicles,
        setGlobalVehicles,
      }}
    >
      {children}
    </FinanceContext.Provider>
  );
};
