import React, {
  useState,
  useCallback,
  useRef,
  useEffect,
  useContext,
} from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import SettingsBackupRestore from "@material-ui/icons/SettingsBackupRestore";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import DirectionsCarIcon from "@material-ui/icons/DirectionsCar";
import banner from "../shared/assets/1bpqq646.png"
import { BASE_URI, PAGE_SIZE, DEALERID, DEALERNAMECLEANED } from "../shared/Constants";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import { ImageContainer } from "../shared/ImageContainer";
import { CurrencyValue } from "../shared/TextMask";
import { useHistory } from "react-router-dom";
import SlidePromotion from "./SlidePromotion";

import OurVehicles from "../ourVehiclesPage/OurVehiclesHomePage";
import OurSpecials from "../ourVehiclesPage/OurSpecialsHomePage";

import { SECONDARYCOLOR, BASECOLOR } from "../shared/Constants";
import { FinanceContext } from "../shared/contexts/FinanceContext";
import CardCarousel from "../shared/CardCarousel";
import logoIcon from "../shared/assets/box_icon.png";
import { NavLink as RouterLink } from "react-router-dom";
import CircleLoader from "react-spinners/CircleLoader";
import Link from "@material-ui/core/Link";

import logoIconD from "../shared/assets/box_icon_dark.png";

const useStyles = makeStyles((theme) => ({
  main: {
    lineHeight: "0px!important",
  },
  icon: {
    marginRight: theme.spacing(2),
  },
  introContentHolder: {
    background: " white",
    color: "#06141f",
  },
  introContent: {
    display: 'flex',
    padding: theme.spacing(6, 0, 6),
    justifyContent: 'center',
    flexDirection: 'column'
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },

  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  cardMedia: {
    paddingTop: "56.25%", // 16:9
  },
  cardContent: {
    flexGrow: 1,
    alignItems: "center",
    textAlign: "center",
  },
  footer: {
    backgroundColor: `${BASECOLOR}`,
    padding: theme.spacing(6),
  },
  flexCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  threeSteps: {
    backgroundColor: `${SECONDARYCOLOR}`,
    color: `${BASECOLOR}`,
  },
  redbtn: {
    backgroundColor: "transparent",
    color: `${BASECOLOR}`,

    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  featureDeal: {
    borderBottom: "1px solid #cccccc",
    paddingTop: "20px",
    paddingBottom: "20px",
    "&:last-child": {
      borderBottom: 0,
    },
  },
  xlbutton: {
    padding: "13px 36px",
    fontSize: "1.1rem",
    background: "#36434c",
    borderRadius: "5px",
  },
  noPadding: {
    paddingBottom: 0,
  },
  featureDealImage: {
    "@media (max-width: 599px)": {
      height: "auto",
    },
  },
  centeredContent: {
    "@media (max-width: 1279px)": {
      justifyContent: "center",
    },
  },
  dealHolderCard: {
    width: "calc(100% / 4)",
    maxWidth: "calc(100% / 4)",
    transition: "all 0.5s ease",
    cursor: "pointer",
    "@media (max-width: 600px)": {
      width: "calc(100% / 2)",
      maxWidth: "calc(100% / 2)",
      },
    "&:hover": {
      transform: "scale(1.05)",
    },
  },
  dealHolder: {
    display: "flex",
    justifyContent:'center'
  },

  loadingHolder: {
    display: "flex",
    position: "fixed",
    background: "#ffffff",
    height: "100vh",
    minHeight: "100vh",
    width: "100%",
    top: "0",

    justifyContent: "center",
    alignItems: "center",
    transition: "all 0.5s ease",
    opacity: (loadingPage) => (loadingPage ? 1 : 0),
    zIndex: (loadingPage) => (loadingPage ? "2000" : "-1"),
  },

  loadingHolderInner: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position:'relative'
  },
  loadingLogo:{
    position:'absolute',
    filter: 'invert(1)',
  },
  footerIconSectionLinkD:{
    color: `#06141f`,
  },
  footerIconSection: {
    backgroundColor: `${SECONDARYCOLOR}`,
  },
  footerIconSectionLink: {
    color: `${BASECOLOR}`,
  },
  card1: {
    alignItems: 'center',
    backgroundColor: "#06141f",
    height: "240px",
    display: "flex",
    cursor:"pointer",
    minWidth: 'calc(100% /4)',
    justifyContent: "center",
    flexDirection: "column",
    transition: "all 0.5s ease",
    textDecoration:'none',
    "&:hover": {
      //transform: 'translateY(-10px)',
    },
    "@media (max-width: 600px)": {
      minWidth: '100%',
      },
      "@media  (min-width: 601px) and (max-width: 768px)": {
        minWidth: '50%',
        },
  },
  card2: {
    backgroundColor: "#9ca1a5",
    height: "240px",
    alignItems: 'center',
    display: "flex",
    cursor:"pointer",
    width: 'calc(100% /4)',
    justifyContent: "center",
    flexDirection: "column",
       transition: "all 0.5s ease",
    "&:hover": {
      //transform: 'translateY(-10px)',
    },
    "@media (max-width: 600px)": {
      minWidth: '100%',
      },
      "@media  (min-width: 601px) and (max-width: 768px)": {
        minWidth: '50%',
        },
  },
  card3: {
    backgroundColor: "#06141f",
    height: "240px",
    alignItems: 'center',
    width: 'calc(100% /4)',
    display: "flex",
    cursor:"pointer",
    justifyContent: "center",
    flexDirection: "column",
       transition: "all 0.5s ease",
    "&:hover": {
      //transform: 'translateY(-10px)',
    },
    "@media (max-width: 600px)": {
      minWidth: '100%',
      },
      "@media  (min-width: 601px) and (max-width: 768px)": {
        minWidth: '50%',
        },
  },
  card4: {
    backgroundColor: "#9ca1a5",
    height: "240px",
    cursor:"pointer",
    width: 'calc(100% /4)',
    alignItems: 'center',
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
       transition: "all 0.5s ease",
    "&:hover": {
      //transform: 'translateY(-10px)',
    },
    "@media (max-width: 600px)": {
      minWidth: '100%',
      },
      "@media  (min-width: 601px) and (max-width: 768px)": {
        minWidth: '50%',
        },
  },
  divider:{
    width:'120px',
    background:'white',
    height:'2px',
    margin:'10px 0px'
  },
  dividerD:{
    width:'120px',
    background:'#06141f',
    height:'2px',
    margin:'10px 0px'
  },
  bannerHolder:{
    maxHeight:'calc(100vh - 76px)',
    overflow:'hidden'
  }
}));

export default function HomeComponent() {
  const [loading, setLoading] = useState(true);
  const [loadingPage, setLoadingPage] = useState(true);
  const [deals, setDeals] = useState([]);
  const classes = useStyles(loadingPage);
  const mountedRef = useRef(true);
  const history = useHistory();
  const [slides, setSlides] = useState([]);
  let { globalVehicles } = useContext(FinanceContext);

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    setLoading(true);
    const source = CancelToken.source();
    const getOffer = async () => {
      try {
        const params = {
          pageNumber: 0,
          pageSize: 100,
          status: "active",
          dealerId: DEALERID,
        };

        const result = await axios({
          method: "GET",
          url: `${BASE_URI}/Offers`,
          params,
          cancelToken: source.token,
        });

        setLoading(false);
        let data = result.data;
        setDeals(data.list);
      } catch (error) {
        setLoading(false);
      }
    };

    getOffer().then(() => {
      setLoading(false);
      if (!mountedRef.current) return null;
    });

    const getSlides = async () => {
      try {
        const params = {
          pageNumber: 0,
          pageSize: 100,
          status: "active",
          dealerId: DEALERID,
        };

        const result = await axios({
          method: "GET",
          url: `${BASE_URI}/slide`,
          params,
          cancelToken: source.token,
        });

        setLoading(false);

        var slides = result.data.list.map((itm) => {
          return {
            img: itm.slideImageUrl,
            url: itm.url,
            content: (
              <SlidePromotion
                vehicleName={itm.make}
                tagline={itm.cta}
                price={""}
                url={itm.url}
                callToAction={itm.buttonText}
              />
            ),
          };
        });

        setSlides(slides);
      } catch (error) {
        setLoading(false);
      }
    };

    getSlides();

    return () => {
      mountedRef.current = false;
      source.cancel();
    };
  }, []);

  useEffect(() => {
    document.documentElement.style.overflow = "hidden";
    setTimeout(() => {
      setLoadingPage(false);
      document.documentElement.style.overflow = "auto";
    }, 2000);
  }, []);




  const cleanModel = (model) => {
    return model.toLowerCase().replace(/\//g, "-").replace(/ /g, "-");
  };
  
  return (
    <>
      <div className={classes.loadingHolder}>
        <div className={classes.loadingHolderInner}>
        <CircleLoader color="#06141f " size="150"/>
          <img alt="Kia Loading" src={logoIcon} className={classes.loadingLogo}/>
        </div>
      </div>

      {!loadingPage && (
        <React.Fragment>
          <main className={classes.main}>
            {/* Hero unit */}
            <div className={classes.bannerHolder}>
              <img alt="Front cross right side view of silver KIA Sonet motor vehicle and front cross left side view of blue KIA Seltos motor vehicle" src={banner} width="100%"/>
            </div>
            <div className={classes.introContent}>
              <Typography variant="h1" style={{alignSelf: 'center', fontSize: '42px', fontWeight: 600 }}>Welcome to KIA Motors</Typography>
   
    
            </div>
            {/* <Container maxWidth="false" className={classes.introContentHolder}>
              <div className={classes.introContent}>
                <Typography
                  component="h1"
                  variant="h4"
                  align="center"
                  color="white"
                  gutterBottom
                >
                  Welcome to {DEALERNAMECLEANED} Motors, the future of the car buying
                  experience.
                </Typography>
                <Typography
                  align="center"
                  paragraph
                  gutterBottom
                  style={{ fontSize: "1.4rem" }}
                >
                  Our website is designed to assist you in obtaining finance
                  without the need to leave your home or office. Our dedicated
                  team are here to assist you every step of the way and we are
                  available on Live chat in the bottom right corner of this
                  page. Once your application is finalized we will deliver your
                  car to your home or office.
                </Typography>
                <Typography variant="subtitle2" align="center" gutterBottom>
                  <Box fontWeight="fontWeightBold">
                    We have a national network of dealers to assist you and we
                    accept trade-in’s and if you require a test drive, we come
                    to you!
                  </Box>
                </Typography>
                <div className={classes.heroButtons}>
                  <Grid container spacing={2} justify="center">
                    <Grid item>
                      <Button
                        className={classes.xlbutton}
                        size="large"
                        variant="contained"
                        color="primary"
                        href="/apply-for-finance"
                      >
                        Apply Now
                      </Button>
                    </Grid>
                    <Grid item style={{ display: "none" }}>
                      <Button
                        className={classes.xlbutton}
                        variant="contained"
                        color="primary"
                        style={{ display: "none" }}
                      >
                        Book a test drive
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        className={classes.xlbutton}
                        variant="contained"
                        color="primary"
                        href="/special-deals"
                      >
                        Browse our deals
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </Container> */}

            {loading && (
              <Grid
                container
                spacing={4}
                justify="center"
                alignContent="center"
              >
                <Grid item>
                  <CircularProgress size={80} />
                </Grid>
              </Grid>
            )}

<OurVehicles hide={true} />

<Box className={classes.footerIconSection}>
      <Grid container className={classes.footerIconSection}>

           
 <a href="tel:0573553620"
                     className={classes.card1}
                   
                    component={RouterLink} to="/contact-us"
                  >
          <Grid
              item
              xs={12}
              sm={6}
              md={3}
              align="center"
              className={classes.card1}
            >
              <Box >
              <img alt="Kia Logo" src={logoIcon} />
              </Box>
              <div className={classes.divider}></div>
              <Typography className={classes.footerIconSectionLink}>
                Call us now
              </Typography>
            </Grid>
              </a>

              <Link
                     className={classes.card2}
                   
                    component={RouterLink} to="/contact-us"
                  >
          <Grid
              item
              xs={12}
              sm={6}
              md={3}
              align="center"
              className={classes.card2}
            >
              <Box >
              <img alt="Kia Logo" src={logoIconD}/>
              </Box>
              <div className={classes.dividerD}></div>
              <Typography className={classes.footerIconSectionLinkD}>
              We're Online
              </Typography>
            </Grid>
              </Link>

       

            <Link
                     className={classes.card3}
                  
                    component={RouterLink} to="/book-a-testdrive"
                  >
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              align="center"
              className={classes.card3}
            >
              <Box >
              <img alt="Kia Logo" src={logoIcon} />
              </Box>
              <div className={classes.divider}></div>
              <Typography className={classes.footerIconSectionLink}>
                Book a Test Drive
              </Typography>
            </Grid>
            </Link>
      
              <Link
                     className={classes.card4}
                  
                    component={RouterLink} to="/contact-us"
                  >
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              align="center"
              className={classes.card4}
            >
              <Box >
              <img alt="Kia Logo" src={logoIconD} />
              </Box>
              <div className={classes.dividerD}></div>
              <Typography className={classes.footerIconSectionLinkD}>
              Find a Dealer
              </Typography>
            </Grid>
            </Link>
          </Grid>
        
      </Box>
      <div style={{width: '100%', display: 'flex', justifyContent: 'center', padding: 10}}>
        <Typography variant="h2" style={{alignSelf: 'center', fontSize: '42px', fontWeight: 300, paddingTop: 10 }}>KIA Special Deals</Typography>
      </div>

      <OurSpecials hide={true} />

            {/* {!loading && deals.length === 0 && (
              <Grid container spacing={4} justify="center">
                <Grid item xs={12}>
                  <Typography color="textSecondary">
                    No available deals
                  </Typography>
                </Grid>
              </Grid>
            )} */}
{/* 
            <div className={classes.dealHolder}>
              {deals.map((d) => {
                return (
                  <div className={classes.dealHolderCard}     onClick={() =>
                    history.push({
                      pathname: `deal/${cleanModel(d.model)}/${
                        d.id
                      }`
                    })
                  }>
                    <img src={d.imageUrl} width="100%" />
                  </div>
                );
              })}
            </div> */}
            {/* <div className={classes.threeSteps}>
              <Container maxWidth="lg" className={classes.cardGrid}>
                <Typography
                  gutterBottom
                  variant="h4"
                  align="center"
                  fontWeight="500"
                >
                  <Box
                    fontWeight={500}
                    style={{ textTransform: "uppercase", fontWeight: 700 }}
                  >
                    BUY YOUR CAR IN 3 EASY STEPS
                  </Box>
                </Typography>
                <Grid container spacing={4} style={{ marginTop: "1rem" }}>
                  <Grid item xs={12} sm={12} md={4} align="center">
                    <Box mb={2}>
                      <SettingsBackupRestore style={{ fontSize: 60 }} />
                    </Box>
                    <Typography gutterBottom variant="h6" align="center">
                      <Box fontWeight="fontWeightBold">PRE-APPROVAL</Box>
                    </Typography>
                    <Typography align="center">
                      Answer a few questions and we will tell you what you
                      qualify for.
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} align="center">
                    <Box mb={2}>
                      <CreditCardIcon style={{ fontSize: 60 }} />
                    </Box>
                    <Typography gutterBottom variant="h6" align="center">
                      <Box fontWeight="fontWeightBold">APPLY</Box>
                    </Typography>
                    <Typography align="center">
                      Apply online and receive a real time answer from SA's
                      leading banks.
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} align="center">
                    <Box mb={2}>
                      <DirectionsCarIcon style={{ fontSize: 60 }} />
                    </Box>
                    <Typography gutterBottom variant="h6" align="center">
                      <Box fontWeight="fontWeightBold">DRIVE</Box>
                    </Typography>
                    <Typography align="center">
                      We will deliver your vehicle to your home or office in 24
                      hours.
                    </Typography>
                  </Grid>
                  <Grid item xs={12} style={{ textAlign: "center" }}>
                    <Button
                      variant="contained"
                      size="large"
                      className={`${classes.redbtn} ${classes.xlbutton}`}
                      href="/apply-for-finance"
                    >
                      start now
                    </Button>
                  </Grid>
                </Grid>
              </Container>
            </div> */}
          </main>
         
        </React.Fragment>
      )}
    </>
  );
}
