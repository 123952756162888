import React, { useContext, useEffect, useState } from 'react';
import Container from '@material-ui/core/Container';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import PQContainer from '../shared/PQContainer'
import { useParams, useHistory } from "react-router-dom";
import Alert from '@material-ui/lab/Alert';
import { VIRTUAL_URI_DERIVATIVE, VIRTUAL_URI_RANGE, BASE_URI, DEALERID, MASTER_DEALERID, MOTORGROUPID } from '../shared/Constants'
import axios from 'axios';
import ApplySteps from '../shared/ApplySteps';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import { ClassSharp } from '@material-ui/icons';
import MFCLogo from '../shared/assets/mfc-logo.jpeg'
import { SECONDARYCOLOR, BASECOLOR } from '../shared/Constants'
import { FinanceContext } from "../shared/contexts/FinanceContext";
import { Finance } from "@red-build/leadcentre";
import PartsBanner from '../shared/assets/Finance.jpg'
import { DealerContext } from '../shared/contexts/DealerContext';

const useStyles = makeStyles((theme) => ({


  divider: {
    backgroundColor: `${SECONDARYCOLOR}`,
    width: '33%',
    marginBottom: '30px',
    height: '3px',
    margin: '20px auto'
  },
  success: {
    color: 'green',
    justifyContent: 'center'
  },
  yellow: {
    color: `${SECONDARYCOLOR}`
  },
  topHeader: {
    fontWeight: 700,
    textTransform: 'uppercase',
    textAlign: 'center',
    fontSize: '2.5rem',
    marginBottom: 0
  },
  arrow: {
    width: 0,
    height: 0,
    borderLeft: '30px solid transparent',
    borderRight: '30px solid transparent',
    borderTop: `30px solid ${SECONDARYCOLOR}`,
    margin: '0 auto'
  },
  stepsIcon: {
    '& svg': {
      fontSize: '5rem',
      border: `2px solid ${SECONDARYCOLOR}`,
      borderRadius: '1rem',
      padding: '0.4rem'
    }
  },
  root: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    paddingTop: '60px',
    paddingBottom: '60px',

    backgroundImage: `url(${PartsBanner})`,
    backgroundColor: 'rgb(0 0 0 / 50%);',
    backgroundBlendMode: 'overlay',
    backgroundSize: "cover",
    backgroundPosition: 'center',
  },
  innerSection: {
    width: "100%",
    maxWidth: "1170px",
    display: "flex",
    justifyContent: "space-between",
    padding: "6px 15px",

    '@media (max-width: 959px)': {
      flexWrap: 'wrap',
      padding: "0px 0px",
    },
  },
  section: {
    width: '60%',

    '&:nth-child(1)': {
      marginRight: '40px',
      width: '40%',
      color: 'white',
    },
    '@media (max-width: 959px)': {
      width: '100%',
      margin: '15px',

      '&:nth-child(1)': {
        margin: '0px',
        padding: '0px 20px',
        width: '100%',

      },
    },
  },
  text: {
    fontSize: '16px',
  },
  titleTop: {
    fontSize: '44px',
    fontWeight: 'regular'
  },
  titleTopAccent: {
    fontSize: '52px',
    color: 'white',

    fontWeight: 'bold'
  },
  imgBlockHolder: {
    color: "#36434c",
    fontSize: "26px",
    fontWeight: "bold",
    marginBottom: "10px",
    maxWidth: "490px",
    margin: "auto",
    display: "flex",
    padding: "20px",
    justifyContent: "center",
    maxWidth: "490px",
    flexWrap: "wrap",
  },
  FinanceHolder: {
    background: 'white',
    padding: '20px',
    borderRadius: '5px',
    paddingRight: '5px'
  }
}));

const ApplyFinance = () => {
  const classes = useStyles();

  let { globalVehicles } = useContext(FinanceContext);
  let { dealerList } = useContext(DealerContext);
  const history = useHistory()


  const { leadId, colorId } = useParams(false);

  const stepsState = {
    one: {
      active: true,
      completed: false
    },
    two: {
      active: false,
      completed: false
    },
    three: {
      active: false,
      completed: false
    }
  }



  const vehicle = history.location.state;

  return (

    <div className={classes.root}>
      <div className={classes.innerSection}>
        <div className={classes.section} >
          <Typography variant="h1" className={classes.titleTopAccent}>
            APPLY FOR FINANCE ONLINE
          </Typography>

          <Typography variant="h2" className={classes.titleTop}>
            NEED FINANCE?
          </Typography>
          <Typography variant="body2" className={classes.text}>
            Leave your details, we will get back to you.

          </Typography>

        </div>
        <div className={classes.section}>


          <div className={classes.FinanceHolder}>
            <Finance
              masterDealerId={MASTER_DEALERID}
              pqType={"interim_pq"}
              dealerModels={globalVehicles}
              dealers={dealerList}
              base_uri={BASE_URI}
              dealerType={"multi_dealer"}
              variantId={vehicle?.variantId}
              offerId={vehicle?.offerId}
            />
          </div>
        </div>
      </div>
    </div>

  )
};

export default ApplyFinance;