import React, { useState, useCallback, useRef, useContext, useEffect } from "react";
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, Image, DotGroup } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { makeStyles } from '@material-ui/core/styles';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { useParams, useHistory } from "react-router-dom";
import Link from '@material-ui/core/Link';
import { FinanceContext } from "../shared/contexts/FinanceContext";
import { TreeItem } from "@material-ui/lab";
import DealsFilter from "../specialDeals/DealsFilter";
import VehicleController from "../ourVehiclesPage/VehicleController";

const useStyles = makeStyles((theme) => ({
    sliderContainer: {
        
        background: '#06141f',
        lineHeight:'0px',
        position: 'relative',
        //height: ({ carouselHeight }) => `${carouselHeight}px`,
        "& .carousel__slider":{
            height:'100%',
            lineHeight:'0px',
        }
      
    },
    carouselBack: {
        left: "1%",
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%);",
        all: "unset",
        cursor: "pointer",
        backgroundColor: `#06141f`,
        color: "white",
        width: "42px",
        height: "42px",
        opacity: "0.4",
        "&:focus": {},
        "&:hover": {
            opacity: "1",
        },
    },
    carouselNext: {
        right: "1%",
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%);",
        all: "unset",
        cursor: "pointer",
        backgroundColor: `#06141f`,
        color: "white",
        width: "42px",
        height: "42px",
        opacity: "0.4",
        "&:focus": {},
        "&:hover": {
            opacity: "1",
        },
    },
    carouselDots: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '15px',
        marginBottom: '15px',
        '& > button': {
            width: '15px',
            height: '15px',
            borderRadius: '50%',
            border: 'none',
            padding: '0',
            cursor: 'pointer',
            backgroundColor: '#EFDF00',
            marginRight: '10px',
        }
    },
    backgroundImage: {

      
        '@media (max-width: 624px)': {
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'top',
            
        },
    },
     slide: {
        paddingBottom: '6.33333%!important',

        '@media (max-width: 624px)': {
            paddingBottom:'6.5%!important',
       
        },

     },
}));


const cleanModel = (model) => {
    return model.toLowerCase().replace(/\//g, "-").replace(/ /g, "-");
};

const Carousel = ({ slides, innerClass, dots, ...props }) => {
    const { vehicles, total } = VehicleController();
    const { innerWidth: defaultWidth } = window;     
    const defaultHeight = defaultWidth/2.6;

    let { globalVehicles } = useContext(FinanceContext);
    const [carouselHeight,setCarouselHight]= useState(defaultHeight);
    const [sortDirection, setSortDirection] = useState("Desc");
    const [pageNumber, setPageNumber] = useState(1);
    const { loading, deals, hasMore } = DealsFilter(pageNumber, sortDirection);

    function handleResize() {
        const { innerWidth: width } = window;
        const height = width / 2.6;
        setCarouselHight(height)
      }
      window.addEventListener('resize', handleResize);


    const classes = useStyles({carouselHeight});
    const history = useHistory()

    return (
        <CarouselProvider
            {...props}
        >
            <div className={classes.sliderContainer}>
                <Slider  >
                    {
                        slides && slides?.map((slide, index) => {
                          

                            return (
                                
                                <Slide innerClassName={innerClass} className={classes.slide} index={index}
                       
                                    onClick={() =>
                                        history.push({
                                            pathname: `${slide.url}`,
                                          
                                        })
                                    }
                                >



                                    {slide.content}

                                    {slide.content ? <Image src={slide.img ? slide.img : slide} className={classes.backgroundImage} /> :
                                        <Image src={slide.img ? slide.img : slide} />}

                                </Slide>

                            )
                        }


                        )
                    }
                </Slider>
                {dots && <DotGroup className={classes.carouselDots} disableActiveDots={false} />}
                { slides.length > 0 ? (                
                  <>
                    <ButtonBack className={classes.carouselBack}><ChevronLeftIcon style={{ fontSize: 40 }} /></ButtonBack>
                    <ButtonNext className={classes.carouselNext}><ChevronRightIcon style={{ fontSize: 40 }} /></ButtonNext>
                  </>
                ) : null }
            </div>
        </CarouselProvider>
    )
}

export default Carousel;