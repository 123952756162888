import React from 'react';
import './App.css';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { BrowserRouter as Router } from 'react-router-dom';
import DashboardContainer from './components/MainContainer'
import KiaSignatureLight from './fonts/KiaSignatureLight.woff'
import KiaSignatureBold from './fonts/KiaSignatureBold.woff'
import CssBaseline from '@material-ui/core/CssBaseline';
import { SnackbarProvider } from 'notistack';
import ScrollToTop from './components/shared/ScrollToTop'
import Cookie from './components/shared/CookiePopUp'
import POPIAPopUp from './components/shared/POPIAPopUp'
import { SECONDARYCOLOR, BASECOLOR } from './components/shared/Constants'
import { DealerProvider } from "./components/shared/contexts/DealerContext";
import { VehicleProvider } from './components/shared/contexts/VehicleContext';
import { MetaTagsProvider } from '@red-build/leadcentre';

const renaultRegualar = {
  fontFamily: 'KiaSignatureLight',
  src: `
    local('Kia-Regular'),
    url(${KiaSignatureLight}) format('woff')
  `
}

const renaultBold = {
  fontFamily: 'KiaSignatureBold',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 700,
  src: `
    local('Kia-Bold'),
    url(${KiaSignatureBold}) format('woff')
  `
}

const theme = createTheme({
  typography: {
    fontFamily: [
      'KiaSignatureLight',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  overrides: {
    MuiTextField: {
      root: {
        marginBottom: '1rem'
      }
    },
    MuiTypography: {
      h4: {
        fontWeight: 700
      },
      h5: {
        textTransform: 'capitalize',
        fontWeight: 700
      },
      h6: {
        fontWeight: 700
      },
      subtitle2: {
        fontSize: '1rem'
      }
    },
    MuiDialogActions: {
      root: {
        padding: '1.5rem 1rem'
      }
    },
    MuiButton: {
      containedPrimary: {
        backgroundColor: `${SECONDARYCOLOR}`,
        color: `${BASECOLOR}`,
        borderRadius: '0px',
        '&:hover': {
          backgroundColor: `${SECONDARYCOLOR}`,
          opacity: 0.8
        }
      },
      contained: {
        borderRadius: '0px'
      }
    },
    MuiCssBaseline: {
      '@global': {
        '@font-face': [renaultRegualar, renaultBold],
        body: {
          backgroundColor: `${BASECOLOR}`,
          fontSize: '100%'
        }
      }
    }
  }
});

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Cookie />
      <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={3} anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}>
          <VehicleProvider>
            <CssBaseline />
            <DealerProvider>
              <MetaTagsProvider>
                <DashboardContainer />
              </MetaTagsProvider>
            </DealerProvider>
          </VehicleProvider>
        </SnackbarProvider>
      </ThemeProvider>

    </Router>
  );
}

export default App;
