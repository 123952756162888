export const BASE_URI = 'https://conexa.r-e-d.co.za/api';;
export const PAGE_SIZE = 21;
export const HAS_MULTIPLE_DEALERSHIPS = true;

export const QUALIFYING_FINANCE_PERCENTAGE = 0.33;
export const MASTER_DEALERID = 436;
export const DEALERID = 20;
export const DEALERNAME = "LSG Kia Master";
export const DEALERNAMECLEANED = "Kia";

export const DEALERMAKEID = 8113;
export const BASECOLOR = "#ffffff";
export const SECONDARYCOLOR = "#06141f";
export const ACCENTCOLOR = "#06141f";
export const MOTORGROUPID = 3;
export const MASTER_MOTORGROUPID = 3;
export const DEALER_NAME = 'Kia Motors Master'


export const FINAPP_URI_BASE = "https://dz6rhlcy38qhy.cloudfront.net";
export const FINAPP_URI_APPLICATION = `${FINAPP_URI_BASE}/${DEALERID}/1/vehicle/finance/0/:leadId`;

export const VIRTUAL_URI_RANGE = `https://configurator.renaultmotors.co.za/${DEALERID}/range/:leadId`;
export const VIRTUAL_URI_DERIVATIVE = `https://configurator.renaultmotors.co.za/${DEALERID}/:modelId/builder/:leadId`;
export const VIRTUAL_URI_APPLICATION = `https://configurator.renaultmotors.co.za/${DEALERID}/1/vehicle/finance/0/:leadId`;
