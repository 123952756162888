import React from 'react';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { NavLink as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import { BASE_URI, PAGE_SIZE, DEALERID ,DEALERNAME} from '../shared/Constants'
import { SECONDARYCOLOR, BASECOLOR} from '../shared/Constants'
const useStyles = makeStyles((theme) => ({
    content: {
        //backgroundColor: `${BASECOLOR}`,
        padding: theme.spacing(10, 0, 6),
    },
    link: {
        color: '#7c7c7c',
        textDecoration: 'none',
        '&:hover': {
            color: `${SECONDARYCOLOR}`,
            textDecoration: 'none'
        }
    }
}));

const Terms = () => {
    const classes = useStyles();

    return (
        <Container maxWidth="lg" >
            <div className={classes.content}>
                <Typography gutterBottom variant="h4" color="textPrimary" fontWeight="500">
                    LEGAL & COPYRIGHT NOTICE
                </Typography>
                <br/>
                <Typography gutterBottom variant="h5" color="textPrimary" fontWeight="500" >
                    Copyright
                </Typography>
                <p>
                All content included on this web site, such as text, graphics, logos, button icons, images, audio clips, and software, is the property of <strong>LS Group</strong> or its content suppliers and protected by South African and international copyright laws. Furthermore, the compilation (meaning the collection, arrangement, and assembly) of all content on this web site is the exclusive property of <strong>LS Group</strong> and protected by South Africa and international copyright laws.
                </p>
                <p>
                Without derogating from the above, <strong>LS Group</strong> authorises you to view, copy, download to a local drive, print and distribute the content of this web site, or parts thereof, provided that: Such content is used for information purposes only; Such content is used for non-commercial purposes. You are expressly prohibited from incorporating any of the material from this web site in any other work, publication or web site of your own or belonging to another. Any reproduction of material from this web site or portion thereof must include this notice in its entirety.
                </p>
                <p>
                The information, services, products and materials published on this web site, including without limitation, text, graphics and links are provided on an “as is” basis. <strong>LS Group</strong> makes no representations or warranties of any kind, express or implied, as to the operation of this web site or the information, contents, materials, or products included on this site. Without limiting the generality of the aforegoing: <strong>LS Group</strong> does not warrant that this web site, will be error free, or will meet any particular criteria of accuracy, completeness or reliability of information, performance or quality; and Whilst <strong>LS Group</strong> has taken reasonable measures to ensure the integrity of this web site and its contents, no warranty, whether express or implied, is given that any files, downloads or applications available via this web site are free of viruses, Trojans, bombs, time-locks or any other date or code which has the ability to corrupt or affect the operation of your system.
                </p>
                <br/>
                <Typography gutterBottom variant="h5" color="textPrimary" fontWeight="500">
                    Liability Disclaimer
                </Typography>
                <p>
                The information, services, products and materials published on this web site, including without limitation, text, graphics and links are provided on an “as is” basis. <strong>LS Group</strong> makes no representations or warranties of any kind, express or implied, as to the operation of this web site or the information, contents, materials, or products included on this site. Without limiting the generality of the aforegoing: <strong>LS Group</strong> does not warrant that this web site, will be error free, or will meet any particular criteria of accuracy, completeness or reliability of information, performance or quality; and Whilst <strong>LS Group</strong> has taken reasonable measures to ensure the integrity of this web site and its contents, no warranty, whether express or implied, is given that any files, downloads or applications available via this web site are free of viruses, Trojans, bombs, time-locks or any other date or code which has the ability to corrupt or affect the operation of your system.
                </p>
                <br/>
                <Typography gutterBottom variant="h5" color="textPrimary" fontWeight="500">
                    Limitation of Liability
                </Typography>
                <p>
                In no event shall <strong>LS Group</strong> and its suppliers be liable for any direct, indirect, punitive, incidental, special or consequential damages arising out of or in any way connected with the use of this web site. Such limitation shall also apply with respect to damages resulting from the inability to use this web site, the operational failure of this web site, or for any information, products, and services obtained through this web site, or otherwise arising out of the use of this web site, whether based on contract, delict, strict liability or otherwise, even if <strong>LS Group</strong> and/or any of its suppliers has been advised of the possibility of damages.
                </p>
                <br/>
                <Typography gutterBottom variant="h5" color="textPrimary" fontWeight="500">
                    Professional Information
                </Typography>
                <p>
                    This web site is only intended to provide general information regarding <strong>LS Group</strong> and its products and services and it is not intended to provide exhaustive treatment of any subject dealt with. The information on this web site including, without limitation, all research, opinions or other content is therefore not intended nor does it constitute financial, accounting, tax, legal, investment, consulting or other professional advice or services.
                </p>
                <p>
                    Before making any decision or taking any action which might affect you or your business you should consult your own 
                    professional advisors
                </p>
                <p>
                    This web site could include technical, typographical or other inaccuracies and you are urged to contact <strong>LS Group</strong> to confirm all 
                    information contained on this web site prior to placing reliance thereon. Changes are periodically made to the information 
                    herein and these changes will be incorporated in new editions of this web site.

                </p>
                <br/>
                <Typography gutterBottom variant="h5" color="textPrimary" fontWeight="500">
                    Applicable Law
                </Typography>
                <p>
                    These terms and conditions shall be governed by and construed in accordance with the laws of the Republic of South Africa 
                    without giving effect to the principles of conflicts of laws. You hereby consent to the exclusive jurisdiction of the High Court of 
                    the Republic of South Africa in respect of any disputes arising in connection with this web site.

                </p>
                <br/>
                <Typography gutterBottom variant="h5" color="textPrimary" fontWeight="500">
                    Privacy Policy
                </Typography>
               <p>
               <strong>{DEALERNAME} Motors</strong> recognises the importance of protecting your personal information. Therefore, we have 
                put together a privacy policy and approach that respects and addresses your needs.

                We define personal information as any and all of the information specific to you that you provide to us over the Internet or any 
                other channel. This includes personal data as varied as the information you enter into an online application for a new account, 
                questions you ask via e-mail or personal banking data.

                How does <strong>LS Group</strong> use the personal information you provide? <strong>LS Group</strong> has the highest regard for the privacy of its 
                customers. <strong>LS Group</strong> will use personal information as appropriate in the normal course of our business to provide the products 
                and services you have requested. We will also use this information to provide positive identification of you when you contact 
                us. On occasion, we also send e-mail updates on services and other materials we believe may be of importance or interest.

                Does <strong>LS Group</strong> provide your personal information to external parties? Absolutely not! <strong>LS Group</strong> does not and will not sell or 
                provide personal information to third parties for independent use. We may, however, share personal information with 
                business partners if that information is required to provide the product or service you have requested. They adhere to using 
                information only as directed by <strong>LS Group</strong>.

                <strong>{DEALERNAME} Motors</strong> engages third parties that help it deliver its banner advertisements and other online 
                communications. The third parties may collect and use information about <strong>LS Group</strong> customers to help us understand the 
                offers, promotions, and types of advertising that are most appealing to its customers. The personal information they collect is 
                aggregated and cannot be linked to a person. If you do not wish your personal information to be used in this way, please notify 
                us.
                <p>
                – Third party vendors, including Google and DoubleClick, show <strong>LS Group</strong>’s ads on sites on the internet.
                </p>
                <p>
                – Third party vendors, including Google and DoubleClick, use cookies to serve ads based on a user’s prior visits to <strong>LS Group</strong>’s 
                website.
                </p>
                <p>
                – Users may opt out of Google and DoubleClick’s use of cookies by visiting the Google advertising opt-out page or by visiting 
                the Network Advertising Initiative opt out page
                </p>
               </p>
               <br/>
                <Typography gutterBottom variant="h5" color="textPrimary" fontWeight="500">
                    Violation and Waiver
                </Typography>
                <p>
                Should you violate these Terms and Conditions or any other rights of <strong>LS Group</strong>, <strong>LS Group</strong> reserves the right to pursue any and 
                all legal and equitable remedies against you. If <strong>LS Group</strong> should fail to enforce any right or provision in these Terms and 
                Conditions, you agree that this failure does not constitute a waiver of such right or provision or of any other rights or 
                provisions in these Terms and Conditions. If a court should find that one or more rights or provisions set forth in these Terms 
                and Conditions are invalid, you agree that the remainder of the Terms and Conditions shall be enforceable and that, to the 
                extent permitted by law, the court shall give effect to the parties’ intentions, as reflected in any such right or provision that has 
                been declared invalid or unenforceable.
                </p>
                <br/>
                <Typography gutterBottom variant="h5" color="textPrimary" fontWeight="500">
                    Tracking Analytics
                </Typography>
                <p>
                    <Link component={RouterLink} to="/cookies" className={classes.link}>We use "cookies" on this webiste.</Link>
                </p>
                <br/>
                <Typography gutterBottom variant="h5" color="textPrimary" fontWeight="500">
                    Contacting Us
                </Typography>
                <p>
                    If there are any questions regarding our Terms & Conditions, Contact Us <Link component={RouterLink} to="/contact-us" className={classes.link}>Contact Us</Link>
                </p>

                <div>
                <Typography gutterBottom variant="h5" color="textPrimary" fontWeight="500">
                    Additional Links
                </Typography>
                    <ul>
                        <li>
                            <Link component={RouterLink} to="/requestergws" className={classes.link}>Personal information request form</Link>
                        </li>
                        <li>
                            <Link component={RouterLink} to="/privacy" className={classes.link}>Policies</Link>
                        </li>
                        <li>
                            <Link component={RouterLink} to="/cookies" className={classes.link}>Cookie Policy</Link>
                        </li>
                        <li>
                            <Link component={RouterLink} to="/terms" className={classes.link}>Terms & Conditions</Link>
                        </li>
                    </ul>
                </div>

            </div>
        </Container>
    )
}

export default Terms