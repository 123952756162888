import React from 'react';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { NavLink as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import { SECONDARYCOLOR, BASECOLOR} from '../shared/Constants'
const useStyles = makeStyles((theme) => ({
    content: {
        backgroundColor: `${BASECOLOR}`,
        padding: theme.spacing(10, 0, 6),
    },
    link: {
        color: '#7c7c7c',
        textDecoration: 'none',
        '&:hover': {
            color: `${SECONDARYCOLOR}`,
            textDecoration: 'none'
        }
    }
}));

const Terms = () => {
    const classes = useStyles();

    return (
        <Container maxWidth="lg">
            <div className={classes.content}>
                <p>
                    Click here to view our Complaints Policy
                </p>
                <p>
                <a href="https://nd-mediagallery2-public-production.s3.amazonaws.com/16d3e3681903f924969149e18a482de1/acm_complaints_policy.pdf">ACM Complaints Policy
                </a>
                </p>
            </div>
        </Container>
    )
}

export default Terms