import React, { useContext } from 'react'
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { DEALERID, BASE_URI, MASTER_MOTORGROUPID, MASTER_DEALERID } from '../shared/Constants'
import { Parts as PartsForm } from '@red-build/leadcentre';
import { DealerContext } from '../shared/contexts/DealerContext';
import PartsBanner from '../shared/assets/parts.jpg'

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    paddingTop: '60px',
    paddingBottom: '60px',
    backgroundImage: `url(${PartsBanner})`,
    backgroundColor: 'rgb(0 0 0 / 50%)',
    backgroundBlendMode: 'overlay',
    backgroundSize: "cover",
    backgroundPosition: 'center',
  },
  innerSection: {
    width: "100%",
    maxWidth: "1170px",
    display: "flex",
    justifyContent: "space-between",
    padding: "6px 15px",

    '@media (max-width: 959px)': {
      flexWrap: 'wrap',
      padding: "0px 0px",
    },
  },
  section: {
    width: '60%',

    '&:nth-child(1)': {
      marginRight: '40px',
      width: '40%',
      color: 'white',
    },
    '@media (max-width: 959px)': {
      width: '100%',
      margin: '15px',

      '&:nth-child(1)': {
        margin: '0px',
        padding: '0px 20px',
        width: '100%',

      },
    },
  },
  text: {
    fontSize: '16px',
    padding: '20px 0px'
  },
  titleTop: {
    fontSize: '44px',
    fontWeight: 'bold'
  },
  titleTopAccent: {
    fontSize: '52px',
    color: 'white',
    fontWeight: 'bold'
  },
  PartsHolder: {
    background: 'white',
    padding: '20px',
    borderRadius: '5px',

  }
}))




const Parts = () => {
  const classes = useStyles();
  const { globalDealer, dealerList } = useContext(DealerContext)

  const defaultColors = {
    primaryColor: "#36434c",
    secondaryColor: "#EFDF00",
  };

  return (
    <div className={classes.root}>
      <div className={classes.innerSection}>
        <div className={classes.section} >
          <Typography variant="h1" className={classes.titleTop}>
            NEED PARTS?
          </Typography>
          <Typography variant="h2">
            Parts Enquiry
          </Typography>

          <Typography variant="body2" className={classes.text}>
            We only fit and supply authentic and authorized Kia certified replacement parts and Kia accessories to your car, bakkie or commercial vehicle.

          </Typography>

          <Typography variant="body2" className={classes.text}>
            Whatever part you're looking for – we always strive to have all parts available – but if we don’t have the required part in stock – we will do our very best to source the part, and have it available for collection or delivery as soon as possible.

          </Typography>

          <Typography variant="body2" className={classes.text}>
            We look forward to hearing from you.

          </Typography>

        </div>
        <div className={classes.section}>

          <div className={classes.PartsHolder}>
            <PartsForm
              variant='standard'
              dealerId={globalDealer?.id}
              masterDealerId={MASTER_DEALERID}
              activeDealerId={globalDealer?.id}
              dealerName={globalDealer.id == MASTER_DEALERID ? "" : globalDealer?.name?.replace("Master", "")?.replace("Kia", "Group")}
              base_uri={BASE_URI}
              dealerList={dealerList}
              websiteColors={defaultColors} />
          </div>
        </div>
      </div>
    </div>

  )
}

export default Parts