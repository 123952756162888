import React, { useEffect, useState, useContext } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { Button, Typography, makeStyles } from "@material-ui/core";
import { ContactUs } from "@red-build/leadcentre";
import { BASE_URI } from "../shared/Constants";
import { Link } from "react-router-dom";
import { VehicleContext } from "../shared/contexts/VehicleContext";
import axios from "axios";
import { useMetaTags } from '@red-build/leadcentre';
import { DealerContext } from '../shared/contexts/DealerContext'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { red } from "@material-ui/core/colors";


const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "40px 15px",
  },
  innerHolder: {
    maxWidth: "1170px",
    width: "100%",
  },
  CardHolder: {
    background: "white",
    boxShadow: "0 0 5px 0 #d7d6d6 ",
    padding: "30px 0px",
    marginTop: "20px",
  },
  CardHolder50: {
    background: "white",
    boxShadow: "0 0 5px 0 #d7d6d6 ",
    padding: "30px 0px",
    marginTop: "0px 10px",
    marginTop: "20px",
    width: "calc(100%/2 - 10px)",

    "@media (max-width: 959px)": {
      width: "calc(100%)",
    },
  },

  cardContent: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: "10px",
    padding: "0px 30px",
    flexDirection: "column",
  },
  cardContent50: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: "10px",
    padding: "0px 0px",
    flexDirection: "column",
  },
  cardContent51: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: "10px",
    padding: '20px',
    flexDirection: "column",
  },

  title: {
    borderLeft: "3px #06141f solid",
    padding: "5px 30px",
    alignItems: "center",
    display: "flex",

    justifyContent: "space-between",
    "@media (max-width: 500px)": {
      flexDirection: "column",
    },
  },
  cardContentItem: {
    display: "flex",
    padding: "10px 0px",
    borderBottom: "2px #8080802e solid",
    justifyContent: "space-between",
    alignItems: "center",
  },
  CardHolderWrap: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  accent: {
    color: "#06141f ",
  },
  tc: {
    padding: "10px",
    paddingTop: "20px",
  },
  titleTop: {
    fontSize: "20px",
    fontWeight: "unset",
  },

  titleTopName: {
    fontSize: "20px",
    fontWeight: "unset",
    fontWeight: 'bold'
  },
  titleTopAccent: {
    fontSize: "20px",
    color: "#c7001f",
    fontWeight: "bold",
  },
  cardContentFeature: {
    boxShadow: "2px 0px 15px -2px #adadad",
    borderRadius: "2px",
    margin: "0px 10px",
    marginBottom: "10px",
    padding: "20px",
    height: 'fit-content',
    width: "calc(100% / 1 - 20px )",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    background: 'white'
  },
  ImgHolder: {
    display: "flex",
    marginTop: "20px",
    "@media (max-width: 760px)": {
      flexDirection: "column",
    },
  },
  ImgHolderImage: {
    aspectRatio: '16/12',
    objectFit: "contain",
  },
  GalleryImgImage: {
    aspectRatio: '16/9',
    objectFit: "contain",
  },

  ImgGalleryHolder: {
    position: "relative",

  },

  ImgGalleryHolderInner: {
    display: "flex",
    transform: ({ galleryPos }) => `translate(${galleryPos}%,0%)`,
  },
  GalleryImg: {
    minWidth: "calc(100% / 6 - 10px)",
    margin: "5px",
    width: "100%",
    height: '90px'
  },

  featureHolder: {
    display: "flex",
    flexWrap: "wrap",
    width: "30%",
    alignContent: 'flex-start',
    "@media (max-width: 760px)": {
      marginTop: "20px",
      width: "unset",
    },
  },
  img: {
    width: "70%",
    overflow: "hidden",
    "@media (max-width: 760px)": {
      width: "unset",
    },
  },
  imgWide: {
    width: "100%",
    overflow: "hidden",
    "@media (max-width: 760px)": {
      width: "unset",
    },
  },
  Link: {
    border: "none",
    //borderRadius:'1rem',
    padding: "10px 40px",
    background: "#06141f",
    color: "white",
    cursor: "pointer",
    borderRadius: '5px',

    "&:hover": {
      opacity: 0.8,

      boxShadow: "0 0 5px 0  #06141f",
    },
  },
  titleFlex: {
    display: "flex",
    alignItems: "center",
    flexWrap: 'wrap'
  },
  divider: {
    margin: "0px 10px",
    background: "#06141f",
    minWidth: "3px",
    minHeight: "35px",
    "@media (max-width: 760px)": {
      display: 'none'
    },
  },
  nextbtn: {
    background: '#d7d6d6',
    border: 'none',
    right: '0',
    padding: '10px',
    color: 'black',
    opacity: '0.5',
    position: "absolute",
    top: '50%',
    borderRadius: '5px',
    transform: 'translate(0px, -50%)',
    '&:hover': {
      background: '#06141f',
    },
  },
  backbtn: {
    position: "absolute",
    top: '50%',
    transform: 'translate(0px, -50%)',
    background: '#d7d6d6',
    border: 'none',
    left: '0',
    padding: '10px',
    color: 'black',
    opacity: '0.5',
    borderRadius: '5px',
    '&:hover': {
      background: '#06141f',
    },
  },
  priceHolder: {
    margin: "20px 0px",
    display: "flex",
    alignItems: "center",
    flexWrap: 'wrap'
  },
}));

const DetailedUsedVehicle = () => {
  const [activeVehicle, setActiveVehicle] = useState();
  const history = useHistory();
  const [activeGallery, setActiveGallery] = useState([]);
  const [activeGalleryIndex, setActiveGalleryIndex] = useState(0);
  const [galleryPos, setGalleryPos] = useState(0);

  const params = useParams();
  const classes = useStyles({ galleryPos });
  const { brandedList } = useContext(VehicleContext)
  const { updateMetaTags } = useMetaTags();
  const { globalDealer } = useContext(DealerContext)
  const { pathname } = useLocation();

  useEffect(() => {
    setActiveVehicle(
      brandedList.filter((v) => v.stockId == params?.stockId)[0]
    );
  }, [brandedList]);

  useEffect(() => {
    axios({
      method: "GET",
      url: `${BASE_URI}/stock/${params?.stockId}`,
    })
      .then((response) => {
        setActiveGallery(response.data.mainUrls);
      })
      .catch((error) => {
        if (axios.isCancel(error)) return;
      });
  }, [activeVehicle]);

  var formatter = new Intl.NumberFormat("af", {
    style: "currency",
    currency: "ZAR",
    minimumFractionDigits: 2,
  });

  const handleBack = () => {
    history.push("/used-vehicles");
  };

  const handleGalleryNext = () => {
    if (Math.abs(galleryPos) < Math.abs(((activeGallery.length) / 2) * 16.66)) {
      setGalleryPos(galleryPos - (100 / 6));
    }
  };


  useEffect(() => {
  }, [activeGalleryIndex]);

  const handleGalleryBack = () => {
    if (galleryPos < 0) {
      setTimeout(() => {
        setGalleryPos(galleryPos + 16);
      }, 100);
    }
  };


  function calculateMonthly(data) {

    let deposit = data?.price * (10 / 100);
    let totalPrice = data?.price;
    let initFee = 0;
    let principal = totalPrice - deposit + initFee;

    let balloonPerc = 10;

    let balloonAmt = (totalPrice * balloonPerc) / 100;

    let interestRate = 9
    let interestPM = interestRate / 100 / 12;

    let repaymentPeriod = 72;
    let days = 1;

    let v = 1 / (1 + interestPM);
    let d = 1 - v;
    let y = Math.pow(v, repaymentPeriod - 1);
    let comp = (1 - y) / d;
    let fp = principal * Math.pow(1 + interestPM, days / (365 / 12).toFixed());
    let monthly = 0;

    if (parseInt(balloonAmt) > 0) {
      let comp = (1 - Math.pow(v, repaymentPeriod - 1)) / d;
      monthly = (fp - balloonAmt * Math.pow(v, repaymentPeriod - 1)) / comp;
    } else {
      let comp = (1 - Math.pow(v, repaymentPeriod)) / d;
      monthly = fp / comp;
    }

    return round(monthly) + initFee;

  }


  function round(x) {

    return Math.round(x * 100) / 100;

  }

  // Meta Tags
  useEffect(() => {
    const brand = activeVehicle?.brand || '';
    const range = activeVehicle?.modelRange || '';
    const price = formatter.format(activeVehicle?.price);
    const imageUrl = activeGallery.length > 0 ? activeGallery[0].img : '';
    const dealerName = globalDealer?.name && typeof globalDealer.name === 'string' ? globalDealer.name.replace('Master', '') : '';

    updateMetaTags({
      pathname: pathname,
      title: [brand, range, 'Used Vehicles', dealerName],
      description: [brand, range, price, dealerName],
      image: imageUrl,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeVehicle, activeGallery]);


  return (
    <div className={classes.root}>
      <div className={classes.innerHolder}>
        <button
          className={classes.Link}
          onClick={() => {
            handleBack();
          }}
        >
          Back
        </button>
        <div className={classes.CardHolder}>
          <div className={classes.title}>
            <div className={classes.titleFlex}>
              <Typography variant="h4" className={classes.titleTopName}>
                {`${activeVehicle?.brand} ${activeVehicle?.model}`}
              </Typography>
              <div className={classes.divider}></div>
              <div className={classes.priceHolder}>

                <Typography variant="h4" className={classes.titleTop}>
                  {formatter.format(activeVehicle?.price)}
                </Typography>
                <Typography variant="h4" className={classes.titleTop}>
                  &nbsp;or {formatter.format(calculateMonthly(activeVehicle))} pm
                </Typography>
              </div>

            </div>
            <Link to="/apply-for-finance">
              <button className={classes.Link}>Pre-Qualify</button>
            </Link>
          </div>
        </div>

        <div className={classes.ImgHolder}>
          <div
            className={
              activeVehicle?.features !== "" ? classes.img : classes.imgWide
            }
          >
            <img
              alt={`gallery for ${activeVehicle?.brand} ${activeVehicle?.model}`}
              src={activeGallery[activeGalleryIndex]}
              width="100%"
              className={classes.ImgHolderImage}
            />
            <div style={{ display: 'flex', justifyContent: 'space-between', top: -250, position: 'relative' }}>
              <Button
                variant="contained"
                Color="primary"
                className={classes.pagBtn}
                startIcon={<ArrowBackIcon />}
                onClick={() => setActiveGalleryIndex(activeGalleryIndex > 0 ? activeGalleryIndex - 1 : activeGallery.length - 1)}
              >
                PREV
              </Button>
              <Button
                variant="contained"
                Color="primary"
                className={classes.pagBtn}
                endIcon={<ArrowForwardIcon />}
                onClick={() => setActiveGalleryIndex(activeGalleryIndex === activeGallery.length - 1 ? 0 : activeGalleryIndex + 1)}
              >
                NEXT
              </Button>
            </div>
            <div className={classes.ImgGalleryHolder}>

              <div className={classes.ImgGalleryHolderInner}>
                {activeGallery.map((i, index) => {
                  return (
                    <div className={classes.GalleryImg} onClick={() => {
                      setActiveGalleryIndex(index)

                    }}>
                      <img
                        src={i}
                        width="100%"
                        alt={`${activeVehicle?.brand} ${activeVehicle?.model} gallery ${index}`}
                      />
                    </div>
                  );
                })}
              </div>

              <button
                onClick={() => {
                  handleGalleryBack();
                }}
                className={classes.backbtn}
              >
                Back
              </button>
              <button
                onClick={() => {
                  handleGalleryNext();
                }}
                className={classes.nextbtn}
              >
                Next
              </button>

            </div>
          </div>

          {activeVehicle?.features !== "" && (
            <div className={classes.featureHolder}>
              {activeVehicle?.features.split(",").map((f, index) => {
                if (f !== " " && index < 10) {
                  return <div className={classes.cardContentFeature}>{f}</div>;
                }
              })}
            </div>
          )}
        </div>

        <div className={classes.CardHolderWrap}>
          <div className={classes.CardHolder50}>
            {/* <div className={classes.title}>
            <Typography variant="h4" className={classes.titleTop}>
              Summary
            </Typography>
          </div> */}

            <div className={classes.cardContent}>
              <div className={classes.cardContentItem}>
                <Typography variant="h6" className={classes.titleTop}>
                  Purchase Price
                </Typography>

                <Typography variant="body" className={classes.accent}>
                  {formatter.format(activeVehicle?.price)}
                </Typography>
              </div>

              <div className={classes.cardContentItem}>
                <Typography variant="h6" className={classes.titleTop}>
                  Year
                </Typography>
                <Typography variant="body" className={classes.accent}>
                  {activeVehicle?.year}
                </Typography>
              </div>

              <div className={classes.cardContentItem}>
                <Typography variant="h6" className={classes.titleTop}>
                  Mileage
                </Typography>

                <Typography variant="body" className={classes.accent}>
                  {activeVehicle?.mileage} km
                </Typography>
              </div>

              <div className={classes.cardContentItem}>
                <Typography variant="h6" className={classes.titleTop}>
                  Fuel type
                </Typography>

                <Typography variant="body" className={classes.accent}>
                  {["p", "P", "Petrol", "petrol"].includes(activeVehicle?.fuelType) && "Petrol"}
                  {["d", "D", "Diesel", "diesel"].includes(activeVehicle?.fuelType) && "Diesel"}
                </Typography>
              </div>

              <div className={classes.cardContentItem}>
                <Typography variant="h6" className={classes.titleTop}>
                  Transmission
                </Typography>
                <Typography variant="body" className={classes.accent}>
                  {activeVehicle?.transmission == "m" && "Manual"}
                  {activeVehicle?.transmission == "M" && "Manual"}
                  {activeVehicle?.transmission == "Manual" && "Manual"}

                  {activeVehicle?.transmission == "A" && "Automatic"}
                  {activeVehicle?.transmission == "a" && "Automatic"}
                  {activeVehicle?.transmission == "Automatic" && "Automatic"}

                </Typography>
              </div>

              <div className={classes.cardContentItem}>
                <Typography variant="h6" className={classes.titleTop}>
                  Colour
                </Typography>

                <Typography variant="body" className={classes.accent}>
                  {activeVehicle?.colour}
                </Typography>
              </div>
              <div className={classes.cardContentItem}>
                <Typography variant="h6" className={classes.titleTop}>
                  Stock No #
                </Typography>

                <Typography variant="body" className={classes.accent}>
                  {activeVehicle?.stockNo}
                </Typography>
              </div>
            </div>
          </div>
          <div className={classes.CardHolder50}>
            <div className={classes.title}>
              <Typography variant="h4" className={classes.titleTop}>
                Contact Us
              </Typography>
            </div>

            <div className={classes.cardContent51}>
              <ContactUs
                dealerId={activeVehicle?.dealerId}
                base_uri={BASE_URI}
                leadTypeId={2}
                dealerName={activeVehicle?.dealershipName}
              />
            </div>
          </div>
        </div>
      </div>
      <Typography variant="body" className={classes.tc}>
        {activeVehicle?.termsAndConditions}
      </Typography>
    </div>
  );
};

export default DetailedUsedVehicle;