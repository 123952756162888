import React,{useState,useEffect} from "react";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { ImageContainer } from "../shared/ImageContainer";
import { CurrencyValue } from "../shared/TextMask";
import Button from "@material-ui/core/Button";
import { NavLink as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";
import AddIcon from "@material-ui/icons/Add";
import VehicleController from "./VehicleController";
import { BASE_URI, PAGE_SIZE, DEALERID, DEALERNAME } from "../shared/Constants";
import { SECONDARYCOLOR, BASECOLOR } from "../shared/Constants";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import DealsFilter from "../specialDeals/DealsFilter";
import useMediaQuery from "../shared/useMediaQuery";

const useStyles = makeStyles((theme) => ({
  content: {
    padding: theme.spacing(10, 0, 6),
  },
  dividerTitle: {
    backgroundColor: `${SECONDARYCOLOR}`,
    width: "33%",
    marginBottom: "30px",
    height: "3px",
    margin: "20px auto",
  },
  bold: {
    fontWeight: 700,
  },
  title: {
    fontSize: "1.5rem",
    textAlign:'center',
    color:'#06141f',
    "&>span": {
      fontWeight: 400,
    },
  },
  titleT: {
    fontSize: "1.6rem",
    padding:'20px 0px',
    color:'#06141f',
    "&>span": {
      fontWeight: 400,
    },
  },
  titleTF: {
    fontSize: "1.5rem",
    display: 'flex',
    color:'white',
    textAlign:'center',
    justifyContent: 'center',
    background:'#06141f',
    marginTop:"10px",
    padding:'20px',
    "&>span": {
      fontWeight: 400,
    },
  },
  
  vehicleContainer: {
    zIndex:"22",
    position:"relative",
    "& h5, & p": {
      color: "#7c7c7c",
    },
    "&:hover img, &:hover p, &:hover h5": {
      color: `${SECONDARYCOLOR}`,
      cursor: "pointer",
      textDecoration: "none",
    },
  },
  vehicleContainerColor: {
background:'#bcbbbb',
position:"absolute",
zIndex:'20',
height: '50%',
width: '100%',
bottom: '0',
  },
  
  btn: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  image: {
    margin:'0 auto',
    width:"100%",
    paddingTop:'0px',
    "& > div > div": {
      backgroundColor: `${BASECOLOR}`,
    },
  },
  cardHolderOutter:{
    position: 'relative',
    overflowX:'hidden',
    alignItems: 'center',
   
  
  },
  cardHolder:{
    display:"flex",
    transition: "all 0.5s ease",
    alignItems: 'center',
    transform: ({ slidePosition }) => (!slidePosition == 0 ? `translateX(${slidePosition}%)` : `translateX(0%)`),
    "@media (max-width: 600px)": {
        transform: ({ slidePosition }) => (!slidePosition <= 0 ? `translateX(${slidePosition}%)` : `translateX(0%)`),
    },
    "@media (min-width: 601px) and (max-width: 1333px)": {
        transform: ({ slidePosition }) => (!slidePosition <= 0 ? `translateX(${slidePosition }%)` : `translateX(0%)`),
    },
  },

  card:{
    background:'#d9d9d9',
    width: ({ deals }) => (deals.length > 4  ? `calc(100% / 5 )` : `calc(100% / 4 )`),
    minWidth:"calc(100% / 5 )",
    color:'white',
    display:"flex",
   //  height: '350px',
 
    justifyContent: "center",
    position:'relative',
    //alignItems: "center",
    "&:hover": {
 
     "& $cardTextHolder": {
       bottom:'-5px',
       transition: "all 0.5s ease",
       transform: 'translateY(0%)',
     }
   
   },
   "@media (max-width: 600px)": {
     minWidth:"calc(100% / 1 )",
   },
   "@media (min-width: 601px) and (max-width: 1333px)": {
     minWidth:"calc(100% / 2 )!important",
 },
   },
  cardTextHolder:{
    padding:'20px',
    width:'100%',
    transition: "all 0.5s ease",
    color:'white!important',
paddingTop:'10px',
    textAlign:'center',
 lineHeight:'22px',

  
   },
   cardBack:{
    position:'absolute',
  cursor:'pointer',
    zIndex:50,
    left:10,
    top: '50%',
    transform: 'translate(-25%, -50%)',
   },
   cardNext:{
    position:'absolute',
    cursor:'pointer',
    zIndex:50,
    right:10,
    top: '50%',
    transform: 'translate(25%, -50%)',
  },
  icon:{
    fontSize:'50px',
  },
}));

const OurSpecialsHomePage = (props) => {


  const { deals, hasMore } = DealsFilter();
  const [slidePosition, setSlidePosition] = useState(0)
  const [slideIndex, setSlideIndex] = useState(0)
  const classes = useStyles({slidePosition,deals});

  const isMobile = useMediaQuery('(max-width: 600px)');
  const isTablet = useMediaQuery('(min-width: 601px) and (max-width: 1333px)');
  const isDesktop = useMediaQuery('(min-width: 1334px)');

  const cleanModel = (model) => {
    return model.toLowerCase().replace(/\//g, "-").replace(/ /g, "-");
  };

  const handleBack = () => {

    if (slidePosition < 0) {
        if (isDesktop) {
            setSlidePosition(slidePosition + (100 / 5))
            setSlideIndex(slideIndex - 1)
        }

        if (isTablet) {
          setSlidePosition(slidePosition + (100 / 2))
          setSlideIndex(slideIndex - 1)
      }

        if (isMobile) {
            setSlidePosition(slidePosition + (100 / 1))
            setSlideIndex(slideIndex - 1)
        }

      

    }

}




const handleNext = () => {


    if (isDesktop) {

        if (slideIndex < deals.length - 5) {

            setSlidePosition(slidePosition - (100 / 5))
            setSlideIndex(slideIndex + 1)
        }
        if (slideIndex === deals.length - 5) {
            setSlidePosition(0)
            setSlideIndex(0)
        }
    }

    if (isMobile) {

        if (slideIndex < deals.length - 1) {

            setSlidePosition(slidePosition - (100 / 1))
            setSlideIndex(slideIndex + 1)
        }

        if (slideIndex === deals.length - 1) {
            setSlidePosition(0)
            setSlideIndex(0)
        }
    }

    if (isTablet) {

        if (slideIndex < deals.length - 3) {

            setSlidePosition(slidePosition - (100 / 2))
            setSlideIndex(slideIndex + 1)
        }
        if (slideIndex === deals.length - 2) {
            setSlidePosition(0)
            setSlideIndex(0)
        }
    }

}
 
  

  return (
    <div className={classes.cardHolderOutter}>
            <div  className={classes.cardBack} onClick={()=>{handleBack()}}><ChevronLeftIcon className={classes.icon}/></div>
        <div className={classes.cardNext} onClick={()=>{handleNext()}}><ChevronRightIcon className={classes.icon}/></div>
       <div  className={classes.cardHolder}>
  
          {deals?.map((d, index) => {
            return (
              <div className={classes.card} key={index}>
                <Link
                  underline="none"
                  component={RouterLink}
                  to={`deal/${cleanModel(d.model)}/${d.id}`}
                >
                  <div className={classes.vehicleContainer}>
                    <div className={classes.image}>
                      <ImageContainer
                        aspectratio={16 / 8}
                        height="100%"
                        src={d.imageUrl}
                        alt={d.headline}
                      />
                    </div>

                

                  {/* <div className={classes.cardTextHolder}>
                  
                
                    <div
                    
                      className={`${classes.titleT} `}
                    >
                       <strong>{d?.title?.replace("Kia","")?.replace("kia","")}</strong> 
                    </div>
                    <div
                   
                      className={`${classes.titleTF} `}
                    >
                      <div component="span">from</div>&nbsp;<strong><CurrencyValue value={d.price} /></strong>
                    </div>
                    </div>   */}
                   
                  </div>
                 
                </Link>
                {/* <div className={classes.vehicleContainerColor}>
                  </div> */}
              </div>
            );
          })}
        </div>
    </div>
  );
};

export default OurSpecialsHomePage;
