import React, { Fragment,useContext,useEffect,useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import TwitterIcon from "@material-ui/icons/Twitter";
import Box from "@material-ui/core/Box";
import DriveEtaOutlinedIcon from "@material-ui/icons/DriveEtaOutlined";
import HeadsetIcon from "@material-ui/icons/Headset";
import TextsmsIcon from "@material-ui/icons/Textsms";
import PlaceIcon from "@material-ui/icons/Place";
import { NavLink as RouterLink } from "react-router-dom";
import { useMediaQuery as mq } from "react-responsive";
import { DEALERNAME } from "../shared/Constants";
import DealerDetailsController from "../contactUsPage/DealerDetailsController";
import { SECONDARYCOLOR, BASECOLOR } from "../shared/Constants";
import logoIcon from "../shared/assets/box_icon.png";
import logoIconD from "../shared/assets/box_icon_dark.png";
import { DealerContext } from "../shared/contexts/DealerContext";
import BannerWhite from "../shared/assets/box_icon_dark.png";
import VehicleController from "../ourVehiclesPage/VehicleController";
import { useHistory } from "react-router-dom";

function Copyright() {
  return (
    <React.Fragment>
      {"© "}
      {new Date().getFullYear()} {DEALERNAME.replace("LSG",'').replace("Master",'')} Retail 
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    marginBottom: theme.spacing(0),
    backgroundColor: `${SECONDARYCOLOR}`,
    padding: "30px 20px",
    color: "white",
  },
  rootBottom: {

    marginBottom: theme.spacing(0),
    backgroundColor: `${SECONDARYCOLOR}`,
    padding: "20px 20px",
    color: "white",
  },
  rootBottomFlex: {
    display: "flex",
    width: '100%',
    justifyContent: 'space-between',
  },
  footerLinkImgHolder:{

    backgroundColor: `${SECONDARYCOLOR}`,
  },
  footerLinkImg:{
    padding: "0px 20px",
    paddingTop:"20px",
    filter: 'invert(1)',

  },
  rootBottomLeft: {
  
  },
  rootBottomRight: {
  
  },
  
  container: {

    marginBottom: theme.spacing(0),
  },
  iconsWrapper: {
    height: 120,
  },
  icons: {
    display: "flex",
  },
  icon: {
padding:"0px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    color: "#000000",
  },
  list: {
    margin: 0,
    listStyle: "none",
    padding: 0,
  },
  listItem: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  language: {
    marginTop: theme.spacing(1),
    width: 150,
  },
  footerIconSection: {
    backgroundColor: `${SECONDARYCOLOR}`,
  },
  footerIconSectionLink: {
    color: `${BASECOLOR}`,
  },

  footerIconSectionLinkD:{
    color: `#06141f`,
  },
  footerIconSectionIcon: {
    color: "white",
  },
  footerLink: {
    color: "white",
    fontWeight: "bold",
    display:'block',
    "@media (max-width: 600px)": {
      marginTop: '20px',
      },
   
  },
  footerLinkHeading: {
    color: "white",
    fontWeight: "500 !important;",
  },
  mbr: {
    marginRight: "10px",
  },
  stickyBarInner: {
    backgroundColor: "#F8F8F8",
    textAlign: "center",
    position: "fixed",
    left: "0",
    bottom: "0",
    height: "60px",
    width: "100%",
  },
  stickyBar: {
    display: "block",
    padding: "20px",
    height: "60px",
    width: "100%",
  },
  stickyBarImage: {
    width: "100%",
    height: "100%",
  },
  card1: {
    alignItems: 'center',
    backgroundColor: "#36434c",
    height: "240px",
    display: "flex",
    cursor:"pointer",
    minWidth: 'calc(100% /4)',
    justifyContent: "center",
    flexDirection: "column",
    transition: "all 0.5s ease",
    textDecoration:'none',
    "&:hover": {
      transform: 'translateY(-10px)',
    },
    "@media (max-width: 600px)": {
      minWidth: '100%',
      },
  },
  card2: {
    backgroundColor: "#9ca1a5",
    height: "240px",
    alignItems: 'center',
    display: "flex",
    cursor:"pointer",
    width: 'calc(100% /4)',
    justifyContent: "center",
    flexDirection: "column",
       transition: "all 0.5s ease",
    "&:hover": {
      transform: 'translateY(-10px)',
    },
    "@media (max-width: 600px)": {
      minWidth: '100%',
      },
  },
  card3: {
    backgroundColor: "#36434c",
    height: "240px",
    alignItems: 'center',
    width: 'calc(100% /4)',
    display: "flex",
    cursor:"pointer",
    justifyContent: "center",
    flexDirection: "column",
       transition: "all 0.5s ease",
    "&:hover": {
      transform: 'translateY(-10px)',
    },
    "@media (max-width: 600px)": {
      minWidth: '100%',
      },
  },
  card4: {
    backgroundColor: "#9ca1a5",
    height: "240px",
    cursor:"pointer",
    width: 'calc(100% /4)',
    alignItems: 'center',
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
       transition: "all 0.5s ease",
    "&:hover": {
      transform: 'translateY(-10px)',
    },
    "@media (max-width: 600px)": {
      minWidth: '100%',
      },
  },
  divider:{
    width:'120px',
    background:'white',
    height:'2px',
    margin:'10px 0px'
  },
  dividerD:{
    width:'120px',
    background:'#06141f',
    height:'2px',
    margin:'10px 0px'
  }
}));

const LANGUAGES = [
  {
    code: "en-US",
    name: "English",
  },
  {
    code: "fr-FR",
    name: "Français",
  },
];

export default function FooterComponent() {
  const classes = useStyles();
  const isMobile = mq({ query: "(max-width: 717px)" });
  const { dealerSocialMedia } = DealerDetailsController();
  const { globalDealer, dealerList } = useContext(DealerContext);
  const [activeDealer,setActiveDealer] = useState([])
const {vehicles} = VehicleController();
const history = useHistory();


  useEffect(() => {
    let data = dealerList?.filter((d)=> d.dealerId == 27 )
    setActiveDealer(data)
  }, [dealerList])
  
  const handleRedirect = (data) => {


    history.push(`/models/${data?.make}/${data?.ownedModelId}`,data)
     };

  return (
    <Fragment>
      <Container className={classes.container}>
        <div class="addthis_inline_share_toolbox"></div>
      </Container>
      <div className={classes.footerLinkImgHolder}>    <img alt='KIA banner white' src={BannerWhite} className={classes.footerLinkImg}/></div>
  
      <Typography component="footer" className={classes.root}>
  
          <Box></Box>
          
          <Grid container >
            
            <Grid item xs={12} sm={6} md={4}>
      
             
              <ul className={classes.list}>
          
                <li className={classes.listItem}>
                <Link className={classes.footerLinkHeading} component={RouterLink} to="/used-vehicles">
                   Konfidence Used Cars
                  </Link>
                </li>
                <li className={classes.listItem}>
                <Link className={classes.footerLinkHeading} component={RouterLink} to="/contact-us">
                    Find a Dealership
                  </Link>
                </li>
                <li className={classes.listItem}>
                <Link className={classes.footerLinkHeading} component={RouterLink} to="/special-deals">
                    Kia Specials
                  </Link>
                </li>
                <li className={classes.listItem}>
                <Link className={classes.footerLinkHeading} component={RouterLink} to="/apply-for-finance">
                    Need Finance?
                  </Link>
                </li>
              </ul>
            </Grid>

            <Grid item xs={6} sm={3} md={2}>
              {/* <Typography variant="h6" marked="left" gutterBottom>
                <span className={classes.footerLink}>Stay Connected</span>
              </Typography> */}
                  <ul className={classes.list}>
                
               {vehicles.map((v,index)=>{
                if(index < 5)
                return(
                  <li className={classes.listItem} onClick={() => {
                    handleRedirect(v)}}>
                  {v.title}</li>
                )
            })}
                </ul>
            </Grid>
            <Grid item xs={6} sm={3} md={2}>
              {/* <Typography variant="h6" marked="left" gutterBottom>
                <span className={classes.footerLink}>Stay Connected</span>
              </Typography> */}
                  <ul className={classes.list}>
                
               {vehicles.map((v,index)=>{
                if(index > 5)
                return(
                  <li className={classes.listItem} onClick={() => {
                    handleRedirect(v)}}>
                  {v.title}</li>
                )
            })}
                </ul>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
             {/* <Typography variant="h6" marked="left" gutterBottom>
             Contact
                            </Typography>  */}
              <ul className={classes.list}>
              <li className={classes.listItem}>
                  <Link className={classes.footerLinkHeading} component={RouterLink} to="/contact-us">
                    Contact Us
                  </Link>
                </li>
                <li className={classes.listItem}>
                  <Link className={classes.footerLinkHeading} component={RouterLink} to="/cookies">
                    Cookies
                  </Link>
                </li>
                <li className={classes.listItem}>
                  <Link className={classes.footerLinkHeading} component={RouterLink} to="/terms">
                    Terms & Conditions
                  </Link>
                </li>
                <li className={classes.listItem}>
                  <Link className={classes.footerLinkHeading} component={RouterLink} to="/privacy">
                    Privacy & Legal
                  </Link>
                </li>
                <li className={classes.listItem}>
                  <Link
                    className={classes.footerLinkHeading}
                            
                    component={RouterLink} to="/requestergws"
                  >
                    Popi Request
                  </Link>
                </li>
              </ul>
            </Grid>
          </Grid>
      
      </Typography>

<div   className={classes.rootBottom}>
<Grid item className={classes.icons}>
                  <IconButton className={classes.icon}>
                    <Link
                      href={
                        !!dealerSocialMedia.facebook
                          ? dealerSocialMedia.facebook
                          : `https://www.facebook.com/LSGroupKIA`
                      }
                      className={classes.footerLinkHeading}
                      target="_blank"
                    >
                      <FacebookIcon />
                    </Link>
                  </IconButton>
                
               
                </Grid>
<div   className={classes.rootBottomFlex}>
<div>Kia in <strong>South Africa</strong></div>
<div>   <Copyright/></div>   
        
                </div>
<div   className={classes.rootBottomLeft}>
  
</div>
<div   className={classes.rootBottomRight}>
</div>
</div>

    </Fragment>
  );
}
