import React from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import { CurrencyValue } from "./TextMask";
import { SECONDARYCOLOR } from "../shared/Constants";
const useStyles = makeStyles((theme) => ({
  featureDeal: {
    borderBottom: "1px solid #cccccc",
    pointerEvents:'none',
    "&:last-child": {
      borderBottom: 0,
    },
  },
  headerLine: {

    width: "50%",
    backgroundColor: `${SECONDARYCOLOR}`,
    padding: "0 !important",
  },
  paymentDetails: {
    "& > div": {
      borderBottom: "1px solid #cccccc",
      padding: ".8rem .5rem",
      display: "flex",
      justifyContent: "space-between",
    },
  },
}));

const PaymentDetails = ({
  details,
  onClick,
  displayPrice,
  displayDetails,
  displaySpecs,
  displayTermsAndConditions,
}) => {
  const classes = useStyles();


  function calculateMonthly(data) {

    let deposit = data.purchasePrice * (data.deposit / 100);
    let totalPrice = data.purchasePrice;
    let initFee = 0;
    let principal = totalPrice - deposit + initFee;

    let balloonPerc = data.balloonPayment;

    let balloonAmt = (totalPrice * balloonPerc) / 100;

    let interestRate = data?.linkedInterestRate ? data?.linkedInterestRate : data?.globalInterestRate;
    let interestPM = interestRate / 100 / 12;

    let repaymentPeriod = data.term;
    let days = 1;

    let v = 1 / (1 + interestPM);
    let d = 1 - v;
    let y = Math.pow(v, repaymentPeriod - 1);
    let comp = (1 - y) / d;
    let fp = principal * Math.pow(1 + interestPM, days / (365 / 12).toFixed());
    let monthly = 0;

    if (parseInt(balloonAmt) > 0) {
        let comp = (1 - Math.pow(v, repaymentPeriod - 1)) / d;
        monthly = (fp - balloonAmt * Math.pow(v, repaymentPeriod - 1)) / comp;
    } else {
        let comp = (1 - Math.pow(v, repaymentPeriod)) / d;
        monthly = fp / comp;
    }

    return round(monthly) + initFee;

}

function round(x) {

    return Math.round(x * 100) / 100;

}



  const getMonthly = (dataIn)=>{

    let data = {
        deposit:10,
        term:dataIn?.term,
        purchasePrice:dataIn?.purchasePrice,
        balloonPayment:10,
        linkedInterestRate:9,
    }

return calculateMonthly(data)
}

  return (
    <Box className={classes.featureDeal}>
      <Grid container spacing={4}>
        {details.image && (
          <Grid item xs={12} sm={6} md={6}>
            <Box height={310} width={"100%"}>
              <img
                alt=""
                style={{ objectFit: "cover", width: "100%", height: "100%" }}
                src={details.image}
              />
            </Box>
          </Grid>
        )}

        <Grid
          className={classes.paymentDetails}
          item
          xs={12}
          md={details.image ? 6 : 12}
        >
          {displayPrice && (
            <React.Fragment>
              <Typography variant="h4" color="textPrimary">
                From
                <strong>
                  <CurrencyValue value={details.purchasePrice} />
                </strong>
                or
                <strong>
                  <CurrencyValue value={details.monthlyInstallment} />
                  pm
                </strong>
              </Typography>
              <div className={classes.headerLine}></div>
            </React.Fragment>
          )}
          {displayDetails && (
            <React.Fragment>
              <Box display="flex" mt={2} pb={1}>
                <Typography variant="h5" color="textPrimary">
                  <strong>PRICE BREAKDOWN</strong>
                </Typography>
              </Box>

              <Box display="flex" pb={1}>
                <Box width={200}>
                  <Typography>
                    <strong>Purchase Price</strong>
                  </Typography>
                </Box>
                <Typography>
                  <CurrencyValue value={details.purchasePrice} />
                </Typography>
              </Box>
              <Box display="flex" pb={1}>
                <Box width={200}>
                  <Typography>
                    <strong>Monthly Instalment</strong>
                  </Typography>
                </Box>
                <Typography>
                <CurrencyValue value={details.monthlyInstallment} />

                </Typography>
              </Box>
              <Box display="flex" pb={1}>
                <Box width={200}>
                  <Typography>
                    <strong>Term (Months)</strong>
                  </Typography>
                </Box>
                <Typography>{details.term}</Typography>
              </Box>
              <Box display="flex" pb={1}>
                <Box width={200}>
                  <Typography>
                    <strong>Linked Interest Rate</strong>
                  </Typography>
                </Box>

                <Typography>{details.rate}%</Typography>
              </Box>
              <Box display="flex" pb={1}>
                <Box width={200}>
                  <Typography>
                    <strong>Deposit</strong>
                  </Typography>
                </Box>
                <Typography>{details.deposit}%</Typography>
              </Box>
              <Box display="flex" pb={1}>
                <Box width={200}>
                  <Typography>
                    <strong>Balloon Payment</strong>
                  </Typography>
                </Box>
                <Typography>{details.balloonPayment}%</Typography>
              </Box>
            </React.Fragment>
          )}
          {onClick && (
            <Button variant="contained" onClick={onClick} color="primary">
              Discover Offer
            </Button>
          )}
        </Grid>
        {displaySpecs && details.specs && details.specs.length > 0 && (
          <Grid
            item
            container
            className="specs"
            style={{ borderBottom: "1px solid #cccccc" }}
          >
            {details.specs.map((spec) => {
              return (
                <Grid key={spec.id} item xs={6} md={3}>
                  <Typography align="center" variant="h6">
                    {spec.title ?? spec.name}
                  </Typography>
                  <Typography align="center" color="textPrimary">
                    {spec.description ?? spec.value}
                  </Typography>
                </Grid>
              );
            })}
          </Grid>
        )}
        {displayTermsAndConditions && (
          <Typography
            style={{ marginTop: ".5rem" }}
            variant="caption"
            color="textSecondary"
          >
            {details.termsAndConditions}
          </Typography>
        )}
      </Grid>
    </Box>
  );
};

PaymentDetails.defaultProps = {
  displayDetails: true,
  displayPrice: true,
};

export default PaymentDetails;
