import React, { useState, useEffect, useContext } from "react";
import { makeStyles, CircularProgress } from "@material-ui/core";

import UsedVehiclesFilter from "./usedVehiclesFilter";
import { BASE_URI, DEALERID, MOTORGROUPID } from "../shared/Constants";

import { useHistory } from "react-router-dom";
import { VehicleContext } from "../shared/contexts/VehicleContext";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    padding: "40px 15px",
    display: "flex",
    flexDirection: 'column',

    "@media (max-width: 768px)": {},
  },
  innerHolderFilter: {
    maxWidth: "1770px",
    width: '100%',
    margin: " 0 auto",
  },
  innerHolder: {
    maxWidth: "1770px",
    display: "flex",
    flexWrap: "wrap",
    margin: " 0 auto",
    width: "100%",
  },
  card: {
    width: "calc(100% / 4 - 20px )",
    background: '#06141f',
    minWidth: "calc(100% / 4 - 20px )",
    cursor: 'pointer',
    boxShadow: "0 0 5px 0 #36434c",

    margin: "10px 10px",
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    "@media (max-width: 700px)": {
      width: "100%",
    },
    "@media (min-width: 701px) and (max-width: 999px)": {
      width: "calc(100% / 2 - 20px )",
    },
    "@media (min-width: 1000px) and (max-width: 1500px)": {
      width: "calc(100% / 3 - 20px )",
    },


  },
  cardTextHolder: {
    padding: "20px",
    background: '#06141f',
    color: 'white',
    //borderRadius: '0px 0px 10px 10px',
    "@media (max-width: 768px)": {},
  },
  cardImgHolder: {
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    padding: '20px',
    background: '#06141f',
    //height:'250px',
    aspectRatio: "16/12",
    //backgroundSize:'cover',
    display: 'flex',
    flexDirection: 'column',
    //borderRadius: '10px 10px 0px 0px',
    justifyContent: 'center',
    "@media (max-width: 768px)": {
      height: '250px',
    },
  },
  cardTextHolderGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '5px 0px'
  },
  cta: {
    border: "1px solid white",
    //borderRadius:'1rem',
    padding: "10px 40px",
    background: "#ffffff00",
    color: "white",
    borderRadius: '5px',
    cursor: "pointer",
    margin: '0 auto',
    display: 'block',

    "&:hover": {
      opacity: 0.8,
      background: "  #06141f",
      boxShadow: "0 0 5px 0  #06141f",
    },
  },
  cardTextTitleHolder: {
    marginBottom: '10px'
  },
  cardTextTitle: {
    fontSize: '19px',
    fontWeight: '500',
  },
  pagHolder: {
    display: 'flex',
    justifyContent: 'center'
  },

  pagBtn: {
    //borderRadius:'1rem',
    border: "2px solid #06141f",
    color: "#06141f",
    cursor: "pointer",
    margin: '10px',
    padding: '10px 40px',
    background: 'transparent',
    "&:hover": {

      color: "white",
      background: " #06141f",
      // boxShadow: "0 0 5px 0  #06141f",
    },
  },
  pagNumberHolder: {
    textAlign: 'right',
    width: '100%',
    padding: '5px 10px',
  }
}));

const UsedCarUsedVehiclesPage = () => {
  const history = useHistory();

  const classes = useStyles();

const {brandedList} = useContext(VehicleContext)

  const [vehiclesPag, setVehiclesPag] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);
  const [count, setCount] = useState(20);

  const handleRedirect = (v) => {
    history.push(`/stock/${v.stockId}`, v);
  };

  const defaultColors = {
    primaryColor: "white",
    secondaryColor: "#06141f",
    labelColor: "#06141f",
    InputColor: "#06141f",
    borderColor: '#06141f',
    background: "#c6c9cb",
    selectColor: "#06141f",
    trackColor: "#06141f",
    checkColor: "#06141f",
    btnText: 'white'
  };
  const multiSelect = true;

  var formatter = new Intl.NumberFormat('af', {
    style: 'currency',
    currency: 'ZAR',
    minimumFractionDigits: 2
  });

  useEffect(() => {
    setPageIndex(0);
  }, [vehiclesPag])

  useEffect(() => {
    let startIndex = 0
    let endIndex = 20
    let pag = []


    for (let index = 0; index <= brandedList?.length / 20; index++) {
      pag?.push(brandedList?.slice(startIndex, endIndex))
      startIndex = endIndex
      endIndex = endIndex + 20
    }
    setVehiclesPag(pag);

  }, [brandedList])


  const handleNextPage = () => {
    if (vehiclesPag[pageIndex].length >= 20) { 
      setCount(count + 20)
      setPageIndex(pageIndex + 1)
    window.scrollTo({
      top: 0,
      behavior: 'smooth', 
    });
    }
  }
  const handleBackPage = () => {
    if (pageIndex > 0) {
      setPageIndex(pageIndex - 1)
      window.scrollTo({
        top: 0,
        behavior: 'smooth', 
      });
    }
  }

  const orientation = 'row';

  return (
    <>
      <div className={classes.root}>
        <div className={classes.innerHolderFilter}>
          <UsedVehiclesFilter
            pageSize={20}
            dealerId={DEALERID}
            motorgroupId={MOTORGROUPID}
            orientation={orientation}
            modelHide={false}
            updateFilteredVehicles={setVehiclesPag}
            vehicles={brandedList}
            hideList={true}
            base_uri={BASE_URI}
            websiteColors={defaultColors}
            multiSelect={multiSelect}
          />
        </div>

        <div className={classes.innerHolder}>
          {vehiclesPag[pageIndex]?.map((v) => {

            if (v?.dealershipName === "Kia Bethlehem") {
              v.dealershipName = "Bethlehem Multifranchise"
            }
            if (v?.dealershipName === "Hyundai Bethlehem") {
              v.dealershipName = "Bethlehem Multifranchise"
            }
            if (v?.dealershipName === "Renault Bethlehem") {
              v.dealershipName = "Bethlehem Multifranchise"
            }
            if (v?.dealershipName === "Bethlehem Hyundai") {
              v.dealershipName = "Bethlehem Multifranchise"
            }

            return (
              <div
                className={classes.card}
                onClick={() => {
                  handleRedirect(v);
                }}
              >
                <img alt={`${v?.brand} ${v?.model} at ${formatter?.format(v?.price)}`} className={classes.cardImgHolder} src={v?.image}>
                </img>

                <div className={classes.cardTextHolder}>

                  <div className={classes.cardTextTitleHolder}>
                    <div className={classes.cardTextTitle} style={{ minHeight: '55px' }}>{`${v?.brand} ${v?.model}`}</div>
                  </div>

                  <div className={classes.cardTextHolderGroup}>
                    <div>Mileage</div>
                    <div>{v?.mileage} km</div>
                  </div>

                  <div className={classes.cardTextHolderGroup}>
                    <div>Price</div>
                    <div>{formatter?.format(v?.price)}</div>
                  </div>

                  <div className={classes.cardTextHolderGroup}>
                    <div>Dealership</div>
                    <div>{v?.dealershipName}</div>
                  </div>

                  <div className={classes.cardTextHolderGroup}>
                    <div>Colour</div>
                    <div>{v?.colour}</div>
                  </div>

                  <div className={classes.cardTextHolderGroup}>
                    <div>Year</div>
                    <div>{v?.year}</div>
                  </div>

                  <div className={classes.cardTextHolderGroup}>
                    <div>Fuel Type</div>

                    <div>
                      {v?.fuelType === "P" && "Petrol"}
                      {v?.fuelType === "p" && "Petrol"}
                      {v?.fuelType === "D" && "Diesel"}
                      {v?.fuelType === "Petrol" && "Petrol"}
                      {v?.fuelType === "Diesel" && "Diesel"}
                    </div>
                  </div>

                  <button className={classes.cta}>Enquire</button>
                </div>
              </div>
            );
          })}

          <div className={classes.pagNumberHolder}>{pageIndex + 1} / {(vehiclesPag?.length).toFixed(0)}</div>
        </div>
        <div className={classes.pagHolder}>


          <button className={classes.pagBtn} onClick={() => { handleBackPage() }}> Back </button>
          <button className={classes.pagBtn} onClick={() => { handleNextPage() }}> Next</button>
        </div>
      </div>
    </>
  );
};

export default UsedCarUsedVehiclesPage;

