import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Image from 'material-ui-image'

const useIntersectionObserver = ({
                                   target,
                                   onIntersect,
                                   threshold = 0.1,
                                   rootMargin = "0px"
                                 }) => {
  React.useEffect(() => {
    const observer = new IntersectionObserver(onIntersect, {
      rootMargin,
      threshold
    });
    const current = target.current;
    observer.observe(current);
    return () => {
      observer.unobserve(current);
    };
  });
};

const useStyles = makeStyles({
  ImageContainerUsedVehicle: {
    position: 'relative',
    overflow: 'hidden',
    height:'220px!important',
    width: '100%',
    paddingTop:'0px',
    display:"flex",
    alignItems:"center"
  },
  image: {
    width: '100%',
    paddingTop:'0px',
    height: '100%',
    top:'0',
    bottom:'0',
    margin:'20px 0px',
    
  }
});

export const ImageContainerUsedVehicle = props => {
  const ref = React.useRef();
  const [isVisible, setIsVisible] = React.useState(false);
  const classes = useStyles(props);

  useIntersectionObserver({
    target: ref,
    onIntersect: ([{ isIntersecting }], observerElement) => {
      if (isIntersecting) {
        setIsVisible(true);
        observerElement.unobserve(ref.current);
      }
    }
  });

  return (
    <div
      ref={ref}
      className={classes.ImageContainerUsedVehicle}>
      {isVisible && (
        <img className={classes.image} src={props.src} alt={props.alt}
               imageStyle={{
                 height: 'initial',
                 bottom: 0,
                 margin: 'auto',
                 position:'relative',
               }}
               aspectratio={props.aspectratio ? props.aspectratio : (16/9)} color='lightgrey'/>
      )}
    </div>
  );
};

export const ImageContainerUsedVehicleSingle = props => {
  const ref = React.useRef();
  const [isVisible, setIsVisible] = React.useState(false);
  const classes = useStyles(props);

  useIntersectionObserver({
    target: ref,
    onIntersect: ([{ isIntersecting }], observerElement) => {
      if (isIntersecting) {
        setIsVisible(true);
        observerElement.unobserve(ref.current);
      }
    }
  });

  return (
    <div
      ref={ref}
      className={classes.ImageContainerUsedVehicleSingle}>
      {isVisible && (
        <img className={classes.image} src={props.src} alt={props.alt}
               imageStyle={{
                 height: 'initial',
                 bottom: 0,
                 margin: 'auto',
                 position:'relative',
               }}
               aspectratio={props.aspectratio ? props.aspectratio : (16/9)} color='lightgrey'/>
      )}
    </div>
  );
};