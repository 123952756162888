import React, { useRef, useEffect, useState, useContext } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { NavLink as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";
import IconButton from "@material-ui/core/IconButton";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import MenuIcon from "@material-ui/icons/Menu";
import { useHistory } from "react-router-dom";
import mobileLogo from "./shared/assets/mobile-logo.svg";
import findDealer from "./shared/assets/Find-a-Dealer.svg";
import car from "./shared/assets/car.svg";
import Divider from "@material-ui/core/Divider";
import Container from "@material-ui/core/Container";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import clsx from "clsx";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import HomeIcon from "@material-ui/icons/Home";
import Grid from "@material-ui/core/Grid";


import {
  BASE_URI,
  BASECOLOR,
  DEALERID,
  DEALERNAME,
  SECONDARYCOLOR,
} from "./shared/Constants";
import axios from "axios";
import { useMediaQuery as mq } from "react-responsive";
import CloseIcon from "@material-ui/icons/Close";
import VehicleController from "./ourVehiclesPage/VehicleController";
import { display } from "@material-ui/system";
import { VehicleContext } from "./shared/contexts/VehicleContext";

const drawerWidth = 240;

const navBarStyles = makeStyles((theme) => ({
  link: {
    padding: "0.2rem 1rem !important",
    cursor: "pointer",
    color: "white",
    fontSize: "12px",
    // height: '64px',
    display: "flex",
    alignItems: "center",
    borderRadius: 0,
    fontWeight: "bold!important",
    position: "relative",
    textTransform: 'uppercase',

    "&:hover": {
      textDecoration: "none",

    },
    "&:hover::after": {
      content: '" "',
      position: "absolute",
      display: "block",
      textDecoration: "none",
      background: `#06141f`,
      width: "100%",
      left: 0,
      bottom: 0,
    },
    "@media (max-width: 768px)": {
      display: "none",
    },
  },

  linkM: {
    padding: "15px 1rem !important",
    cursor: "pointer",
    color: "white!important",
    fontSize: "12px",
    // height: '64px',
    display: "flex",
    alignItems: "center",
    borderRadius: 0,
    fontWeight: "700",
    position: "relative",


    "&:hover": {
      textDecoration: "none",

    },
    "&:hover::after": {
      content: '" "',
      position: "absolute",
      display: "block",
      textDecoration: "none",
      background: `#06141f`,
      width: "100%",
      left: 0,
      bottom: 0,
    },

  },

  activeLink: {
    background: `#06141f`,
    "@media (max-width: 768px)": {
      display: "none",
    },
  },
  hidden: {
    textDecoration: "none",
    "@media (max-width: 768px)": {
      display: "none",
    },
  },
  grid: {
    display: "flex",
    justifyContent: 'space-between',
  },
  iconLink: {
    padding: "1rem 0rem !important",
    cursor: "pointer",

    fontFamily: "Renault",
    display: "flex",
    alignItems: "center",
    borderRadius: 0,
    position: "relative",

    "&:hover": {
      textDecoration: "none",
      backgroundColor: "transparent",
    },
    "&:hover::after": {
      content: '" "',
      position: "absolute",
      display: "block",
      textDecoration: "none",
      borderBottom: `3px solid ${BASECOLOR}`,
      width: "100%",
      left: 0,
      bottom: 0,
    },
  },
  active: {
    textDecoration: "none",
    borderBottom: "2px solid #36434c",
    borderTop: "2px solid white",
  },
  background: {
    background: "#ffffff",
    transition: "all 0.2s",
    zIndex: '5!important',
    boxShadow: '2px 1px 0px 0px #06141f',
    "&:hover": {
      //background: `${SECONDARYCOLOR}`,
      linkNav: {
        color: "black!important",
      },
    },
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: "drawerWidth",

    background: "#020a0f",
  },
  white: {
    color: "#00151f",
    fontWeight: "normal",
  },
  bold: {
    fontWeight: "bold",
    textTransform: 'uppercase',
  },
  menuButton: {
    padding: "0px",

    "@media (min-width: 769px)": {
      display: "none",
    },

  },
  logo: {
    height: "auto",
    // width: '100%',
    // maxWidth: '150px',
    width: '150px',
    minWidth: '150px',
    filter: 'invert(1)',
    "@media (max-width: 717px)": {
      maxWidth: "80px",
      paddingTop: "17px",
      paddingBottom: " 12px",
    },
  },
  right: {
    flex: 1,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  closeMenuButton: {
    marginRight: "auto",
    marginLeft: 0,
  },
  badgeButton: {
    "&:hover": {
      background: "transparent",
      color: "#5c92a7",
    },
    "&:focus": {
      outline: "none",
    },
    "&:active": {
      color: "#5c92a7",
    },
  },
  flexContainer: {
    display: "flex",
    direction: "row",
  },
  topNav: {
    display: "flex",
    direction: "row",

    minHeight: "unset",
  },
  flex: {
    display: "flex",
    direction: "row",
  },
  flexEnd: {
    display: "flex",
    direction: "row",
    justifyContent: 'end',
  },

  grow: {
    flexGrow: 1,
  },
  center: {
    display: "flex",
    justifyContent: "space-between",
    padding: '15px',
    width: '100%',
    "@media (min-width: 769px)": {
      width: 'unset',
    },
  },
  root: {
    "& > * + *": {
      marginLeft: theme.spacing(2),
      "@media (max-width: 410px)": {
        marginLeft: "3px",
      },
    },
  },
  iconButtonStyles: {
    fontSize: "0.875rem",
    fontWeight: 500,
    lineHeight: 1.75,
    letterSpacing: "0.02857em",
    textTransform: "uppercase",
  },
  flexWrap: {
    "@media (max-width: 410px)": {
      flexWrap: "wrap",
    },
  },
  MenuFlexClose: {
    width: "100vw",
    display: 'flex!important',
    "@media (max-width: 410px)": {
      flexWrap: "wrap",
    },
  },
  MenuFlex: {

    display: 'flex!important',
    flexWrap: 'wrap',
    padding: '0px 0px',


    "@media (max-width: 410px)": {
      flexWrap: "wrap",
    },
  },
  MenuFlexClose: {
    width: '20px',
    background: '#fafafa',
    height: '2px',
    textAlign: "right",
    fontSize: '18px',
    position: 'absolute',
    right: '15px',
    cursor: "pointer",
    transition: 'all 0.5s ease',
    '&:hover': {
      width: '40px',
      height: '4px',

      boxShadow: '2px 7px 30px -5px #465967',
      borderRadius: '10px',
      background: 'white',

    }
  },
  MenuFlexOutter: {
    padding: "0px",
    background: 'white',

    position: 'fixed',
    zIndex: '4',
    width: '50%',
    transition: 'all 0.5s ease',
    transform: (dropDown) => !dropDown ? 'translateY(-110%)' : 'translateY(0%)',
  },
  MenuClose: {
    padding: '10px',


    cursor: "pointer"
  },
  MenuBtn: {
    padding: '8px 25px',
    color: 'white',
    cursor: "pointer",
    background: '#06141f',
    border: "none",
    borderRadius: '50px'
  },

  MenuBtnActive: {
    padding: '8px 25px',
    color: 'white',
    cursor: "pointer",
    background: '#06141f',
    border: "none",
    borderRadius: '50px'
  },
  cardItem: {
    width: 'calc(100% / 5)',
    textAlign: 'center',
    color: '#06141f',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: "pointer",
    border: '1px solid #CFD5DE',
    padding: '20px 10px',
    transition: 'all 0.5s ease',
    '&:hover': {



      color: '#bababa',
    }
  },
  dropDownHodler: {
    display: 'flex',
    alignItems: 'center',
    textTransform: 'uppercase',
    color: 'white',
    height: "100%",
  },

  dropDownHodlerInner: {
    height: "100%",
  }
}));

export default function NavbarComponent() {
  const { loading, error, vehicles, total } = VehicleController();
  const { vehiclesList } = useContext(VehicleContext)
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const [mobileOpen, setMobileOpen] = useState(false);
  const [dropDown, setDropDown] = useState(false);

  const [open, setOpen] = useState(false);
  const [navBarData, setNavBarData] = useState(false);
  const anchorRef = useRef(null);
  let history = useHistory();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openNewDropdown = Boolean(anchorEl);
  const classes = navBarStyles(dropDown);

  const handleDropdownClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDropdownClose = () => {
    setAnchorEl(null);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  const prevOpen = useRef(open);

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;

    axios({
      method: "GET",
      url: `${BASE_URI}/dealerWebsites/${DEALERID}`,
    }).then((response) => {
      setNavBarData({
        title: response.data.title,
        subTitle: response.data.subTitle,
        logo: response.data.image,
      });

      document.title = `${response.data.title}`;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  function handleDrawerToggle() {
    setDropDown(false);
    setMobileOpen(!mobileOpen);
  }

  const cleanModel = (model) => {
    return model.toLowerCase().replace(/\//g, "-").replace(/ /g, "-");
  };

  const handleModelClick = (vehicle) => {
    setDropDown(false);
    history.push(
      `/models/${cleanModel(vehicle.title)}/${vehicle?.ownedModelId}`,
      { ...vehicle, type: "MODEL" }
    );
  };

  const menuLinks = (
    <React.Fragment>
      <Hidden xsDown implementation="css" className="h-100">
        <div className={classes.flexContainer}>



        </div>
      </Hidden>
    </React.Fragment>
  );

  const isMobile = mq({ query: "(max-width: 717px)" });

  return (
    <>
      <AppBar
        position="sticky"
        color="transparent"
        className={classes.background}
        elevation={0}
      >
        <Divider variant="middle" />


        <Drawer
          variant="temporary"
          anchor={theme.direction === "rtl" ? "right" : "left"}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <IconButton
            onClick={handleDrawerToggle}
            className={`${classes.closeMenuButton} ${classes.white}`}
          >
            <CloseIcon />
          </IconButton>
          <div className="d-flex flex-column flex-sm-row">
            <Link
              variant="h1"
              color="textSecondary"
              component={RouterLink}
              to="/"
              className={`${classes.linkM} ${classes.white}`}
              onClick={handleDrawerToggle}
            >
              Home
            </Link>
            <div >
              <Link
                variant="h1"
                color="textSecondary"

                onClick={handleDrawerToggle}
                component={RouterLink}
                to="/our-vehicles"
                className={`${classes.linkM} ${classes.white} `}
              >
                Our Vehicles
              </Link>
              <Link
                variant="h1"
                color="textSecondary"

                onClick={handleDrawerToggle}
                component={RouterLink}
                to="/used-vehicles"
                className={`${classes.linkM} ${classes.white} `}
              >
                Used Vehicles
              </Link>
            </div>

            <Link
              variant="h1"
              color="textSecondary"
              component={RouterLink}
              to="/special-deals"
              onClick={handleDrawerToggle}
              className={`${classes.linkM} ${classes.white}`}
            >
              Special Deals
            </Link>
            <Link
              variant="h1"
              color="textSecondary"
              component={RouterLink}
              to="/apply-for-finance"
              className={`${classes.linkM} ${classes.white}`}
              onClick={handleDrawerToggle}
            >
              Apply Online
            </Link>
            <Link
              variant="h1"
              color="textSecondary"
              component={RouterLink}
              to="/contact-us"
              className={`${classes.linkM} ${classes.white}`}
              onClick={handleDrawerToggle}
            >
              Contact Us
            </Link>
            <Link
              variant="h1"
              color="textSecondary"
              activeClassName={classes.active}
              onClick={handleDrawerToggle}
              component={RouterLink}
              to="/service"
              className={`${classes.linkM} ${classes.white}`}
            >
              Book A Service
            </Link>
            <Link
              variant="h1"
              color="textSecondary"
              activeClassName={classes.active}
              onClick={handleDrawerToggle}
              component={RouterLink}
              to="/parts"
              className={`${classes.linkM} ${classes.white}`}
            >
              Parts
            </Link>

          </div>
        </Drawer>

        <div

          className={classes.grid}

        >
          <React.Fragment>


            <div className={classes.flex}>
              <div onClick={() => { setDropDown(!dropDown) }} className={classes.dropDownHodler}>

                <div
                  className={`${classes.link} ${classes.white} ${classes.hidden} ${classes.dropDownHodlerInner}`}
                >

                  New Vehicles

                  <ArrowDropDownIcon className={`${classes.white} ${classes.hidden} ${classes.dropDownHodlerInner}`} />
                </div>


              </div>

              <Link
                variant="h1"
                color="textSecondary"
                activeClassName={classes.active}
                onClick={() => setDropDown(false)}
                component={RouterLink}
                to="/used-vehicles"
                className={`${classes.link} ${classes.white} `}
              >
                Used Vehicles
              </Link>
              <Link
                variant="h1"
                color="textSecondary"
                activeClassName={classes.active}
                onClick={() => setDropDown(false)}
                component={RouterLink}
                to="/service"
                className={`${classes.link} ${classes.white}`}
              >
                <span className={classes.bold}>Book A Service</span>
              </Link>
              <Link
                variant="h1"
                color="textSecondary"
                activeClassName={classes.active}
                onClick={() => {setDropDown(false)}}
                component={RouterLink}
                to="/parts"
                className={`${classes.link} ${classes.white}`}
              >
                <span className={classes.bold}>Parts</span>
              </Link>
            </div>


            <Toolbar className={classes.center} onClick={() => { setDropDown(false) }}>
              <Link component={RouterLink} to="/">
                <img
                  src={navBarData.logo}
                  className={classes.logo}
                  alt="KIA Logo - black on white background"
                />
              </Link>
              <div >
                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  className={`${classes.menuButton} ${classes.white}`}
                >
                  <MenuIcon />
                </IconButton>
                {menuLinks}
              </div>
            </Toolbar>




            <div className={classes.flexEnd}>
              <Link
                variant="h1"
                color="textSecondary"
                activeClassName={classes.active}
                onClick={() => setDropDown(false)}
                component={RouterLink}
                to="/special-deals"
                className={`${classes.link} ${classes.white}`}
              >
                <span className={classes.bold}>Special Deals</span>
              </Link>
              <Link
                variant="h1"
                color="textSecondary"
                activeClassName={classes.active}
                onClick={() => setDropDown(false)}
                component={RouterLink}
                to="/apply-for-finance"
                className={`${classes.link} ${classes.white}`}
              >
                <span className={classes.bold}>Apply Online</span>
              </Link>
              <Link
                variant="h1"
                color="textSecondary"
                activeClassName={classes.active}
                onClick={() => setDropDown(false)}
                component={RouterLink}
                to="/contact-us"
                className={`${classes.link} ${classes.white}`}
              >
                <span className={classes.bold}>Contact Us</span>
              </Link>

            </div>

          </React.Fragment>
        </div>

      </AppBar>

      <div className={classes.MenuFlexOutter}>
        {/* <div className={classes.MenuClose} >
        <button className={classes.MenuBtn} >All Vehicles</button> <button className={classes.MenuBtn} >Cars</button>  <button className={classes.MenuBtn} >SUV & MVP</button>  <button className={classes.MenuBtn} >Commercial Vehicles</button>
      </div>  */}
        <div className={classes.MenuFlex}>

          {vehicles?.map((vehicle) => (
            <div key={vehicle.id} className={classes.cardItem} onClick={() => {
              handleModelClick(vehicle)
              setDropDown(false)
            }}>

              <img alt={vehicle.title} src={vehicle.image} width="50%" />
              {vehicle.title}

            </div>
          ))}
        </div>
      </div>

    </>
  );
}
