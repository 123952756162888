import React, {
  useState,
  useCallback,
  useRef,
  useEffect,
  useContext,
} from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from '@material-ui/core/styles';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Box from "@material-ui/core/Box";
import { useHistory } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { CurrencyValue } from "../shared/TextMask";
import { FinanceContext } from "../shared/contexts/FinanceContext";

const useStyles = makeStyles({
  root: {
    color: 'white',
    height: "100vh",
  },
  card:{
    padding: '17px',
    textAlign:'left !important'
  }
});

export default function CardCarousel(props) {

  const classes = useStyles();
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  const history = useHistory();
  let { globalVehicles } = useContext(FinanceContext);
  
  return (
    <Carousel
      responsive={responsive}
      infinite={true}
      autoPlay={true}
      autoPlaySpeed={3000}
      itemClass={classes.card}
    >
      {props.deals.map((v, index) => {
      
        return (
          <div key={index} onClick={() =>
            history.push({
              pathname: `deal/${v.model
                .toLowerCase()
                .replace(/\//g, "-")
                .replace(/ /g, "-")}/${v.id}`,
              state: {
                model: {
                  id: globalVehicles?.filter(
                    (x) => x.modelId == v.modelId
                  )[0]?.id,
                  price: v.price,
                  variantId: v.variantId,
                  modelName: v.headline,
                },
              },
            })
          }>
                 <img alt={`${v.headline}_offer`} src={v.imageUrl} width="100%"/>
           
              
          </div>
          
      
         
        );
      })}
    </Carousel>
  );
}
