import React, { useContext, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Logo from "../shared/assets/box_icon.png";
import { Box, Typography, TextField, MenuItem } from "@material-ui/core";
import { VehicleContext } from "../shared/contexts/VehicleContext";
import { DealerContext } from "../shared/contexts/DealerContext";
import { Formik } from "formik";
import { useHistory, useParams } from "react-router-dom";
import axios from "axios"
import { useSnackbar } from "notistack";
import { BASE_URI, DEALERID } from "../shared/Constants";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",

    backgroundPosition: "100% 80%",
    backgroundSize: "cover",
    alignItems: "center",
    display: "flex",
    height: "90vh",
    padding: '20px',
  },
  rootInner: {
    width: "100%",

    maxWidth: "570px",
    marginLeft: "50px",
    backgroundImage:
      "linear-gradient(90deg, rgba(227,227,227,1) 20%, rgba(199,198,198,1) 100%)",
    borderRadius: "1rem!important",
    boxShadow: "0 0 15px #0000001c",
    "@media (max-width: 959px)": {
      margin: "0px",
    },
  },
  modelHolder: {
    display: "flex",
    flexWrap:'wrap'
  },
  model: {
    marginRight: "8px",
    cursor: "pointer",
    whiteSpace: 'nowrap',
    marginBottom: '8px',
    textTransform:'uppercase'
  },
  modelActive: {
    marginRight: "8px",
    fontWeight: "bold",
    whiteSpace: 'nowrap',
    marginBottom: '8px',
    textTransform:'uppercase'
  },

  rootInnerTitle: {
    fontSize: "32px",
    fontWeight: "bold",
  },
  rootInnerTitleAccent: {
    fontSize: "21px",
    fontWeight: "bold",
    color: "#c7001f",
    marginBottom: "16px",
  },
  input: {
    marginBottom: "20px",
  },
  inputGroup: {
    display: "flex",
    justifyContent: "space-between",
  },
  inputS: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "20px",
    width: "calc(100% / 2 - 10px)",
  },
  textHolder: {
    padding: "15px",
    borderRadius: "0rem 0rem 1rem 1rem!important",
  },
  formHolder: {
    background: "white",
    padding: "15px",
    borderRadius: "0rem 0rem 1rem 1rem!important",
  },
  Link: {
    border: "none",
    borderRadius:'1rem',
    padding: "10px 40px",
    background: "#36434c",
    color: "white",
    cursor: "pointer",
    margin: "15px 0 0",

    "&:hover": {
      background: "  #9b161c",
      boxShadow: "0 0 5px 0  #9b161c",
    },
  },
}));

const BookATestDrive = () => {
  const classes = useStyles();
  const history = useHistory()
  const paramss = useParams()
  const { newVehiclesList } = useContext(VehicleContext);
  const { dealerList } = useContext(DealerContext);
  const { enqueueSnackbar } = useSnackbar();
  const [vehiclesList, setVehiclesList] = useState(newVehiclesList);
  const [vehicleVariant, setVehicleVariant] = useState([]);
  const [vehicleVariantActive, setVehicleVariantActive] = useState([]);

  useEffect(() => {
    history.push(`/book-a-testdrive/${newVehiclesList[0]?.ownedModelId}`)
    setVehiclesList(newVehiclesList);
  }, [newVehiclesList]);

  useEffect(() => {
 
    let params = {
      makeId: 8113,
      dealerModelId: paramss?.stockId,
    };

    axios({
      method: "GET",
      url: `${BASE_URI}/dealervariants`,
      params,
    })
      .then((response) => {
        setVehicleVariant(response.data.list)
  
      })
      .catch((error) => {
        if (axios.isCancel(error)) return;
      });

    
  }, [paramss])
 
  useEffect(() => {

    axios({
      method: "GET",
      url: `${BASE_URI}/DealerModelInformation/GetForDealerModel/${paramss?.stockId}`,
    })
      .then((response) => {
        setVehicleVariantActive(response.data.contentImages)

       
      })
      .catch((error) => {
        if (axios.isCancel(error)) return;
      });
 

  }, [paramss])
  
  useEffect(() => {

    for (let index = 0; index < vehiclesList.length; index++) {
      const element = vehiclesList[index];

    if(paramss?.stockId== element?.ownedModelId ){

      element.active = true;
    }
    }

  }, [vehiclesList])
  
  
  const handleModelChange = (v) => {

    history.push(`/book-a-testdrive/${v.ownedModelId}`)
    for (let index = 0; index < vehiclesList.length; index++) {
      const element = vehiclesList[index];
      element.active = false;
    }

    v.active = true;

    setVehiclesList(vehiclesList);
  };

  const createVehicleLead = (values) => {

    let vehicleParam = {}
    vehicleParam.LeadId = values.leadId;
    vehicleParam.VariantId = values.variantId;

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    axios({
        method: 'POST',
        url: `${BASE_URI}/leadVehicles`,
        data: vehicleParam,
        cancelToken: source.token
    }).then((responseSecond) => {
     
        enqueueSnackbar(`Successfully sent information`, { variant: 'success' });

    }).catch(error => {
        if (axios.isCancel(error)) return
        enqueueSnackbar("Unable to get complete the request", { variant: 'error' });

    })
}


  const createLead =(values)=>{
    let params = {}

    params.leadStatusId = 1
    params.dealerId = values.dealerId
    params.name = values.name
    params.firstName = values.name;
    params.lastName = '';
    params.phoneNumber = values.phone
    params.emailAddress = values.email
    params.leadSourceId = 1
    params.notes = ""
    params.leadTypeId = 1
    params.variant = values.variant
    params.variantId = values.variantId
    params.dateUpdated = new Date().toISOString();

    axios({
      method: 'POST',
      url: `${BASE_URI}/leads`,
      data: params,

  }).then((response) => {

      if (response) {
          values.leadId = response.data.id;

          createVehicleLead(values);
      }
      else {
    
          enqueueSnackbar(`Successfully sent information`, { variant: 'success' });
      }

  }).catch(error => {
      if (axios.isCancel(error)) return
      enqueueSnackbar("Unable to complete the request", { variant: 'error' });
  
  })

  }

  const handleSelectChange = (e, value, setFieldValue, fieldName, fieldId) => {
    setFieldValue(fieldName, e.target.value)

    setFieldValue(fieldId, value.props.id)
}
const handleSelectVariant = (e, value, setFieldValue, fieldName, fieldId) => {

  setFieldValue(fieldName, e.target.value.title)
  setFieldValue(fieldId, value.props.id)


}

  let obj = { 
    email: "",
    dealer: "",
    dealerId:"",
    name:'',
    phone:'',
    variant:'',
    variantId:''
}

  return (
    <div className={classes.root} style={{backgroundImage:`url(${vehicleVariantActive[0]?.image.imageUrl})`}}>
      <div className={classes.rootInner}>
        <div className={classes.textHolder}>
          <Typography className={classes.rootInnerTitle}>
            SELECT A MODEL
          </Typography>

        

          <div className={classes.modelHolder}>
            {vehiclesList?.map((v) => {
              return (
                <div
                  className={
                    v.active == true ? classes.modelActive : classes.model
                  }
                  onClick={() => {
                    handleModelChange(v);
                  }}
                >
                  {v?.model}
                </div>
              );
            })}
          </div>
        </div>

        <div className={classes.formHolder}>
          <Formik
            initialValues={obj}
            enableReinitialize={true}
            validate={(values) => {
              const errors = {};
              if (!values.email) {
                errors.email = "Required";
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = "Invalid email address";
              }
              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(() => {
                createLead(values)
           
                setSubmitting(false);
              }, 400);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              isSubmitting,
              /* and other goodies */
            }) => (
              <form onSubmit={handleSubmit}>
                <div className={classes.inputGroup}>
                  <div className={classes.inputS}>
                    <TextField
                      id="name"
                      label="Name"
                      variant="outlined"
                      required
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                    />
                    {errors.name && touched.name && errors.name}
                  </div>
                  <div className={classes.inputS}>
                    <TextField
                      id="dealer"
                      label="Branch"
                      variant="outlined"
                      fullWidth
                      required
                      select
                      onChange={(e, child) => handleSelectChange(e, child, setFieldValue, 'dealer', 'dealerId')}
                      onBlur={handleBlur}
                      value={values.dealer}
                    >
                      {dealerList?.map((d) => {
                     
                        return (
                          <MenuItem key={d.dealerId} 
                          id={d.dealerId} 
                          value={d.name}>{d?.name}</MenuItem>
                        );
                      })}
                    </TextField>
                    {errors.dealer && touched.dealer && errors.dealer}
                  </div>
                </div>

                <div className={classes.inputGroup}>
                  <div className={classes.inputS}>
                    <TextField
                      id="email"
                      label="Email"
                      variant="outlined"
                      required
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                    />
                    {errors.email && touched.email && errors.email}
                  </div>

                  <div className={classes.inputS}>
                    <TextField
                      id="phone"
                      label="Phone"
                      required
                      variant="outlined"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.phone}
                    />
                    {errors.phone && touched.phone && errors.phone}
                  </div>
                </div>

                <div>
                  <TextField
                    id="variant"
                    label="Select a Variant"
                    variant="outlined"
                    fullWidth
                    required
                    select
                    onChange={(e, child) => handleSelectVariant(e, child, setFieldValue, 'variant', 'variantId')}
                    onBlur={handleBlur}
                 
                  >
                         {vehicleVariant?.map((d) => {
                      
                        return (
                          <MenuItem  key={d.id} 
                          id={d.id} 
                          value={d}>
                            {d?.title}</MenuItem>
                        );
                      })}
                
                  </TextField>
                  {errors.variant && touched.variant && errors.variant}
                </div>
                <button
                  className={classes.Link}
                  type="submit"
                  disabled={isSubmitting}
                >
                  SUBMIT
                </button>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default BookATestDrive;