import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { VehicleContext } from "../shared/contexts/VehicleContext";
import { Typography, TextField, Grid, MenuItem } from "@material-ui/core";
import axios from "axios";
import { BASE_URI, DEALERID, MASTER_DEALERID, FINAPP_URI_APPLICATION } from "../shared/Constants";
import { Finance } from "@red-build/leadcentre";
import { ContactUs as Form } from "@red-build/leadcentre";
import { DealerContext } from "../shared/contexts/DealerContext";
import { useMetaTags } from '@red-build/leadcentre';

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",

  },
  imageHolder: {
    width: "100%",
    backgroundSize: 'cover!important',
    backgroundPosition: 'center!important',
    aspectRatio: '16/6',
    position: 'relative',

    maxHeight: 'calc(100vh - 80px)',

    '@media (max-width: 613px)': {
      maxHeight: 'calc(50vh - 80px)',
    },
    '@media (min-width: 614px) and (max-width: 1000px)': {
      maxHeight: 'calc(50vh - 80px)',
    }


  },
  imageHolderText: {
    fontSize: "20px",
    fontWeight: "bold",
    padding: "10px 50px",
    color: "white",
    textTransform: "uppercase",
    background: ({ websiteColors }) => `${websiteColors.primaryColor}`,
    border: ({ websiteColors }) => `${websiteColors.accentColor} 2px solid`,
    borderRadius: '5px',
    position: 'absolute',
    bottom: '40px',
    left: '50%',
    transform: 'translate(-50%, 0%)',
    "@media (max-width: 959px)": {
      padding: "10px 20px",
      fontSize: "14px",
      display: "none"
    },
  },
  imageHolderTextWrap: {
    fontWeight: "bold",

    color: "white",
    textTransform: "uppercase",
  },
  bold: {
    color: ({ websiteColors }) => `${websiteColors.accentColor}`,
    fontSize: "100px",
    fontWeight: "bold",
    fontSize: "6rem",
  },
  accent: {
    color: ({ websiteColors }) => `${websiteColors.accentColor}`,

  },
  tabHolder: {
    background: "#b6b6b6",
    width: "100%",
    padding: "0px 0px",
    display: "flex",
    justifyContent: "center",
    position: "relative",
    marginBottom: "40px",
    transition: "0.5s all ease-in",
    transform: ({ tabChangeAmount }) => `translate(${tabChangeAmount}%,0%)`,

    "@media (max-width: 959px)": {
      justifyContent: "unset",
    },
    "@media (max-width: 1100px)": {},
  },
  tabNav: {
    position: "absolute",
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    padding: "0px 20px",
    transform: "translate(0px , -50%)",
    top: "32%",
    zIndex: "2",
    color: "#083246",
  },
  tab: {
    cursor: "pointer",
    borderBottom: "4px #b81b2200 solid",
    background: "#b6b6b6",
    display: "flex",
    alignItems: "center",
    padding: "20px 40px",
    justifyContent: "center",
    fontSize: "1.2rem",
    "@media (max-width: 425px)": {
      minWidth: "calc(100% / 2)",
      padding: "15px 30px",
      fontSize: "1.2rem",
    },
    "@media (min-width: 426px) and (max-width: 959px)": {
      minWidth: "calc(100% / 2)",
      padding: "15px 30px",
      fontSize: "1.2rem",
    },
  },
  tabActive: {
    cursor: "pointer",
    borderBottom: "4px #083246 solid",
    background: "#b6b6b6",
    display: "flex",
    alignItems: "center",
    padding: "20px 40px",
    justifyContent: "center",
    fontSize: "1.2rem",
    transform: "scale(1.05)",
    fontWeight: "bolder",
    "@media (max-width: 425px)": {
      minWidth: "calc(100% / 2)",
      padding: "15px 30px",
      fontSize: "1.2rem",
    },
    "@media (min-width: 426px) and (max-width: 959px)": {
      minWidth: "calc(100% / 2)",
      padding: "15px 30px",
      fontSize: "1.2rem",
    },
  },
  tabModel: {
    display: "none",
    "@media (max-width: 959px)": {
      background: "grey",
      fontSize: "1.5rem",
      position: "absolute",
      height: "100%",

      display: "flex",
      alignItems: "center",
      minWidth: "calc(100% / 3)",
      padding: "15px 30px",
      fontSize: "1.2rem",
    },
  },
  galleryHolder: {
    display: "flex",
    flexWrap: "wrap",

    width: "100%",
    margin: '0px auto',
    position: 'relative',
    top: '-82px',
    "@media (max-width: 959px)": {
      top: '0px',
    },
  },

  galleryHolderOutter: {
    textAlign: 'center',
    padding: "40px 80px",
    width: "100%",
    background: 'white',

    "@media (max-width: 959px)": {
      width: "100%",
      padding: "40px 40px",
    },
  },
  galleryHolderP: {
    display: "flex",
    flexWrap: "wrap",


    justifyContent: 'center',
  },
  galleryHolderPOutter: {
    textAlign: 'center',
    padding: "0px 50px",
    width: "100%",
    background: '#f8f8f8',
    justifyContent: 'center',
    "@media (max-width: 959px)": {
      width: "100%",
      padding: "0px 40px",
    },
  },


  brochureHolder: {
    display: "flex",
    flexWrap: "wrap",
    maxWidth: "1170px",
    maxWidth: "1170px",
    width: "100%",
    justifyContent: "center",
    padding: "0px 15px",
  },
  FeatureHolder: {
    display: "flex",
    flexWrap: "wrap",
    maxWidth: "1170px",
    width: "100%",
    padding: "0px 15px",
  },
  HighlightsHolder: {
    display: "flex",
    flexWrap: "wrap",
    background: 'white',
    flexDirection: 'column',
    width: "100%",
    "@media (max-width: 959px)": {
      width: "100%",
    },
  },
  HighlightsHolderGroup: {
    width: "100% ",
    backgroundPosition: 'center',

    backgroundSize: 'cover',

    "@media (max-width: 959px)": {
      maxWidth: "unset",
    },
    "&:nth-child(3)": {
      maxWidth: "unset",

    },
  },

  divider: {
    margin: "10px 10px",

    background: ({ websiteColors }) => `${websiteColors.accentColor}`,
    width: "100px",
    minHeight: "4px",

  },
  HighlightsHolderGroupTextInner: {
    padding: '100px 50px',
    background: ({ websiteColors }) => `linear-gradient(90deg, ${websiteColors.primaryColor}, transparent)`,

    "@media (max-width: 959px)": {
      padding: '50px 50px',
    },
  },

  HighlightsHolderGroupTextInnerRight: {
    padding: '100px 50px',
    display: 'flex',
    justifyContent: 'end',
    background: ({ websiteColors }) => `linear-gradient(270deg, ${websiteColors.primaryColor}, transparent)`,
    "@media (max-width: 959px)": {
      padding: '50px 50px',
    },
  },

  HighlightsHolderGroupText: {
    color: 'white',
    width: "40%",
    "@media (max-width: 959px)": {
      width: "60%",
    },
  },
  galleryImg: {
    maxWidth: "calc(100% / 3 - 20px)",
    margin: "10px",
    cursor: "pointer",
    border: '5px solid white',
    lineHeight: '0px',
    boxShadow: '0px 0px 7px 5px #0000004a',
    "&:hover": {
      transform: "scale(1.02)",
    },

    "&:nth-child(4)": {
      maxWidth: "calc(100% / 2 - 10px)",
    },
    "&:nth-child(5)": {
      maxWidth: "calc(100% / 2 - 10px)",
    },
    "@media (max-width: 959px)": {
      maxWidth: "calc(100% / 2 - 10px)",
      margin: "5px",
    },
  },
  titleTop: {
    margin: "10px 0px",
  },
  featuresCard: {
    maxWidth: "calc(100% / 3 - 20px)",
    minWidth: "calc(100% / 3 - 20px)",
    boxShadow: "0 0 5px 0 #083246 ",
    margin: "10px",
    padding: "20px",
    borderRadius: "3px",
    "@media (max-width: 425px)": {
      maxWidth: "unset",
      width: "100%",
    },
    "@media (max-width: 959px)": {
      maxWidth: "calc(100% / 2 - 20px)",
      width: "100%",
    },
  },
  featuresCardTitle: {
    fontWeight: "bold",
  },
  cta: {
    padding: "10px 40px",
    border: "none",
    cursor: "pointer",
    background: "#083246",
    marginRight: "10px",
    marginTop: "10px",
    color: "white",
    borderRadius: "1rem",
    "&:hover": {
      boxShadow: "0 0 5px 0 #4d0b0e ",
    },
  },

  ctaGrey: {
    padding: "10px 40px",
    border: "none",
    cursor: "pointer",


    background: ({ websiteColors }) => `${websiteColors.primaryColor}`,
    border: ({ websiteColors }) => `${websiteColors.accentColor} 2px solid`,
    borderRadius: '5px',
    color: 'white',
    marginTop: "10px",


  },
  link: {
    color: "white",
  },
  PricingCard: {
    maxWidth: "calc(100% / 4 - 20px)",
    width: "100%",
    margin: "10px",


    "@media (max-width: 570px)": {
      maxWidth: "unset",
      width: "100%",
    },
    "@media (min-width: 571px) and (max-width: 1024px)": {
      maxWidth: "calc(100% / 2 - 20px)",
      width: "100%",
    },
  },
  PricingCardTextHolder: {
    padding: "20px 0px",

    color: "#083246",
    textAlign: 'left'
  },
  PricingCardText: {
    fontSize: "19px",
    color: "#083246",
    minHeight: '54px',
    textTransform: 'uppercase',
  },
  PricingCardImg: {

  },

  tabHolderOutter: {
    overflow: "hidden",
    width: "100%",
    position: "relative",
  },
  tabNavBack: {
    position: "absolute",
    top: "18px",
    left: "5px",
    "@media (min-width: 959px)": {
      display: "none",
    },
  },
  tabNavNext: {
    position: "absolute",
    right: "5px",
    top: "18px",
    "@media (min-width: 959px)": {
      display: "none",
    },
  },
  ctaHolder: {


    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    margin: "10px auto",

    padding: "20px 50px",
    "@media (max-width: 959px)": {
      flexDirection: "column",
      padding: "0px 40px",
    },
  },
  ctaHolderButton: {


    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: 'wrap',
    margin: "10px auto",



  },
  ctaHolderOutter: {
    background: "white",
    width: "100%",
    "@media (max-width: 959px)": {
      width: "100%",
    },
  },
  From: {
    color: "#083246",
    fontSize: "30px",
  },
  FromTitle: {
    color: ({ websiteColors }) => `${websiteColors.accentColor}`,
    fontSize: '60px',
    margin: '0px',

    "@media (max-width: 500px)": {
      padding: "0px 0px",
      fontSize: '40px',
    },
    "@media (min-width:501px) and (max-width: 768px)": {
      padding: "0px 0px",
      fontSize: '50px',
    },
  },
  priceAccent: {

    color: ({ websiteColors }) => `${websiteColors.accentColor}`,
    fontSize: "40px",
    margin: '0px'
  },
  ctaIntro: {
    fontSize: "20px",
    fontWeight: "bold",
    width: '50%',
    "@media (max-width: 959px)": {
      width: '100%',
    },
  },
  ctaIntroBtnHolder: {


    "@media (max-width: 959px)": {
      width: '100%',
    },
  },
  ctaTop: {
    padding: "10px 35px",
    border: "none",
    cursor: "pointer",
    background: "#083246",
    margin: "10px",
    borderRadius: "50px",
    color: "white",

    background: ({ websiteColors }) => `${websiteColors.primaryColor}`,
    border: ({ websiteColors }) => `${websiteColors.accentColor} 2px solid`,
    borderRadius: '5px',

  },
  contactPopUpHolder: {
    position: "fixed",
    transition: '0.5s all ease',
    transform: ({ contactPopUp }) => `translateY(${contactPopUp}%)`,
    background: "#343434c4",
    width: "100%",
    height: "100vh",
    top: "0px",
    zIndex: "5",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: 'hidden',
  },

  finanacePopUpHolder: {
    position: "fixed",
    transition: '0.5s all ease',
    transform: ({ finanacePopUp }) => `translateY(${finanacePopUp}%)`,
    background: "#343434c4",
    width: "100%",
    height: "100vh",
    top: "0px",
    zIndex: "5",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: 'hidden',
  },
  testPopUpHolder: {
    position: "fixed",
    transition: '0.5s all ease',
    transform: ({ testPopUp }) => `translateY(${testPopUp}%)`,
    background: "#343434c4",
    width: "100%",
    height: "100vh",
    top: "0px",
    zIndex: "5",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: 'hidden',
  },

  contactPopUpHolderInner: {
    background: 'white',
    width: "40%",

    "@media (max-width: 425px)": {
      width: "90%",
      height: '78%',
      overflowX: 'auto',
    },
  },
  Close: {
    color: "red",
    textAlign: "right",
    padding: "15px 15px",
    fontSize: "20px",
    cursor: "pointer"
  },
  text: {

    margin: '10px 0px',
    display: 'block',

  },
  colorBlockHolder: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    margin: '0 auto',
    flexWrap: 'wrap',
    marginTop: "20px"
  },
  colorBlockImage: {

    width: '40%',
    margin: '0 auto',
    "@media (max-width: 900px)": {
      width: "90%",

    },
  },
  colorBlockOutter: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    width: 'calc(100% / 6 - 40px)',
    margin: '20px',
    "@media (max-width: 425px)": {
      width: 'calc(100% / 2 - 20px)',

    },
    "@media (min-width: 426px) and (max-width: 768px)": {
      width: 'calc(100% / 3 - 20px)',

    },
  },
  colorBlock: {
    minHeight: '20px',
    borderRadius: '10px',
    margin: '10px',
    padding: "20px",
  },
}));

const CarProfileContainer = () => {
  let tabs = [
    {
      name: "Highlights",
      active: true,
    },
    {
      name: "Features",
      active: false,
    },
    {
      name: "Gallery",
      active: false,
    },

    {
      name: "Finance",
      active: false,
    },
    {
      name: "Pricing",
      active: false,
    },
    {
      name: "Brochure",
      active: false,
    },
  ];

  const { vehiclesList, setVehiclesList, newVehiclesList } =
    useContext(VehicleContext);
  const [activeVehicles, setActiveVehicles] = useState();
  const [activeTab, setActiveTab] = useState("Highlights");
  const [tabList, setTabList] = useState();
  const [tabChangeAmount, setTabChangeAmount] = useState(0);
  const [totalTabLenght, setTotalTabLenght] = useState();
  const [activeImg, setActiveImg] = useState("");
  const [activeGallery, setActiveGallery] = useState([]);
  const [activeGalleryIndex, setActiveGalleryIndex] = useState(0);
  const [pricingList, setPricingList] = useState([]);
  const [activeVariant, setActiveVariant] = useState([]);
  const [colours, setColours] = useState([]);
  const [coloursIndex, setColoursIndex] = useState(0);
  const [contactPopUp, setContactPopUp] = useState(-100);
  const [finanacePopUp, setFinanacePopUp] = useState(-100);
  const [testPopUp, setTestPopUp] = useState(-100);
  const { globalDealer, dealerList, websiteColors } = useContext(DealerContext);
  const [activeTitle, setActiveTitle] = useState();
  const paramss = useParams();
  const history = useHistory();
  const { pathname } = useLocation();
  const { updateMetaTags } = useMetaTags();

  const classes = useStyles({ tabChangeAmount, websiteColors, contactPopUp, finanacePopUp, testPopUp });

  useEffect(() => {
    setTabList(tabs);
    setTotalTabLenght(tabs.length - 1);
    setActiveTab("Highlights");
    axios({
      method: "GET",
      url: `${BASE_URI}/DealerModelInformation/GetForDealerModel/${paramss?.ownedModelId}`,
    })
      .then((response) => {
        setActiveVehicles(response.data);

        setActiveGallery(response.data.galleryImages);
      })
      .catch((error) => {
        if (axios.isCancel(error)) return;
      });

    let params = {
      makeId: 8104,
      dealerModelId: paramss.ownedModelId,
    };

    axios({
      method: "GET",
      url: `${BASE_URI}/dealervariants`,
      params,
    })
      .then((response) => {

        setPricingList(response.data.list);
        setActiveVariant(response.data.list[0])


        axios({
          method: "GET",
          url: `${BASE_URI}/colours/exteriors?dealerVariantId=${response.data.list[0].id}`,
        })
          .then((response) => {

            if (response.data.list.length > 0) {
              setColours(response.data.list)
            } else {
              setColours([])
            }

          })
          .catch((error) => {
            if (axios.isCancel(error)) return;
          });
      })
      .catch((error) => {
        if (axios.isCancel(error)) return;
      });


  }, [paramss]);



  const handleLightBox = (img, index) => {
    setActiveImg(index);
  };

  const handlePopUp = (type, vehicle) => {



    switch (type) {
      case "Enquire":
        setContactPopUp(0);
        if (vehicle !== undefined) {
          setActiveVariant(vehicle)
        }
        break;

      case "TestDrive":
        setTestPopUp(0);
        if (vehicle !== undefined) {
          setActiveVariant(vehicle)
        }
        break;

      case "Qualify":
        setFinanacePopUp(0);
        if (vehicle !== undefined) {
          setActiveVariant(vehicle)
        }
        break;


      default:
        break;
    }

  };


  useEffect(() => {
    setActiveTitle(newVehiclesList.flat().filter((v) => v.id == paramss?.ownedModelId)[0]);
  }, [paramss, newVehiclesList]);

  var formatter = new Intl.NumberFormat("af", {
    style: "currency",
    currency: "ZAR",
    minimumFractionDigits: 2,
  });

  function openPDF(pdf) {
    window.open(pdf);
    return false;
  }


  function calculateMonthly(data) {


    let deposit = data?.priceRange * (10 / 100);
    let totalPrice = data?.priceRange;
    let initFee = 0;
    let principal = totalPrice - deposit + initFee;

    let balloonPerc = 10;

    let balloonAmt = (totalPrice * balloonPerc) / 100;

    let interestRate = 9
    let interestPM = interestRate / 100 / 12;

    let repaymentPeriod = 72;
    let days = 1;

    let v = 1 / (1 + interestPM);
    let d = 1 - v;
    let y = Math.pow(v, repaymentPeriod - 1);
    let comp = (1 - y) / d;
    let fp = principal * Math.pow(1 + interestPM, days / (365 / 12).toFixed());
    let monthly = 0;

    if (parseInt(balloonAmt) > 0) {
      let comp = (1 - Math.pow(v, repaymentPeriod - 1)) / d;
      monthly = (fp - balloonAmt * Math.pow(v, repaymentPeriod - 1)) / comp;
    } else {
      let comp = (1 - Math.pow(v, repaymentPeriod)) / d;
      monthly = fp / comp;
    }

    return round(monthly) + initFee;

  }


  function round(x) {
    return Math.round(x * 100) / 100;
  }

  // Meta Tags
  useEffect(() => {
    const model = activeVariant?.modelName || activeTitle?.model || activeVariant?.variantId;
    const price = formatter.format(activeTitle?.price) || '';
    const description = activeTitle?.introductionDesc;
    const imageUrl = activeVehicles?.contentImages[0]?.image?.imageUrl;
    const dealerName = globalDealer?.name && typeof globalDealer.name === 'string' ? globalDealer.name.replace('Master', '') : '';

    updateMetaTags({
      pathname: pathname,
      title: [model, 'New Vehicles', dealerName],
      description: [model, price, dealerName, description],
      image: imageUrl,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeVariant, activeTitle]);

  return (
    <div className={classes.root}>

      <div
        className={classes.imageHolder}
        style={{ background: `url(${activeVehicles?.contentImages[0]?.image?.imageUrl})`, }}
      >

        <button className={classes.imageHolderText}>
          {activeVehicles?.introductionTitle}

        </button>
      </div>


      <div className={classes.contactPopUpHolder} >
        <div className={classes.contactPopUpHolderInner}>
          <div
            className={classes.Close}
            onClick={() => {
              setContactPopUp(-100);
            }}
          >
            X
          </div>
          <Form
            dealers={dealerList}
            dealerType="multi_dealer"
            dealerId={globalDealer?.id}
            base_uri={BASE_URI}
            leadTypeId={1}
            variantId={activeVariant?.variantId}
            paper={true}
            dealerName={globalDealer.id == MASTER_DEALERID ? "" : globalDealer?.name?.replace("Master", "")?.replace("Kia", "Group")}
          />
        </div>
      </div>

      <div className={classes.testPopUpHolder} >
        <div className={classes.contactPopUpHolderInner}>
          <div
            className={classes.Close}
            onClick={() => {
              setTestPopUp(-100);
            }}
          >
            X
          </div>
          <Form
            dealers={dealerList}
            dealerType="multi_dealer"
            dealerId={globalDealer?.id}
            base_uri={BASE_URI}
            leadTypeId={1}
            variantId={activeVariant?.variantId}
            paper={true}
            dealerName={globalDealer.id == MASTER_DEALERID ? "" : globalDealer?.name?.replace("Master", "")?.replace("Kia", "Group")}
          />
        </div>
      </div>



      <div className={classes.finanacePopUpHolder}>
        <div className={classes.contactPopUpHolderInner}>
          <div
            className={classes.Close}
            onClick={() => {
              setFinanacePopUp(-100);
            }}
          >
            X
          </div>
          <Finance
            masterDealerId={MASTER_DEALERID}
            dealers={dealerList}
            base_uri={BASE_URI}
            dealerId={DEALERID}
            dealerType={"multi_dealer"}
            //activeDealerId={globalDealer.id}
            variantId={activeVariant?.variantId}
            leadTypeId={1}
            pqSuccessUrl={FINAPP_URI_APPLICATION}
            paper={true}
          />
        </div>
      </div>


      <div className={classes.ctaHolderOutter}>
        <div className={classes.ctaHolder}>
          <div className={classes.ctaIntro}>
            <h1 className={classes.FromTitle}>   <strong> {activeTitle?.make} {activeTitle?.model}</strong>  </h1>


            <h2 className={classes.priceAccent}>
              <div className={classes.From}>
                From

              </div>

              <strong>
                {formatter.format(activeTitle?.price)}

              </strong>
            </h2>
          </div>
          <div className={classes.ctaIntroBtnHolder}>

            <button
              className={classes.ctaTop}
              onClick={() => { openPDF(activeVehicles.brochures[0].document.filePath) }}
            >
              Brochure
            </button>

            <button
              className={classes.ctaTop}
              onClick={() => {
                handlePopUp("Qualify");
              }}
            >
              Pre-Qualify
            </button>

            <button
              className={classes.ctaTop}
              onClick={() => {
                handlePopUp("Enquire");
              }}
            >
              Enquire Now
            </button>

            <button
              className={classes.ctaTop}
              onClick={() => {
                handlePopUp("TestDrive");
              }}
            >
              Book A Test Drive
            </button>
          </div>
        </div>
      </div>


      <div className={classes.HighlightsHolder}>
        <div className={classes.HighlightsHolderGroup} style={{ backgroundImage: `url(${activeVehicles?.contentImages[0]?.image?.imageUrl})` }}>

          <div className={classes.HighlightsHolderGroupTextInner}>
            <div className={classes.HighlightsHolderGroupText}>
              <Typography variant="h4" className={classes.titleTop}>
                {activeVehicles?.contentTitle1}
              </Typography>

              <Typography variant="body" className={classes.text}>
                {activeVehicles?.contentDesc1}
              </Typography>
            </div>
          </div>
        </div>

        <div className={classes.HighlightsHolderGroup} style={{ backgroundImage: `url(${activeVehicles?.contentImages[1]?.image?.imageUrl})` }}>
          <div className={classes.HighlightsHolderGroupTextInnerRight}>
            <div className={classes.HighlightsHolderGroupText}>

              <Typography variant="h4" className={classes.titleTop}>
                {activeVehicles?.contentTitle2}
              </Typography>

              <Typography variant="body" className={classes.text}>
                {activeVehicles?.contentDesc2}
              </Typography>
            </div>
          </div>
        </div>

        <div className={classes.HighlightsHolderGroup} style={{ backgroundImage: `url(${activeVehicles?.contentImages[3]?.image?.imageUrl})` }}>

          <div className={classes.HighlightsHolderGroupTextInner}>
            <div className={classes.HighlightsHolderGroupText}>
              <Typography variant="h4" className={classes.titleTop}>
                {activeVehicles?.contentTitle3}
              </Typography>

              <Typography variant="body" className={classes.text}>
                {activeVehicles?.contentDesc3}
              </Typography>
            </div>
          </div>
        </div>
      </div>


      {activeTab == "Features" && (
        <div className={classes.FeatureHolder}>
          {activeVehicles?.features.map((f) => {
            return (
              <div className={classes.featuresCard}>
                <div className={classes.featuresCardTitle}>{f.title}</div>
                <div> {f.description}</div>
              </div>
            );
          })}
        </div>
      )}

      <div className={classes.galleryHolderOutter}>
        <Typography variant="h4" className={classes.titleTop}>
          <strong>
            Gallery <span className={classes.accent}>images</span></strong>
        </Typography>

        <img alt="" src={activeVehicles?.galleryImages[activeGalleryIndex]?.image?.imageUrl} width="100%" />
        <div className={classes.galleryHolder}>
          {activeVehicles?.galleryImages.map((v, index) => {
            if (index < 3)
              return (
                <div className={classes.galleryImg}>
                  <img
                    alt=""
                    src={v?.image?.imageUrl}
                    width="100%"
                    onClick={() => {
                      setActiveGalleryIndex(index);
                    }}
                  />
                </div>
              );
          })}
        </div>
      </div>

      <div className={classes.galleryHolderOutter}>
        <Typography variant="h4" className={classes.titleTop}>
          <strong>
            Available <span className={classes.accent}>colours</span></strong>
        </Typography>
        {colours?.length > 0 &&
          <div className={classes.colorBlockImage} > <img alt="" src={colours[coloursIndex]?.image} width="100%" /></div>
        }
        <div className={classes.colorBlockHolder}>
          {colours?.map((c, index) => {
            return (
              <div className={classes.colorBlockOutter} onClick={() => { setColoursIndex(index) }}>

                <img alt={`${c.title}`} src={c?.image} />


                <p> {c.title}</p>
              </div>
            )
          })}
        </div>
      </div>



      <div className={classes.galleryHolderPOutter}>
        <Typography variant="h4" className={classes.titleTop}>
          <strong>
            Range <span className={classes.accent}>models</span></strong>
        </Typography>

        <div className={classes.galleryHolderP}>
          {pricingList.map((v, index) => {
            return (
              <div className={classes.PricingCard}>

                <img
                  alt=""
                  src={v.image}
                  width="100%"
                  className={classes.PricingCardImg}
                />

                <div className={classes.PricingCardTextHolder}>
                  <div className={classes.PricingCardText}>{v.title}</div>

                  <div>{formatter.format(v.priceRange)} or {formatter.format(calculateMonthly(v))} pm</div>

                  <div className={classes.ctaHolderButton}>
                    <button
                      className={classes.ctaGrey}
                      onClick={() => {
                        handlePopUp("Qualify", v);
                      }}
                    >
                      Finance
                    </button>
                    <button className={classes.ctaGrey} onClick={() => { handlePopUp("Enquire", v) }}>Call Back</button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>



    </div>
  );
};

export default CarProfileContainer;