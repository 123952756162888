export const kwidSpecs = (
    <amp-accordion className="i-amphtml-element i-amphtml-layout-container i-amphtml-built i-amphtml-layout"
        i-amphtml-layout="container">
        <section>
            <h4 className="bg-tertiary mb1 px2 py1 i-amphtml-accordion-header" id="4_AMP_header_0" role="button"
                aria-controls="4_AMP_content_0" aria-expanded="false" tabindex="0">1.0 EXPRESSION (ABS) <span
                    className="pull-right">R 162 900</span></h4>
            <div className="bg-tertiary i-amphtml-accordion-content" id="4_AMP_content_0" aria-labelledby="4_AMP_header_0"
                role="region">
                <table className="table-light">
                    <tbody>
                        <tr className="bg-darken-1">
                            <td colspan="2">Service / Maintenance / Warranty</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Service interval</td>
                            <td><span className="pull-right">15 000 km</span></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td className="align-top">Service Plan</td>
                            <td><span className="pull-right">24 months</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Warranty</td>
                            <td><span className="pull-right">5 YEAR/150000KM, 6 YEAR COROSSION, 2 YEAR/30000KM SERVICE
                                PLAN</span></td>
                        </tr>
                    </tbody>
                </table>
                <table className="table-light">
                    <tbody>
                        <tr className="bg-darken-1">
                            <td colspan="2">Active and Passive Safety</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">ABS</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Electronic brakeforce distribution (EBD)</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Air bags</td>
                            <td><span className="pull-right">2</span></td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Performance</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Engine size</td>
                            <td className="pull-right">999cc</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>CO2</td>
                            <td className="pull-right">112g/Km</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Engine</td>
                            <td className="pull-right">TRANSVERSE INLINE 3</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Power</td>
                            <td className="pull-right">50kW@5500</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Torque</td>
                            <td className="pull-right">91Nm@4250</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Valves</td>
                            <td className="pull-right">SOHC, 4 VALVES PER CYLINDER</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Fuel</td>
                            <td className="pull-right">UNLEADED</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Fuel capacity</td>
                            <td className="pull-right">28 litres</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>City fuel consumption</td>
                            <td className="pull-right">5.9 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Highway fuel consumption</td>
                            <td className="pull-right">4.1 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Average fuel consumption</td>
                            <td className="pull-right">4.7 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Top speed</td>
                            <td className="pull-right">152Km/h</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>0 - 100 Km/h</td>
                            <td className="pull-right">0sec</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Driving and Control Environment</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Gearbox</td>
                            <td className="pull-right">MANUAL</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Gears</td>
                            <td className="pull-right">5</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Drive</td>
                            <td className="pull-right">2</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Power steering</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Computer</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Seats</td>
                            <td className="pull-right">5 -
                            {/* <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span> */}
                            </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Spare tire size</td>
                            <td className="pull-right">FULLSIZE</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Keyless Entry</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Interior Comfort</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Air conditioner</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Electric windows</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Electric window detail</td>
                            <td className="pull-right">FRONT</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Radio</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Exterior</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Metallic paint</td>
                            <td className="pull-right">
                                OPTION
                        </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Colour coded bumpers</td>
                            <td className="pull-right">
                                PAINTED
                        </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Colour coded mirrors</td>
                            <td className="pull-right">
                                BLACK
                        </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Park distance - rear</td>
                            <td className="pull-right">
                                YES
                        </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Boot capacity</td>
                            <td className="pull-right">
                                279 litres
                        </td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </section>
        <section>
            <h4 className="bg-tertiary mb1 px2 py1 i-amphtml-accordion-header" id="4_AMP_header_1" role="button"
                aria-controls="4_AMP_content_1" aria-expanded="false" tabindex="0">1.0 DYNAMIQUE (ABS) <span
                    className="pull-right">R 172 900</span></h4>
            <div className="bg-tertiary i-amphtml-accordion-content" id="4_AMP_content_1" aria-labelledby="4_AMP_header_1"
                role="region">
                <table className="table-light">
                    <tbody>
                        <tr className="bg-darken-1">
                            <td colspan="2">Service / Maintenance / Warranty</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Service interval</td>
                            <td><span className="pull-right">15 000 km</span></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td className="align-top">Service Plan</td>
                            <td><span className="pull-right">24 months</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Warranty</td>
                            <td><span className="pull-right">5 YEAR/150000KM, 6 YEAR COROSSION, 2 YEAR/30000KM SERVICE
                                PLAN</span></td>
                        </tr>
                    </tbody>
                </table>
                <table className="table-light">
                    <tbody>
                        <tr className="bg-darken-1">
                            <td colspan="2">Active and Passive Safety</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">ABS</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Electronic brakeforce distribution (EBD)</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Air bags</td>
                            <td><span className="pull-right">2</span></td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Performance</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Engine size</td>
                            <td className="pull-right">999cc</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>CO2</td>
                            <td className="pull-right">112g/Km</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Engine</td>
                            <td className="pull-right">TRANSVERSE INLINE 3</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Power</td>
                            <td className="pull-right">50kW@5500</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Torque</td>
                            <td className="pull-right">91Nm@4250</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Valves</td>
                            <td className="pull-right">SOHC, 4 VALVES PER CYLINDER</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Fuel</td>
                            <td className="pull-right">UNLEADED</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Fuel capacity</td>
                            <td className="pull-right">28 litres</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>City fuel consumption</td>
                            <td className="pull-right">5.9 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Highway fuel consumption</td>
                            <td className="pull-right">4.1 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Average fuel consumption</td>
                            <td className="pull-right">4.7 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Top speed</td>
                            <td className="pull-right">152Km/h</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>0 - 100 Km/h</td>
                            <td className="pull-right">0sec</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Driving and Control Environment</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Gearbox</td>
                            <td className="pull-right">MANUAL</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Gears</td>
                            <td className="pull-right">5</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Drive</td>
                            <td className="pull-right">2</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Power steering</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Computer</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Seats</td>
                            <td className="pull-right">5 -
                            {/* <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span> */}
                            </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Spare tire size</td>
                            <td className="pull-right">FULLSIZE</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Keyless Entry</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Interior Comfort</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Air conditioner</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Electric windows</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Electric window detail</td>
                            <td className="pull-right">FRONT + REAR</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Radio</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Remote side mirrors</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Exterior</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Metallic paint</td>
                            <td className="pull-right">
                                OPTION
                        </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Colour coded bumpers</td>
                            <td className="pull-right">
                                PAINTED
                        </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Colour coded mirrors</td>
                            <td className="pull-right">
                                BODY
                        </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Park distance - rear</td>
                            <td className="pull-right">
                                YES
                        </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Satelite navigation</td>
                            <td className="pull-right">
                                YES
                        </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Boot capacity</td>
                            <td className="pull-right">
                                279 litres
                        </td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </section>
        <section>
            <h4 className="bg-tertiary mb1 px2 py1 i-amphtml-accordion-header" id="4_AMP_header_2" role="button"
                aria-controls="4_AMP_content_2" aria-expanded="false" tabindex="0">1.0 EXPRESSION AMT ABS <span
                    className="pull-right">R 172 900</span></h4>
            <div className="bg-tertiary i-amphtml-accordion-content" id="4_AMP_content_2" aria-labelledby="4_AMP_header_2"
                role="region">
                <table className="table-light">
                    <tbody>
                        <tr className="bg-darken-1">
                            <td colspan="2">Service / Maintenance / Warranty</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Service interval</td>
                            <td><span className="pull-right">15 000 km</span></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td className="align-top">Service Plan</td>
                            <td><span className="pull-right">24 months</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Warranty</td>
                            <td><span className="pull-right">5 YEAR/150000KM, 6 YEAR COROSSION, 2 YEAR/30000KM SERVICE
                                PLAN</span></td>
                        </tr>
                    </tbody>
                </table>
                <table className="table-light">
                    <tbody>
                        <tr className="bg-darken-1">
                            <td colspan="2">Active and Passive Safety</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">ABS</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Electronic brakeforce distribution (EBD)</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Air bags</td>
                            <td><span className="pull-right">2</span></td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Performance</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Engine size</td>
                            <td className="pull-right">999cc</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>CO2</td>
                            <td className="pull-right">104g/Km</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Engine</td>
                            <td className="pull-right">TRANSVERSE INLINE 3</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Power</td>
                            <td className="pull-right">50kW@5500</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Torque</td>
                            <td className="pull-right">91Nm@4250</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Valves</td>
                            <td className="pull-right">SOHC, 4 VALVES PER CYLINDER</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Fuel</td>
                            <td className="pull-right">UNLEADED</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Fuel capacity</td>
                            <td className="pull-right">28 litres</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>City fuel consumption</td>
                            <td className="pull-right">5.1 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Highway fuel consumption</td>
                            <td className="pull-right">4 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Average fuel consumption</td>
                            <td className="pull-right">4.4 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Top speed</td>
                            <td className="pull-right">152Km/h</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>0 - 100 Km/h</td>
                            <td className="pull-right">0sec</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Driving and Control Environment</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Gearbox</td>
                            <td className="pull-right">AUTOMATIC</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Gears</td>
                            <td className="pull-right">5</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Drive</td>
                            <td className="pull-right">2</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Power steering</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Computer</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Seats</td>
                            <td className="pull-right">5 -
                            {/* <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span> */}
                            </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Spare tire size</td>
                            <td className="pull-right">FULLSIZE</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Keyless Entry</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Interior Comfort</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Air conditioner</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Electric windows</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Electric window detail</td>
                            <td className="pull-right">FRONT</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Radio</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Exterior</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Metallic paint</td>
                            <td className="pull-right">
                                OPTION
                        </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Colour coded bumpers</td>
                            <td className="pull-right">
                                PAINTED
                        </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Colour coded mirrors</td>
                            <td className="pull-right">
                                BLACK
                        </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Park distance - rear</td>
                            <td className="pull-right">
                                YES
                        </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Boot capacity</td>
                            <td className="pull-right">
                                279 litres
                        </td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </section>
        <section>
            <h4 className="bg-tertiary mb1 px2 py1 i-amphtml-accordion-header" id="4_AMP_header_3" role="button"
                aria-controls="4_AMP_content_3" aria-expanded="false" tabindex="0">1.0 DYNAMIQUE AMT (ABS) <span
                    className="pull-right">R 182 900</span></h4>
            <div className="bg-tertiary i-amphtml-accordion-content" id="4_AMP_content_3" aria-labelledby="4_AMP_header_3"
                role="region">
                <table className="table-light">
                    <tbody>
                        <tr className="bg-darken-1">
                            <td colspan="2">Service / Maintenance / Warranty</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Service interval</td>
                            <td><span className="pull-right">15 000 km</span></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td className="align-top">Service Plan</td>
                            <td><span className="pull-right">24 months</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Warranty</td>
                            <td><span className="pull-right">5 YEAR/150000KM, 6 YEAR COROSSION, 2 YEAR/30000KM SERVICE
                                PLAN</span></td>
                        </tr>
                    </tbody>
                </table>
                <table className="table-light">
                    <tbody>
                        <tr className="bg-darken-1">
                            <td colspan="2">Active and Passive Safety</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">ABS</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Electronic brakeforce distribution (EBD)</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Air bags</td>
                            <td><span className="pull-right">2</span></td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Performance</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Engine size</td>
                            <td className="pull-right">999cc</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>CO2</td>
                            <td className="pull-right">104g/Km</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Engine</td>
                            <td className="pull-right">TRANSVERSE INLINE 3</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Power</td>
                            <td className="pull-right">50kW@5500</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Torque</td>
                            <td className="pull-right">91Nm@4250</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Valves</td>
                            <td className="pull-right">SOHC, 4 VALVES PER CYLINDER</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Fuel</td>
                            <td className="pull-right">UNLEADED</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Fuel capacity</td>
                            <td className="pull-right">28 litres</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>City fuel consumption</td>
                            <td className="pull-right">5.1 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Highway fuel consumption</td>
                            <td className="pull-right">4 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Average fuel consumption</td>
                            <td className="pull-right">4.4 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Top speed</td>
                            <td className="pull-right">152Km/h</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>0 - 100 Km/h</td>
                            <td className="pull-right">0sec</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Driving and Control Environment</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Gearbox</td>
                            <td className="pull-right">AUTOMATIC</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Gears</td>
                            <td className="pull-right">5</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Drive</td>
                            <td className="pull-right">2</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Power steering</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Computer</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Seats</td>
                            <td className="pull-right">5 -
                            {/* <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span> */}
                            </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Spare tire size</td>
                            <td className="pull-right">FULLSIZE</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Keyless Entry</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Interior Comfort</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Air conditioner</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Electric windows</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Electric window detail</td>
                            <td className="pull-right">FRONT + REAR</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Radio</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Remote side mirrors</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Exterior</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Metallic paint</td>
                            <td className="pull-right">
                                OPTION
                        </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Colour coded bumpers</td>
                            <td className="pull-right">
                                PAINTED
                        </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Colour coded mirrors</td>
                            <td className="pull-right">
                                BODY
                        </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Park distance - rear</td>
                            <td className="pull-right">
                                YES
                        </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Satelite navigation</td>
                            <td className="pull-right">
                                YES
                        </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Boot capacity</td>
                            <td className="pull-right">
                                279 litres
                        </td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </section>
        <section>
            <h4 className="bg-tertiary mb1 px2 py1 i-amphtml-accordion-header" id="4_AMP_header_4" role="button"
                aria-controls="4_AMP_content_4" aria-expanded="false" tabindex="0">1.0 CLIMBER (ABS) <span
                    className="pull-right">R 183 900</span></h4>
            <div className="bg-tertiary i-amphtml-accordion-content" id="4_AMP_content_4" aria-labelledby="4_AMP_header_4"
                role="region">
                <table className="table-light">
                    <tbody>
                        <tr className="bg-darken-1">
                            <td colspan="2">Service / Maintenance / Warranty</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Service interval</td>
                            <td><span className="pull-right">15 000 km</span></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td className="align-top">Service Plan</td>
                            <td><span className="pull-right">24 months</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Warranty</td>
                            <td><span className="pull-right">5 YEAR/150000KM, 6 YEAR COROSSION, 2 YEAR/30000KM SERVICE
                                PLAN</span></td>
                        </tr>
                    </tbody>
                </table>
                <table className="table-light">
                    <tbody>
                        <tr className="bg-darken-1">
                            <td colspan="2">Active and Passive Safety</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">ABS</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Electronic brakeforce distribution (EBD)</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Air bags</td>
                            <td><span className="pull-right">2</span></td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Performance</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Engine size</td>
                            <td className="pull-right">999cc</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>CO2</td>
                            <td className="pull-right">112g/Km</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Engine</td>
                            <td className="pull-right">TRANSVERSE INLINE 3</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Power</td>
                            <td className="pull-right">50kW@5500</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Torque</td>
                            <td className="pull-right">91Nm@4250</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Valves</td>
                            <td className="pull-right">SOHC, 4 VALVES PER CYLINDER</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Fuel</td>
                            <td className="pull-right">UNLEADED</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Fuel capacity</td>
                            <td className="pull-right">28 litres</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>City fuel consumption</td>
                            <td className="pull-right">5.9 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Highway fuel consumption</td>
                            <td className="pull-right">4.1 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Average fuel consumption</td>
                            <td className="pull-right">4.7 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Top speed</td>
                            <td className="pull-right">152Km/h</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>0 - 100 Km/h</td>
                            <td className="pull-right">0sec</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Driving and Control Environment</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Gearbox</td>
                            <td className="pull-right">MANUAL</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Gears</td>
                            <td className="pull-right">5</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Drive</td>
                            <td className="pull-right">2</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Power steering</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Computer</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Seats</td>
                            <td className="pull-right">5 -
                            {/* <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span> */}
                            </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Spare tire size</td>
                            <td className="pull-right">FULLSIZE</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Interior Comfort</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Air conditioner</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Electric windows</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Electric window detail</td>
                            <td className="pull-right">FRONT + REAR</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Radio</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Remote side mirrors</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Exterior</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Metallic paint</td>
                            <td className="pull-right">
                                OPTION
                        </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Colour coded bumpers</td>
                            <td className="pull-right">
                                PAINTED
                        </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Colour coded mirrors</td>
                            <td className="pull-right">
                                ORANGE &amp; BLACK
                        </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Park distance - rear</td>
                            <td className="pull-right">
                                YES
                        </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Satelite navigation</td>
                            <td className="pull-right">
                                YES
                        </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Boot capacity</td>
                            <td className="pull-right">
                                279 litres
                        </td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </section>
        <section>
            <h4 className="bg-tertiary mb1 px2 py1 i-amphtml-accordion-header" id="4_AMP_header_5" role="button"
                aria-controls="4_AMP_content_5" aria-expanded="false" tabindex="0">1.0 CLIMBER AMT (ABS) <span
                    className="pull-right">R 193 900</span></h4>
            <div className="bg-tertiary i-amphtml-accordion-content" id="4_AMP_content_5" aria-labelledby="4_AMP_header_5"
                role="region">
                <table className="table-light">
                    <tbody>
                        <tr className="bg-darken-1">
                            <td colspan="2">Service / Maintenance / Warranty</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Service interval</td>
                            <td><span className="pull-right">15 000 km</span></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td className="align-top">Service Plan</td>
                            <td><span className="pull-right">24 months</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Warranty</td>
                            <td><span className="pull-right">5 YEAR/150000KM, 6 YEAR COROSSION, 2 YEAR/30000KM SERVICE
                                PLAN</span></td>
                        </tr>
                    </tbody>
                </table>
                <table className="table-light">
                    <tbody>
                        <tr className="bg-darken-1">
                            <td colspan="2">Active and Passive Safety</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">ABS</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Electronic brakeforce distribution (EBD)</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Air bags</td>
                            <td><span className="pull-right">2</span></td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Performance</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Engine size</td>
                            <td className="pull-right">999cc</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>CO2</td>
                            <td className="pull-right">104g/Km</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Engine</td>
                            <td className="pull-right">TRANSVERSE INLINE 3</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Power</td>
                            <td className="pull-right">50kW@5500</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Torque</td>
                            <td className="pull-right">91Nm@4250</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Valves</td>
                            <td className="pull-right">SOHC, 4 VALVES PER CYLINDER</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Fuel</td>
                            <td className="pull-right">UNLEADED</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Fuel capacity</td>
                            <td className="pull-right">28 litres</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>City fuel consumption</td>
                            <td className="pull-right">5.1 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Highway fuel consumption</td>
                            <td className="pull-right">4 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Average fuel consumption</td>
                            <td className="pull-right">4.4 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Top speed</td>
                            <td className="pull-right">152Km/h</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>0 - 100 Km/h</td>
                            <td className="pull-right">0sec</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Driving and Control Environment</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Gearbox</td>
                            <td className="pull-right">AUTOMATIC</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Gears</td>
                            <td className="pull-right">5</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Drive</td>
                            <td className="pull-right">2</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Power steering</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Computer</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Seats</td>
                            <td className="pull-right">5 -
                            {/* <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span> */}
                            </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Spare tire size</td>
                            <td className="pull-right">FULLSIZE</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Interior Comfort</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Air conditioner</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Electric windows</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Electric window detail</td>
                            <td className="pull-right">FRONT + REAR</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Radio</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Remote side mirrors</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Exterior</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Metallic paint</td>
                            <td className="pull-right">
                                OPTION
                        </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Colour coded bumpers</td>
                            <td className="pull-right">
                                PAINTED
                        </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Colour coded mirrors</td>
                            <td className="pull-right">
                                ORANGE &amp; BLACK
                        </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Park distance - rear</td>
                            <td className="pull-right">
                                YES
                        </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Satelite navigation</td>
                            <td className="pull-right">
                                YES
                        </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Boot capacity</td>
                            <td className="pull-right">
                                279 litres
                        </td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </section>
    </amp-accordion>
)

export const triberSpecs = (
    <amp-accordion className="i-amphtml-element i-amphtml-layout-container i-amphtml-built i-amphtml-layout"
        i-amphtml-layout="container">
        <section>
            <h4 className="bg-tertiary mb1 px2 py1 i-amphtml-accordion-header" id="38_AMP_header_0" role="button"
                aria-controls="38_AMP_content_0" aria-expanded="false" tabindex="0">1.0 EXPRESSION <span
                    className="pull-right">R 183 900</span></h4>
            <div className="bg-tertiary i-amphtml-accordion-content" id="38_AMP_content_0" aria-labelledby="38_AMP_header_0"
                role="region">
                <table className="table-light">
                    <tbody>
                        <tr className="bg-darken-1">
                            <td colspan="2">Service / Maintenance / Warranty</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Service interval</td>
                            <td><span className="pull-right">15 000 km</span></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td className="align-top">Service Plan</td>
                            <td><span className="pull-right">24 months</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Warranty</td>
                            <td><span className="pull-right">5 YEAR/150000KM WARRANTY, 2 YEAR/30000KM SERVICE PLAN, 5 YEAR
                                  ANTI-CORROSION, 5 YEAR/150000KM ROADSIDE ASSIST</span></td>
                        </tr>
                    </tbody>
                </table>
                <table className="table-light">
                    <tbody>
                        <tr className="bg-darken-1">
                            <td colspan="2">Active and Passive Safety</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">ABS</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Electronic brakeforce distribution (EBD)</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Air bags</td>
                            <td><span className="pull-right">2</span></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td className="align-top">Autolock</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Performance</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Engine size</td>
                            <td className="pull-right">999cc</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>CO2</td>
                            <td className="pull-right">131g/Km</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Engine</td>
                            <td className="pull-right">TRANSVERSE INLINE 3</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Power</td>
                            <td className="pull-right">52kW@6250</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Torque</td>
                            <td className="pull-right">96Nm@3500</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Valves</td>
                            <td className="pull-right">DOHC, 4 VALVES PER CYLINDER, VVT</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Fuel</td>
                            <td className="pull-right">UNLEADED</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Fuel capacity</td>
                            <td className="pull-right">40 litres</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>City fuel consumption</td>
                            <td className="pull-right">6.9 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Highway fuel consumption</td>
                            <td className="pull-right">4.7 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Average fuel consumption</td>
                            <td className="pull-right">5.5 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Top speed</td>
                            <td className="pull-right">0Km/h</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>0 - 100 Km/h</td>
                            <td className="pull-right">0sec</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Driving and Control Environment</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Gearbox</td>
                            <td className="pull-right">MANUAL</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Gears</td>
                            <td className="pull-right">5</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Drive</td>
                            <td className="pull-right">2</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Power steering</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Computer</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Seats</td>
                            <td className="pull-right">7 -
                              {/* <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span> */}
                            </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Spare tire size</td>
                            <td className="pull-right">FULLSIZE</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Keyless Entry</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Interior Comfort</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Air conditioner</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Electric windows</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Electric window detail</td>
                            <td className="pull-right">FRONT</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Radio</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Exterior</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Metallic paint</td>
                            <td className="pull-right">
                                OPTION
                          </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Colour coded bumpers</td>
                            <td className="pull-right">
                                PAINTED
                          </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Colour coded mirrors</td>
                            <td className="pull-right">
                                BODY
                          </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Park distance - rear</td>
                            <td className="pull-right">
                                YES
                          </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Boot capacity</td>
                            <td className="pull-right">
                                84 litres
                          </td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </section>
        <section>
            <h4 className="bg-tertiary mb1 px2 py1 i-amphtml-accordion-header" id="38_AMP_header_1" role="button"
                aria-controls="38_AMP_content_1" aria-expanded="false" tabindex="0">1.0 DYNAMIQUE <span className="pull-right">R
                  193 900</span></h4>
            <div className="bg-tertiary i-amphtml-accordion-content" id="38_AMP_content_1" aria-labelledby="38_AMP_header_1"
                role="region">
                <table className="table-light">
                    <tbody>
                        <tr className="bg-darken-1">
                            <td colspan="2">Service / Maintenance / Warranty</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Service interval</td>
                            <td><span className="pull-right">15 000 km</span></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td className="align-top">Service Plan</td>
                            <td><span className="pull-right">24 months</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Warranty</td>
                            <td><span className="pull-right">5 YEAR/150000KM WARRANTY, 2 YEAR/30000KM SERVICE PLAN, 5 YEAR
                                  ANTI-CORROSION, 5 YEAR/150000KM ROADSIDE ASSIST</span></td>
                        </tr>
                    </tbody>
                </table>
                <table className="table-light">
                    <tbody>
                        <tr className="bg-darken-1">
                            <td colspan="2">Active and Passive Safety</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">ABS</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Electronic brakeforce distribution (EBD)</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Air bags</td>
                            <td><span className="pull-right">2</span></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td className="align-top">Autolock</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Performance</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Engine size</td>
                            <td className="pull-right">999cc</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>CO2</td>
                            <td className="pull-right">131g/Km</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Engine</td>
                            <td className="pull-right">TRANSVERSE INLINE 3</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Power</td>
                            <td className="pull-right">52kW@6250</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Torque</td>
                            <td className="pull-right">96Nm@3500</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Valves</td>
                            <td className="pull-right">DOHC, 4 VALVES PER CYLINDER, VVT</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Fuel</td>
                            <td className="pull-right">UNLEADED</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Fuel capacity</td>
                            <td className="pull-right">40 litres</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>City fuel consumption</td>
                            <td className="pull-right">6.9 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Highway fuel consumption</td>
                            <td className="pull-right">4.7 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Average fuel consumption</td>
                            <td className="pull-right">5.5 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Top speed</td>
                            <td className="pull-right">0Km/h</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>0 - 100 Km/h</td>
                            <td className="pull-right">0sec</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Driving and Control Environment</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Gearbox</td>
                            <td className="pull-right">MANUAL</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Gears</td>
                            <td className="pull-right">5</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Drive</td>
                            <td className="pull-right">2</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Power steering</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Computer</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Seats</td>
                            <td className="pull-right">7 -
                              {/* <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span> */}
                            </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Spare tire size</td>
                            <td className="pull-right">FULLSIZE</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Keyless Entry</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Interior Comfort</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Air conditioner</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Electric windows</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Electric window detail</td>
                            <td className="pull-right">FRONT + REAR</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Radio</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Remote side mirrors</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Exterior</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Metallic paint</td>
                            <td className="pull-right">
                                OPTION
                          </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Colour coded bumpers</td>
                            <td className="pull-right">
                                PAINTED
                          </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Colour coded mirrors</td>
                            <td className="pull-right">
                                BODY
                          </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Park distance - rear</td>
                            <td className="pull-right">
                                YES
                          </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Boot capacity</td>
                            <td className="pull-right">
                                84 litres
                          </td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </section>
        <section>
            <h4 className="bg-tertiary mb1 px2 py1 i-amphtml-accordion-header" id="38_AMP_header_2" role="button"
                aria-controls="38_AMP_content_2" aria-expanded="false" tabindex="0">1.0 PRESTIGE <span className="pull-right">R
                  209 900</span></h4>
            <div className="bg-tertiary i-amphtml-accordion-content" id="38_AMP_content_2" aria-labelledby="38_AMP_header_2"
                role="region">
                <table className="table-light">
                    <tbody>
                        <tr className="bg-darken-1">
                            <td colspan="2">Service / Maintenance / Warranty</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Service interval</td>
                            <td><span className="pull-right">15 000 km</span></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td className="align-top">Service Plan</td>
                            <td><span className="pull-right">24 months</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Warranty</td>
                            <td><span className="pull-right">5 YEAR/150000KM WARRANTY, 2 YEAR/30000KM SERVICE PLAN, 5 YEAR
                                  ANTI-CORROSION, 5 YEAR/150000KM ROADSIDE ASSIST</span></td>
                        </tr>
                    </tbody>
                </table>
                <table className="table-light">
                    <tbody>
                        <tr className="bg-darken-1">
                            <td colspan="2">Active and Passive Safety</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">ABS</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Electronic brakeforce distribution (EBD)</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Air bags</td>
                            <td><span className="pull-right">4</span></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td className="align-top">Autolock</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Performance</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Engine size</td>
                            <td className="pull-right">999cc</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>CO2</td>
                            <td className="pull-right">131g/Km</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Engine</td>
                            <td className="pull-right">TRANSVERSE INLINE 3</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Power</td>
                            <td className="pull-right">52kW@6250</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Torque</td>
                            <td className="pull-right">96Nm@3500</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Valves</td>
                            <td className="pull-right">DOHC, 4 VALVES PER CYLINDER, VVT</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Fuel</td>
                            <td className="pull-right">UNLEADED</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Fuel capacity</td>
                            <td className="pull-right">40 litres</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>City fuel consumption</td>
                            <td className="pull-right">6.9 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Highway fuel consumption</td>
                            <td className="pull-right">4.7 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Average fuel consumption</td>
                            <td className="pull-right">5.5 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Top speed</td>
                            <td className="pull-right">0Km/h</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>0 - 100 Km/h</td>
                            <td className="pull-right">0sec</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Driving and Control Environment</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Gearbox</td>
                            <td className="pull-right">MANUAL</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Gears</td>
                            <td className="pull-right">5</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Drive</td>
                            <td className="pull-right">2</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Power steering</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Computer</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Seats</td>
                            <td className="pull-right">7 -
                              {/* <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span> */}
                            </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Spare tire size</td>
                            <td className="pull-right">FULLSIZE</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Keyless Entry</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Interior Comfort</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Air conditioner</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Electric windows</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Electric window detail</td>
                            <td className="pull-right">FRONT + REAR</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Electric window 1-touch</td>
                            <td className="pull-right">DRIVER</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Radio</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Remote side mirrors</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Exterior</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Metallic paint</td>
                            <td className="pull-right">
                                OPTION
                          </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Colour coded bumpers</td>
                            <td className="pull-right">
                                PAINTED
                          </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Colour coded mirrors</td>
                            <td className="pull-right">
                                BODY
                          </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Park distance - rear</td>
                            <td className="pull-right">
                                YES
                          </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Boot capacity</td>
                            <td className="pull-right">
                                84 litres
                          </td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </section>
        <section>
            <h4 className="bg-tertiary mb1 px2 py1 i-amphtml-accordion-header" id="38_AMP_header_3" role="button"
                aria-controls="38_AMP_content_3" aria-expanded="false" tabindex="0">1.0 PRESTIGE AMT <span
                    className="pull-right">R 219 900</span></h4>
            <div className="bg-tertiary i-amphtml-accordion-content" id="38_AMP_content_3" aria-labelledby="38_AMP_header_3"
                role="region">
                <table className="table-light">
                    <tbody>
                        <tr className="bg-darken-1">
                            <td colspan="2">Service / Maintenance / Warranty</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Service interval</td>
                            <td><span className="pull-right">15 000 km</span></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td className="align-top">Service Plan</td>
                            <td><span className="pull-right">24 months</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Warranty</td>
                            <td><span className="pull-right">5 YEAR/150000KM WARRANTY, 2 YEAR/30000KM SERVICE PLAN, 5 YEAR
                                  ANTI-CORROSION, 5 YEAR/150000KM ROADSIDE ASSIST</span></td>
                        </tr>
                    </tbody>
                </table>
                <table className="table-light">
                    <tbody>
                        <tr className="bg-darken-1">
                            <td colspan="2">Active and Passive Safety</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">ABS</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Electronic brakeforce distribution (EBD)</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Air bags</td>
                            <td><span className="pull-right">4</span></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td className="align-top">Autolock</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Performance</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Engine size</td>
                            <td className="pull-right">999cc</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>CO2</td>
                            <td className="pull-right">131g/Km</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Engine</td>
                            <td className="pull-right">TRANSVERSE INLINE 3</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Power</td>
                            <td className="pull-right">52kW@6250</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Torque</td>
                            <td className="pull-right">96Nm@3500</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Valves</td>
                            <td className="pull-right">DOHC, 4 VALVES PER CYLINDER, VVT</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Fuel</td>
                            <td className="pull-right">UNLEADED</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Fuel capacity</td>
                            <td className="pull-right">40 litres</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>City fuel consumption</td>
                            <td className="pull-right">6.9 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Highway fuel consumption</td>
                            <td className="pull-right">4.7 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Average fuel consumption</td>
                            <td className="pull-right">5.5 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Top speed</td>
                            <td className="pull-right">0Km/h</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>0 - 100 Km/h</td>
                            <td className="pull-right">0sec</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Driving and Control Environment</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Gearbox</td>
                            <td className="pull-right">AUTOMATIC</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Gears</td>
                            <td className="pull-right">5</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Drive</td>
                            <td className="pull-right">2</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Power steering</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Computer</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Seats</td>
                            <td className="pull-right">7 -
                              {/* <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span> */}
                            </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Spare tire size</td>
                            <td className="pull-right">FULLSIZE</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Keyless Entry</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Interior Comfort</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Air conditioner</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Electric windows</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Electric window detail</td>
                            <td className="pull-right">FRONT + REAR</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Electric window 1-touch</td>
                            <td className="pull-right">DRIVER</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Radio</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Remote side mirrors</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Exterior</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Metallic paint</td>
                            <td className="pull-right">
                                OPTION
                          </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Colour coded bumpers</td>
                            <td className="pull-right">
                                PAINTED
                          </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Colour coded mirrors</td>
                            <td className="pull-right">
                                BODY
                          </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Park distance - rear</td>
                            <td className="pull-right">
                                YES
                          </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Boot capacity</td>
                            <td className="pull-right">
                                84 litres
                          </td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </section>
    </amp-accordion>
)

export const sanderoSpecs = (
    <amp-accordion className="i-amphtml-element i-amphtml-layout-container i-amphtml-built i-amphtml-layout"
        i-amphtml-layout="container">
        <section>
            <h4 className="bg-tertiary mb1 px2 py1 i-amphtml-accordion-header" id="28_AMP_header_0" role="button"
                aria-controls="28_AMP_content_0" aria-expanded="false" tabindex="0">0.9 TURBO EXPRESSION A/C <span
                    className="pull-right">R 217 900</span></h4>
            <div className="bg-tertiary i-amphtml-accordion-content" id="28_AMP_content_0" aria-labelledby="28_AMP_header_0"
                role="region">
                <table className="table-light">
                    <tbody>
                        <tr className="bg-darken-1">
                            <td colspan="2">Service / Maintenance / Warranty</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Service interval</td>
                            <td><span className="pull-right">15 000 km</span></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td className="align-top">Service Plan</td>
                            <td><span className="pull-right">24 months</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Warranty</td>
                            <td><span className="pull-right">5 YEAR/150000KM, 6 YEAR COROSSION, 5 YEAR ROADSIDE ASSISTANCE, 2
                                YEAR/30000KM SERVICE PLAN</span></td>
                        </tr>
                    </tbody>
                </table>
                <table className="table-light">
                    <tbody>
                        <tr className="bg-darken-1">
                            <td colspan="2">Active and Passive Safety</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">ABS</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Electronic brakeforce distribution (EBD)</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Air bags</td>
                            <td><span className="pull-right">2</span></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td className="align-top">Autolock</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">ISOfix (secure child seat)</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Performance</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Engine size</td>
                            <td className="pull-right">898cc</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>CO2</td>
                            <td className="pull-right">119g/Km</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Engine</td>
                            <td className="pull-right">TRANSVERSE INLINE 3</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Power</td>
                            <td className="pull-right">66kW@5250</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Torque</td>
                            <td className="pull-right">135Nm@2500</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Valves</td>
                            <td className="pull-right">SOHC, 2 VALVES PER CYLINDER, VVT</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Fuel</td>
                            <td className="pull-right">UNLEADED</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Fuel capacity</td>
                            <td className="pull-right">50 litres</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>City fuel consumption</td>
                            <td className="pull-right">6.4 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Highway fuel consumption</td>
                            <td className="pull-right">4.4 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Average fuel consumption</td>
                            <td className="pull-right">5.2 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Top speed</td>
                            <td className="pull-right">169Km/h</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>0 - 100 Km/h</td>
                            <td className="pull-right">11.1sec</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Driving and Control Environment</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Gearbox</td>
                            <td className="pull-right">MANUAL</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Gears</td>
                            <td className="pull-right">5</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Drive</td>
                            <td className="pull-right">2</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Power steering</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Steering adjustment (up down)</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Multi function steering wheel</td>
                            <td className="pull-right">AUDIO, PHONE</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Seats</td>
                            <td className="pull-right">5 -
                            {/* <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span> */}
                            </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Spare tire size</td>
                            <td className="pull-right">FULLSIZE</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Keyless Entry</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Interior Comfort</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Air conditioner</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Electric windows</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Electric window detail</td>
                            <td className="pull-right">FRONT</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Radio</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Remote side mirrors</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Exterior</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Metallic paint</td>
                            <td className="pull-right">
                                OPTION
                        </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Colour coded bumpers</td>
                            <td className="pull-right">
                                PAINTED
                        </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Colour coded mirrors</td>
                            <td className="pull-right">
                                BODY
                        </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Fog lamps - front</td>
                            <td className="pull-right">
                                OPTION
                        </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Alloy wheels</td>
                            <td className="pull-right">
                                OPT
                        </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Boot capacity</td>
                            <td className="pull-right">
                                292 litres
                        </td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </section>
        <section>
            <h4 className="bg-tertiary mb1 px2 py1 i-amphtml-accordion-header" id="28_AMP_header_1" role="button"
                aria-controls="28_AMP_content_1" aria-expanded="false" tabindex="0">0.9 TURBO STEPWAY EXPRESSION <span
                    className="pull-right">R 231 900</span></h4>
            <div className="bg-tertiary i-amphtml-accordion-content" id="28_AMP_content_1" aria-labelledby="28_AMP_header_1"
                role="region">
                <table className="table-light">
                    <tbody>
                        <tr className="bg-darken-1">
                            <td colspan="2">Service / Maintenance / Warranty</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Service interval</td>
                            <td><span className="pull-right">15 000 km</span></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td className="align-top">Service Plan</td>
                            <td><span className="pull-right">24 months</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Warranty</td>
                            <td><span className="pull-right">5 YEAR/150000KM, 6 YEAR COROSSION, 5 YEAR ROADSIDE ASSISTANCE, 2
                                YEAR/30000KM SERVICE PLAN</span></td>
                        </tr>
                    </tbody>
                </table>
                <table className="table-light">
                    <tbody>
                        <tr className="bg-darken-1">
                            <td colspan="2">Active and Passive Safety</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">ABS</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Electronic brakeforce distribution (EBD)</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Air bags</td>
                            <td><span className="pull-right">2</span></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td className="align-top">Autolock</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">ISOfix (secure child seat)</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Performance</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Engine size</td>
                            <td className="pull-right">898cc</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>CO2</td>
                            <td className="pull-right">124g/Km</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Engine</td>
                            <td className="pull-right">TRANSVERSE INLINE 3</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Power</td>
                            <td className="pull-right">66kW@5250</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Torque</td>
                            <td className="pull-right">135Nm@2500</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Valves</td>
                            <td className="pull-right">SOHC, 2 VALVES PER CYLINDER, VVT</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Fuel</td>
                            <td className="pull-right">UNLEADED</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Fuel capacity</td>
                            <td className="pull-right">50 litres</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>City fuel consumption</td>
                            <td className="pull-right">6.7 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Highway fuel consumption</td>
                            <td className="pull-right">4.6 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Average fuel consumption</td>
                            <td className="pull-right">5.4 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Top speed</td>
                            <td className="pull-right">168Km/h</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>0 - 100 Km/h</td>
                            <td className="pull-right">11.1sec</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Driving and Control Environment</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Gearbox</td>
                            <td className="pull-right">MANUAL</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Gears</td>
                            <td className="pull-right">5</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Drive</td>
                            <td className="pull-right">2</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Power steering</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Steering adjustment (up down)</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Multi function steering wheel</td>
                            <td className="pull-right">AUDIO, PHONE</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Seats</td>
                            <td className="pull-right">5 -
                            {/* <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span> */}
                            </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Spare tire size</td>
                            <td className="pull-right">FULLSIZE</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Keyless Entry</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Interior Comfort</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Air conditioner</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Electric windows</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Electric window detail</td>
                            <td className="pull-right">FRONT</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Radio</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Remote side mirrors</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Exterior</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Metallic paint</td>
                            <td className="pull-right">
                                OPTION
                        </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Colour coded bumpers</td>
                            <td className="pull-right">
                                PAINTED
                        </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Colour coded mirrors</td>
                            <td className="pull-right">
                                METALLIC GREY
                        </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Alloy wheels</td>
                            <td className="pull-right">
                                OPT
                        </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Boot capacity</td>
                            <td className="pull-right">
                                292 litres
                        </td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </section>
        <section>
            <h4 className="bg-tertiary mb1 px2 py1 i-amphtml-accordion-header" id="28_AMP_header_2" role="button"
                aria-controls="28_AMP_content_2" aria-expanded="false" tabindex="0">0.9 TURBO STEPWAY PLUS <span
                    className="pull-right">R 251 900</span></h4>
            <div className="bg-tertiary i-amphtml-accordion-content" id="28_AMP_content_2" aria-labelledby="28_AMP_header_2"
                role="region">
                <table className="table-light">
                    <tbody>
                        <tr className="bg-darken-1">
                            <td colspan="2">Service / Maintenance / Warranty</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Service interval</td>
                            <td><span className="pull-right">15 000 km</span></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td className="align-top">Service Plan</td>
                            <td><span className="pull-right">24 months</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Warranty</td>
                            <td><span className="pull-right">5 YEAR/150000KM, 6 YEAR COROSSION, 5 YEAR ROADSIDE ASSISTANCE, 2
                                YEAR/30000KM SERVICE PLAN</span></td>
                        </tr>
                    </tbody>
                </table>
                <table className="table-light">
                    <tbody>
                        <tr className="bg-darken-1">
                            <td colspan="2">Active and Passive Safety</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">ABS</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Electronic brakeforce distribution (EBD)</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Air bags</td>
                            <td><span className="pull-right">4</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Day Running Lights</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td className="align-top">Autolock</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">ISOfix (secure child seat)</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Performance</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Engine size</td>
                            <td className="pull-right">898cc</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>CO2</td>
                            <td className="pull-right">124g/Km</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Engine</td>
                            <td className="pull-right">TRANSVERSE INLINE 3</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Power</td>
                            <td className="pull-right">66kW@5250</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Torque</td>
                            <td className="pull-right">135Nm@2500</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Valves</td>
                            <td className="pull-right">SOHC, 2 VALVES PER CYLINDER, VVT</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Fuel</td>
                            <td className="pull-right">UNLEADED</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Fuel capacity</td>
                            <td className="pull-right">50 litres</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>City fuel consumption</td>
                            <td className="pull-right">6.4 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Highway fuel consumption</td>
                            <td className="pull-right">4.4 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Average fuel consumption</td>
                            <td className="pull-right">5.4 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Top speed</td>
                            <td className="pull-right">168Km/h</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>0 - 100 Km/h</td>
                            <td className="pull-right">11.1sec</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Driving and Control Environment</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Gearbox</td>
                            <td className="pull-right">MANUAL</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Gears</td>
                            <td className="pull-right">5</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Drive</td>
                            <td className="pull-right">2</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Power steering</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Steering adjustment (up down)</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Multi function steering wheel</td>
                            <td className="pull-right">AUDIO, PHONE</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Cruise control</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Computer</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Seats</td>
                            <td className="pull-right">5 -
                            {/* <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span> */}
                            </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Spare tire size</td>
                            <td className="pull-right">FULLSIZE</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Keyless Entry</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Interior Comfort</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Air conditioner</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Electric windows</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Electric window detail</td>
                            <td className="pull-right">FRONT + REAR</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Radio</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Leather</td>
                            <td className="pull-right">OPTION</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Remote side mirrors</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Exterior</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Metallic paint</td>
                            <td className="pull-right">
                                OPTION
                        </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Colour coded bumpers</td>
                            <td className="pull-right">
                                PAINTED
                        </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Colour coded mirrors</td>
                            <td className="pull-right">
                                METALLIC GREY
                        </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Park distance - rear</td>
                            <td className="pull-right">
                                YES
                        </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Satelite navigation</td>
                            <td className="pull-right">
                                YES
                        </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Alloy wheels</td>
                            <td className="pull-right">
                                OPT
                        </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Boot capacity</td>
                            <td className="pull-right">
                                292 litres
                        </td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </section>
        <section>
            <h4 className="bg-tertiary mb1 px2 py1 i-amphtml-accordion-header" id="28_AMP_header_3" role="button"
                aria-controls="28_AMP_content_3" aria-expanded="false" tabindex="0">0.9 TURBO STEPWAY TECHROAD <span
                    className="pull-right">R 251 900</span></h4>
            <div className="bg-tertiary i-amphtml-accordion-content" id="28_AMP_content_3" aria-labelledby="28_AMP_header_3"
                role="region">
                <table className="table-light">
                    <tbody>
                        <tr className="bg-darken-1">
                            <td colspan="2">Service / Maintenance / Warranty</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Service interval</td>
                            <td><span className="pull-right">15 000 km</span></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td className="align-top">Service Plan</td>
                            <td><span className="pull-right">24 months</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Warranty</td>
                            <td><span className="pull-right">5 YEAR/150000KM, 6 YEAR COROSSION, 5 YEAR ROADSIDE ASSISTANCE, 2
                                YEAR/30000KM SERVICE PLAN</span></td>
                        </tr>
                    </tbody>
                </table>
                <table className="table-light">
                    <tbody>
                        <tr className="bg-darken-1">
                            <td colspan="2">Active and Passive Safety</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">ABS</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Electronic brakeforce distribution (EBD)</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Air bags</td>
                            <td><span className="pull-right">4</span></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td className="align-top">Autolock</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">ISOfix (secure child seat)</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Performance</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Engine size</td>
                            <td className="pull-right">898cc</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>CO2</td>
                            <td className="pull-right">124g/Km</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Engine</td>
                            <td className="pull-right">TRANSVERSE INLINE 3</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Power</td>
                            <td className="pull-right">66kW@5250</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Torque</td>
                            <td className="pull-right">135Nm@2500</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Valves</td>
                            <td className="pull-right">SOHC, 2 VALVES PER CYLINDER, VVT</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Fuel</td>
                            <td className="pull-right">UNLEADED</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Fuel capacity</td>
                            <td className="pull-right">50 litres</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>City fuel consumption</td>
                            <td className="pull-right">6.4 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Highway fuel consumption</td>
                            <td className="pull-right">4.4 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Average fuel consumption</td>
                            <td className="pull-right">5.4 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Top speed</td>
                            <td className="pull-right">168Km/h</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>0 - 100 Km/h</td>
                            <td className="pull-right">11.1sec</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Driving and Control Environment</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Gearbox</td>
                            <td className="pull-right">MANUAL</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Gears</td>
                            <td className="pull-right">5</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Drive</td>
                            <td className="pull-right">2</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Power steering</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Steering adjustment (up down)</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Multi function steering wheel</td>
                            <td className="pull-right">AUDIO, PHONE</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Cruise control</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Computer</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Seats</td>
                            <td className="pull-right"> -
                        </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Spare tire size</td>
                            <td className="pull-right">FULLSIZE</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Keyless Entry</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Interior Comfort</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Air conditioner</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Electric windows</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Electric window detail</td>
                            <td className="pull-right">FRONT</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Radio</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Leather</td>
                            <td className="pull-right">OPTION</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Remote side mirrors</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Exterior</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Metallic paint</td>
                            <td className="pull-right">
                                OPTION
                        </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Colour coded bumpers</td>
                            <td className="pull-right">
                                PAINTED
                        </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Colour coded mirrors</td>
                            <td className="pull-right">
                                GLOSS BLACK
                        </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Park distance - rear</td>
                            <td className="pull-right">
                                YES
                        </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Satelite navigation</td>
                            <td className="pull-right">
                                YES
                        </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Alloy wheels</td>
                            <td className="pull-right">
                                OPT
                        </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Boot capacity</td>
                            <td className="pull-right">
                                292 litres
                        </td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </section>
    </amp-accordion>
)

export const clioSpecs = (
    <amp-accordion className="i-amphtml-element i-amphtml-layout-container i-amphtml-built i-amphtml-layout"
        i-amphtml-layout="container">
        <section>
            <h4 className="bg-tertiary mb1 px2 py1 i-amphtml-accordion-header" id="24_AMP_header_0" role="button"
                aria-controls="24_AMP_content_0" aria-expanded="false" tabindex="0">0.9 AUTHENTIQUE TURBO <span
                    className="pull-right">R 261 900</span></h4>
            <div className="bg-tertiary i-amphtml-accordion-content" id="24_AMP_content_0" aria-labelledby="24_AMP_header_0"
                role="region">
                <table className="table-light">
                    <tbody>
                        <tr className="bg-darken-1">
                            <td colspan="2">Service / Maintenance / Warranty</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Service interval</td>
                            <td><span className="pull-right">15 000 km</span></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td className="align-top">Service Plan</td>
                            <td><span className="pull-right">36 months</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Warranty</td>
                            <td><span className="pull-right">5 YEAR/150000KM, 6 YEAR COROSSION, 3 YEAR/45000KM SERVICE
                                PLAN</span></td>
                        </tr>
                    </tbody>
                </table>
                <table className="table-light">
                    <tbody>
                        <tr className="bg-darken-1">
                            <td colspan="2">Active and Passive Safety</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">ABS</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Electronic brakeforce distribution (EBD)</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Air bags</td>
                            <td><span className="pull-right">4</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Day Running Lights</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td className="align-top">Autolock</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Performance</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Engine size</td>
                            <td className="pull-right">898cc</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>CO2</td>
                            <td className="pull-right">126g/Km</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Engine</td>
                            <td className="pull-right">TRANSVERSE INLINE 3</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Power</td>
                            <td className="pull-right">66kW@5250</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Torque</td>
                            <td className="pull-right">140Nm@2500</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Valves</td>
                            <td className="pull-right">4 VALVES PER CYLINDER, VVT</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Fuel</td>
                            <td className="pull-right">UNLEADED</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Fuel capacity</td>
                            <td className="pull-right">45 litres</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>City fuel consumption</td>
                            <td className="pull-right">6.3 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Highway fuel consumption</td>
                            <td className="pull-right">4.3 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Average fuel consumption</td>
                            <td className="pull-right">5.5 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Top speed</td>
                            <td className="pull-right">178Km/h</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>0 - 100 Km/h</td>
                            <td className="pull-right">12.9sec</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Driving and Control Environment</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Gearbox</td>
                            <td className="pull-right">MANUAL</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Gears</td>
                            <td className="pull-right">5</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Drive</td>
                            <td className="pull-right">2</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Power steering</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Steering adjustment (up down)</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Steering adjustment (in out)</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Cruise control</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Computer</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Seats</td>
                            <td className="pull-right">5 -
                            {/* <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span> */}
                            </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Spare tire size</td>
                            <td className="pull-right">FULLSIZE</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Keyless Entry</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Interior Comfort</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Air conditioner</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Electric windows</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Electric window detail</td>
                            <td className="pull-right">FRONT</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Radio</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Remote side mirrors</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Exterior</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Metallic paint</td>
                            <td className="pull-right">
                                OPTION
                        </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Colour coded bumpers</td>
                            <td className="pull-right">
                                PAINTED
                        </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Colour coded mirrors</td>
                            <td className="pull-right">
                                BODY
                        </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Satelite navigation</td>
                            <td className="pull-right">
                                OPTION
                        </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Boot capacity</td>
                            <td className="pull-right">
                                300 litres
                        </td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </section>
        <section>
            <h4 className="bg-tertiary mb1 px2 py1 i-amphtml-accordion-header" id="24_AMP_header_1" role="button"
                aria-controls="24_AMP_content_1" aria-expanded="false" tabindex="0">0.9 TURBO EXPRESSION <span
                    className="pull-right">R 279 900</span></h4>
            <div className="bg-tertiary i-amphtml-accordion-content" id="24_AMP_content_1" aria-labelledby="24_AMP_header_1"
                role="region">
                <table className="table-light">
                    <tbody>
                        <tr className="bg-darken-1">
                            <td colspan="2">Service / Maintenance / Warranty</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Service interval</td>
                            <td><span className="pull-right">15 000 km</span></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td className="align-top">Service Plan</td>
                            <td><span className="pull-right">36 months</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Warranty</td>
                            <td><span className="pull-right">5 YEAR/150000KM, 6 YEAR COROSSION, 3 YEAR/45000KM SERVICE
                                PLAN</span></td>
                        </tr>
                    </tbody>
                </table>
                <table className="table-light">
                    <tbody>
                        <tr className="bg-darken-1">
                            <td colspan="2">Active and Passive Safety</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">ABS</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Electronic brakeforce distribution (EBD)</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Air bags</td>
                            <td><span className="pull-right">4</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Day Running Lights</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td className="align-top">Autolock</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Performance</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Engine size</td>
                            <td className="pull-right">898cc</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>CO2</td>
                            <td className="pull-right">126g/Km</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Engine</td>
                            <td className="pull-right">TRANSVERSE INLINE 3</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Power</td>
                            <td className="pull-right">66kW@5250</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Torque</td>
                            <td className="pull-right">140Nm@2500</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Valves</td>
                            <td className="pull-right">4 VALVES PER CYLINDER, VVT</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Fuel</td>
                            <td className="pull-right">UNLEADED</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Fuel capacity</td>
                            <td className="pull-right">45 litres</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>City fuel consumption</td>
                            <td className="pull-right">6.3 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Highway fuel consumption</td>
                            <td className="pull-right">4.3 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Average fuel consumption</td>
                            <td className="pull-right">5.5 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Top speed</td>
                            <td className="pull-right">178Km/h</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>0 - 100 Km/h</td>
                            <td className="pull-right">12.9sec</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Driving and Control Environment</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Gearbox</td>
                            <td className="pull-right">MANUAL</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Gears</td>
                            <td className="pull-right">5</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Drive</td>
                            <td className="pull-right">2</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Power steering</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Steering adjustment (up down)</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Steering adjustment (in out)</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Cruise control</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Computer</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Seats</td>
                            <td className="pull-right">5 -
                            {/* <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span> */}
                            </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Spare tire size</td>
                            <td className="pull-right">FULLSIZE</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Keyless Entry</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Interior Comfort</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Air conditioner</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Electric windows</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Electric window detail</td>
                            <td className="pull-right">FRONT</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Radio</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Remote side mirrors</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Exterior</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Metallic paint</td>
                            <td className="pull-right">
                                OPTION
                        </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Colour coded bumpers</td>
                            <td className="pull-right">
                                PAINTED
                        </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Colour coded mirrors</td>
                            <td className="pull-right">
                                BODY
                        </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Satelite navigation</td>
                            <td className="pull-right">
                                YES
                        </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Alloy wheels</td>
                            <td className="pull-right">
                                YES
                        </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Boot capacity</td>
                            <td className="pull-right">
                                300 litres
                        </td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </section>
        <section>
            <h4 className="bg-tertiary mb1 px2 py1 i-amphtml-accordion-header" id="24_AMP_header_2" role="button"
                aria-controls="24_AMP_content_2" aria-expanded="false" tabindex="0">0.9 TURBO DYNAMIQUE <span
                    className="pull-right">R 297 900</span></h4>
            <div className="bg-tertiary i-amphtml-accordion-content" id="24_AMP_content_2" aria-labelledby="24_AMP_header_2"
                role="region">
                <table className="table-light">
                    <tbody>
                        <tr className="bg-darken-1">
                            <td colspan="2">Service / Maintenance / Warranty</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Service interval</td>
                            <td><span className="pull-right">15 000 km</span></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td className="align-top">Service Plan</td>
                            <td><span className="pull-right">36 months</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Warranty</td>
                            <td><span className="pull-right">5 YEAR/150000KM, 6 YEAR COROSSION, 3 YEAR/45000KM SERVICE
                                PLAN</span></td>
                        </tr>
                    </tbody>
                </table>
                <table className="table-light">
                    <tbody>
                        <tr className="bg-darken-1">
                            <td colspan="2">Active and Passive Safety</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">ABS</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Electronic brakeforce distribution (EBD)</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Air bags</td>
                            <td><span className="pull-right">4</span></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td className="align-top">Automatic Lights</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Day Running Lights</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td className="align-top">Autolock</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Performance</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Engine size</td>
                            <td className="pull-right">898cc</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>CO2</td>
                            <td className="pull-right">105g/Km</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Engine</td>
                            <td className="pull-right">TRANSVERSE INLINE 3</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Power</td>
                            <td className="pull-right">66kW@5250</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Torque</td>
                            <td className="pull-right">140Nm@2500</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Valves</td>
                            <td className="pull-right">4 VALVES PER CYLINDER, VVT</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Fuel</td>
                            <td className="pull-right">UNLEADED</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Fuel capacity</td>
                            <td className="pull-right">45 litres</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>City fuel consumption</td>
                            <td className="pull-right">6.3 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Highway fuel consumption</td>
                            <td className="pull-right">4.3 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Average fuel consumption</td>
                            <td className="pull-right">5.5 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Top speed</td>
                            <td className="pull-right">178Km/h</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>0 - 100 Km/h</td>
                            <td className="pull-right">12.9sec</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Driving and Control Environment</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Gearbox</td>
                            <td className="pull-right">MANUAL</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Gears</td>
                            <td className="pull-right">5</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Drive</td>
                            <td className="pull-right">2</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Power steering</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Steering adjustment (up down)</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Steering adjustment (in out)</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Cruise control</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Computer</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Seats</td>
                            <td className="pull-right">5 -
                            {/* <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span> */}
                            </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Electric mirror fold</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Spare tire size</td>
                            <td className="pull-right">FULLSIZE</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Keyless Entry</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Interior Comfort</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Air conditioner</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Climate control</td>
                            <td className="pull-right">OPTION</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Electric windows</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Electric window detail</td>
                            <td className="pull-right">FRONT + REAR</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Electric window 1-touch</td>
                            <td className="pull-right">DRIVER</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Radio</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Leather</td>
                            <td className="pull-right">PARTIAL</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Remote side mirrors</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Exterior</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Metallic paint</td>
                            <td className="pull-right">
                                OPTION
                        </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Colour coded bumpers</td>
                            <td className="pull-right">
                                PAINTED
                        </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Colour coded mirrors</td>
                            <td className="pull-right">
                                BODY
                        </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Xenon headlamps</td>
                            <td className="pull-right">
                                LED
                        </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Park distance - rear</td>
                            <td className="pull-right">
                                YES
                        </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Satelite navigation</td>
                            <td className="pull-right">
                                YES
                        </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Alloy wheels</td>
                            <td className="pull-right">
                                YES
                        </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Boot capacity</td>
                            <td className="pull-right">
                                300 litres
                        </td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </section>
        <section>
            <h4 className="bg-tertiary mb1 px2 py1 i-amphtml-accordion-header" id="24_AMP_header_3" role="button"
                aria-controls="24_AMP_content_3" aria-expanded="false" tabindex="0">1.2 EXPRESSION TURBO EDC <span
                    className="pull-right">R 321 900</span></h4>
            <div className="bg-tertiary i-amphtml-accordion-content" id="24_AMP_content_3" aria-labelledby="24_AMP_header_3"
                role="region">
                <table className="table-light">
                    <tbody>
                        <tr className="bg-darken-1">
                            <td colspan="2">Service / Maintenance / Warranty</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Service interval</td>
                            <td><span className="pull-right">15 000 km</span></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td className="align-top">Service Plan</td>
                            <td><span className="pull-right">36 months</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Warranty</td>
                            <td><span className="pull-right">5 YEAR/150000KM, 6 YEAR COROSSION, 3 YEAR/45000KM SERVICE
                                PLAN</span></td>
                        </tr>
                    </tbody>
                </table>
                <table className="table-light">
                    <tbody>
                        <tr className="bg-darken-1">
                            <td colspan="2">Active and Passive Safety</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">ABS</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Electronic brakeforce distribution (EBD)</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Air bags</td>
                            <td><span className="pull-right">4</span></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td className="align-top">Automatic Lights</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Day Running Lights</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td className="align-top">Autolock</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Performance</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Engine size</td>
                            <td className="pull-right">1197cc</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>CO2</td>
                            <td className="pull-right">120g/Km</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Engine</td>
                            <td className="pull-right">TRANSVERSE INLINE 4</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Power</td>
                            <td className="pull-right">88kW@4900</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Torque</td>
                            <td className="pull-right">190Nm@2000</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Valves</td>
                            <td className="pull-right">4 VALVES PER CYLINDER, VVT</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Fuel</td>
                            <td className="pull-right">UNLEADED</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Fuel capacity</td>
                            <td className="pull-right">45 litres</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>City fuel consumption</td>
                            <td className="pull-right">6.6 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Highway fuel consumption</td>
                            <td className="pull-right">4.4 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Average fuel consumption</td>
                            <td className="pull-right">5.2 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Top speed</td>
                            <td className="pull-right">199Km/h</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>0 - 100 Km/h</td>
                            <td className="pull-right">9.4sec</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Driving and Control Environment</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Gearbox</td>
                            <td className="pull-right">AUTOMATIC</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Gears</td>
                            <td className="pull-right">6</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Drive</td>
                            <td className="pull-right">2</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Power steering</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Steering adjustment (up down)</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Steering adjustment (in out)</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Cruise control</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Computer</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Seats</td>
                            <td className="pull-right">5 -
                            {/* <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span> */}
                            </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Spare tire size</td>
                            <td className="pull-right">FULLSIZE</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Keyless Entry</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Interior Comfort</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Air conditioner</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Electric windows</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Electric window detail</td>
                            <td className="pull-right">FRONT</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Radio</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Remote side mirrors</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Exterior</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Metallic paint</td>
                            <td className="pull-right">
                                OPTION
                        </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Colour coded bumpers</td>
                            <td className="pull-right">
                                PAINTED
                        </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Colour coded mirrors</td>
                            <td className="pull-right">
                                BODY
                        </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Satelite navigation</td>
                            <td className="pull-right">
                                YES
                        </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Alloy wheels</td>
                            <td className="pull-right">
                                YES
                        </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Boot capacity</td>
                            <td className="pull-right">
                                300 litres
                        </td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </section>
    </amp-accordion>
)

export const kongooSpecs = (
    <amp-accordion className="i-amphtml-element i-amphtml-layout-container i-amphtml-built i-amphtml-layout"
        i-amphtml-layout="container">
        <section>
            <h4 className="bg-tertiary mb1 px2 py1 i-amphtml-accordion-header" id="65_AMP_header_0" role="button"
                aria-controls="65_AMP_content_0" aria-expanded="false" tabindex="0">1.6 <span className="pull-right">R 287
                900</span></h4>
            <div className="bg-tertiary i-amphtml-accordion-content" id="65_AMP_content_0" aria-labelledby="65_AMP_header_0"
                role="region">
                <table className="table-light">
                    <tbody>
                        <tr className="bg-darken-1">
                            <td colspan="2">Service / Maintenance / Warranty</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Service interval</td>
                            <td><span className="pull-right">15 000 km</span></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td className="align-top">Service Plan</td>
                            <td><span className="pull-right">36 months</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Warranty</td>
                            <td><span className="pull-right">5 YEAR/150000KM, 6 YEAR COROSSION, 5 YEAR ROADSIDE ASSISTANCE,
                                3 YEAR/60000KM SERVICE PLAN</span></td>
                        </tr>
                    </tbody>
                </table>
                <table className="table-light">
                    <tbody>
                        <tr className="bg-darken-1">
                            <td colspan="2">Active and Passive Safety</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">ABS</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Electronic brakeforce distribution (EBD)</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Air bags</td>
                            <td><span className="pull-right">1</span></td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Performance</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Engine size</td>
                            <td className="pull-right">1598cc</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>CO2</td>
                            <td className="pull-right">180g/Km</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Engine</td>
                            <td className="pull-right">TRANSVERSE INLINE 4</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Power</td>
                            <td className="pull-right">77kW@5750</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Torque</td>
                            <td className="pull-right">148Nm@3750</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Valves</td>
                            <td className="pull-right">DOHC, 4 VALVES PER CYLINDER</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Fuel</td>
                            <td className="pull-right">UNLEADED</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Fuel capacity</td>
                            <td className="pull-right">60 litres</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>City fuel consumption</td>
                            <td className="pull-right">10.9 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Highway fuel consumption</td>
                            <td className="pull-right">6.7 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Average fuel consumption</td>
                            <td className="pull-right">7.7 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Top speed</td>
                            <td className="pull-right">158Km/h</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>0 - 100 Km/h</td>
                            <td className="pull-right">15.8sec</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Driving and Control Environment</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Gearbox</td>
                            <td className="pull-right">MANUAL</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Gears</td>
                            <td className="pull-right">5</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Drive</td>
                            <td className="pull-right">2</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Power steering</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Steering adjustment (up down)</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Computer</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Seats</td>
                            <td className="pull-right">2 -
                            {/* <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span> */}
                            </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Spare tire size</td>
                            <td className="pull-right">FULLSIZE</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Keyless Entry</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Interior Comfort</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Air conditioner</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Radio</td>
                            <td className="pull-right">OPTION</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Exterior</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Colour coded bumpers</td>
                            <td className="pull-right">
                                BLACK
                        </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Colour coded mirrors</td>
                            <td className="pull-right">
                                BLACK
                        </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Boot capacity</td>
                            <td className="pull-right">
                                3000 litres
                        </td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </section>
    </amp-accordion>
)

export const dusterTechroadSpecs = (
    <amp-accordion className="i-amphtml-element i-amphtml-layout-container i-amphtml-built i-amphtml-layout"
        i-amphtml-layout="container">
        <section>
            <h4 className="bg-tertiary mb1 px2 py1 i-amphtml-accordion-header" id="81_AMP_header_0" role="button"
                aria-controls="81_AMP_content_0" aria-expanded="false" tabindex="0">1.5dCI TECHROAD 4X2 <span
                    className="pull-right">R 327 900</span></h4>
            <div className="bg-tertiary i-amphtml-accordion-content" id="81_AMP_content_0" aria-labelledby="81_AMP_header_0"
                role="region">
                <table className="table-light">
                    <tbody>
                        <tr className="bg-darken-1">
                            <td colspan="2">Service / Maintenance / Warranty</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Service interval</td>
                            <td><span className="pull-right">15 000 km</span></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td className="align-top">Service Plan</td>
                            <td><span className="pull-right">36 months</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Warranty</td>
                            <td><span className="pull-right">5 YEAR/150000KM, 6 YEAR COROSSION, 5 YEAR ROADSIDE ASSISTANCE,
                                3 YEAR/45000KM SERVICE PLAN</span></td>
                        </tr>
                    </tbody>
                </table>
                <table className="table-light">
                    <tbody>
                        <tr className="bg-darken-1">
                            <td colspan="2">Active and Passive Safety</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">ABS</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Electronic brakeforce distribution (EBD)</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Air bags</td>
                            <td><span className="pull-right">4</span></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td className="align-top">Autolock</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Performance</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Engine size</td>
                            <td className="pull-right">1461cc</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>CO2</td>
                            <td className="pull-right">135g/Km</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Engine</td>
                            <td className="pull-right">TRANSVERSE INLINE 4</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Power</td>
                            <td className="pull-right">66kW@4000</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Torque</td>
                            <td className="pull-right">210Nm@1750</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Valves</td>
                            <td className="pull-right">2 VALVES PER CYLINDER</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Fuel</td>
                            <td className="pull-right">DIESEL</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Fuel capacity</td>
                            <td className="pull-right">50 litres</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>City fuel consumption</td>
                            <td className="pull-right">5.7 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Highway fuel consumption</td>
                            <td className="pull-right">4.9 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Average fuel consumption</td>
                            <td className="pull-right">5.1 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Top speed</td>
                            <td className="pull-right">155Km/h</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>0 - 100 Km/h</td>
                            <td className="pull-right">14.8sec</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Driving and Control Environment</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Gearbox</td>
                            <td className="pull-right">MANUAL</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Gears</td>
                            <td className="pull-right">5</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Drive</td>
                            <td className="pull-right">2</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Power steering</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Steering adjustment (up down)</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Steering adjustment (in out)</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Multi function steering wheel</td>
                            <td className="pull-right">AUDIO, PHONE</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Cruise control</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Computer</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Seats</td>
                            <td className="pull-right">5 -
                            {/* <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span> */}
                            </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Spare tire size</td>
                            <td className="pull-right">FULLSIZE</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Keyless Entry</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Interior Comfort</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Air conditioner</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Electric windows</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Electric window detail</td>
                            <td className="pull-right">FRONT + REAR</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Radio</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Leather</td>
                            <td className="pull-right">OPTION</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Remote side mirrors</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Exterior</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Metallic paint</td>
                            <td className="pull-right">
                                OPTION
                        </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Colour coded bumpers</td>
                            <td className="pull-right">
                                PAINTED
                        </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Colour coded mirrors</td>
                            <td className="pull-right">
                                GLOSS BLACK
                        </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Park distance - rear</td>
                            <td className="pull-right">
                                YES
                        </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Satelite navigation</td>
                            <td className="pull-right">
                                YES
                        </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Alloy wheels</td>
                            <td className="pull-right">
                                YES
                        </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Boot capacity</td>
                            <td className="pull-right">
                                478 litres
                        </td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </section>
        <section>
            <h4 className="bg-tertiary mb1 px2 py1 i-amphtml-accordion-header" id="81_AMP_header_1" role="button"
                aria-controls="81_AMP_content_1" aria-expanded="false" tabindex="0">1.5dCI TECHROAD EDC 4X2 <span
                    className="pull-right">R 361 900</span></h4>
            <div className="bg-tertiary i-amphtml-accordion-content" id="81_AMP_content_1" aria-labelledby="81_AMP_header_1"
                role="region">
                <table className="table-light">
                    <tbody>
                        <tr className="bg-darken-1">
                            <td colspan="2">Service / Maintenance / Warranty</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Service interval</td>
                            <td><span className="pull-right">15 000 km</span></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td className="align-top">Service Plan</td>
                            <td><span className="pull-right">36 months</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Warranty</td>
                            <td><span className="pull-right">5 YEAR/150000KM, 6 YEAR COROSSION, 5 YEAR ROADSIDE ASSISTANCE,
                                3 YEAR/45000KM SERVICE PLAN</span></td>
                        </tr>
                    </tbody>
                </table>
                <table className="table-light">
                    <tbody>
                        <tr className="bg-darken-1">
                            <td colspan="2">Active and Passive Safety</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">ABS</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Electronic brakeforce distribution (EBD)</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Air bags</td>
                            <td><span className="pull-right">4</span></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td className="align-top">Autolock</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Performance</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Engine size</td>
                            <td className="pull-right">1461cc</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>CO2</td>
                            <td className="pull-right">126g/Km</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Engine</td>
                            <td className="pull-right">TRANSVERSE INLINE 4</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Power</td>
                            <td className="pull-right">80kW@4000</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Torque</td>
                            <td className="pull-right">250Nm@1750</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Valves</td>
                            <td className="pull-right">2 VALVES PER CYLINDER</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Fuel</td>
                            <td className="pull-right">DIESEL</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Fuel capacity</td>
                            <td className="pull-right">50 litres</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>City fuel consumption</td>
                            <td className="pull-right">5.2 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Highway fuel consumption</td>
                            <td className="pull-right">4.5 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Average fuel consumption</td>
                            <td className="pull-right">4.8 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Top speed</td>
                            <td className="pull-right">169Km/h</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>0 - 100 Km/h</td>
                            <td className="pull-right">11.9sec</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Driving and Control Environment</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Gearbox</td>
                            <td className="pull-right">AUTOMATIC</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Gears</td>
                            <td className="pull-right">6</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Drive</td>
                            <td className="pull-right">2</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Power steering</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Steering adjustment (up down)</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Steering adjustment (in out)</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Multi function steering wheel</td>
                            <td className="pull-right">AUDIO, PHONE</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Cruise control</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Computer</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Seats</td>
                            <td className="pull-right">5 -
                            {/* <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span> */}
                            </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Spare tire size</td>
                            <td className="pull-right">FULLSIZE</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Keyless Entry</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Interior Comfort</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Air conditioner</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Electric windows</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Electric window detail</td>
                            <td className="pull-right">FRONT + REAR</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Radio</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Leather</td>
                            <td className="pull-right">OPTION</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Remote side mirrors</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Exterior</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Metallic paint</td>
                            <td className="pull-right">
                                OPTION
                        </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Colour coded bumpers</td>
                            <td className="pull-right">
                                PAINTED
                        </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Colour coded mirrors</td>
                            <td className="pull-right">
                                GLOSS BLACK
                        </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Park distance - rear</td>
                            <td className="pull-right">
                                YES
                        </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Satelite navigation</td>
                            <td className="pull-right">
                                YES
                        </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Alloy wheels</td>
                            <td className="pull-right">
                                YES
                        </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Boot capacity</td>
                            <td className="pull-right">
                                478 litres
                        </td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </section>
    </amp-accordion>
)

export const capturSpecs = (
    <amp-accordion className="i-amphtml-element i-amphtml-layout-container i-amphtml-built i-amphtml-layout"
        i-amphtml-layout="container">
        <section>
            <h4 className="bg-tertiary mb1 px2 py1 i-amphtml-accordion-header" id="35_AMP_header_0" role="button"
                aria-controls="35_AMP_content_0" aria-expanded="false" tabindex="0">0.9 TURBO BLAZE 66kW <span
                    className="pull-right">R 284 900</span></h4>
            <div className="bg-tertiary i-amphtml-accordion-content" id="35_AMP_content_0" aria-labelledby="35_AMP_header_0"
                role="region">
                <table className="table-light">
                    <tbody>
                        <tr className="bg-darken-1">
                            <td colspan="2">Service / Maintenance / Warranty</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Service interval</td>
                            <td><span className="pull-right">15 000 km</span></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td className="align-top">Service Plan</td>
                            <td><span className="pull-right">36 months</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Warranty</td>
                            <td><span className="pull-right">5 YEAR/150000KM, 6 YEAR COROSSION, 3 YEAR/45000KM SERVICE
                                PLAN</span></td>
                        </tr>
                    </tbody>
                </table>
                <table className="table-light">
                    <tbody>
                        <tr className="bg-darken-1">
                            <td colspan="2">Active and Passive Safety</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">ABS</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Electronic brakeforce distribution (EBD)</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td className="align-top">Auto Windscreen Wipers</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Air bags</td>
                            <td><span className="pull-right">4</span></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td className="align-top">Automatic Lights</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td className="align-top">Autolock</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">ISOfix (secure child seat)</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Performance</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Engine size</td>
                            <td className="pull-right">898cc</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>CO2</td>
                            <td className="pull-right">125g/Km</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Engine</td>
                            <td className="pull-right">TRANSVERSE INLINE 3</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Power</td>
                            <td className="pull-right">66kW@5250</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Torque</td>
                            <td className="pull-right">135Nm@2500</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Valves</td>
                            <td className="pull-right">SOHC, 4 VALVES PER CYLINDER</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Fuel</td>
                            <td className="pull-right">UNLEADED</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Fuel capacity</td>
                            <td className="pull-right">45 litres</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>City fuel consumption</td>
                            <td className="pull-right">6.6 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Highway fuel consumption</td>
                            <td className="pull-right">4.7 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Average fuel consumption</td>
                            <td className="pull-right">5.4 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Top speed</td>
                            <td className="pull-right">171Km/h</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>0 - 100 Km/h</td>
                            <td className="pull-right">12.9sec</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Driving and Control Environment</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Gearbox</td>
                            <td className="pull-right">MANUAL</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Gears</td>
                            <td className="pull-right">5</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Drive</td>
                            <td className="pull-right">2</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Power steering</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Steering adjustment (up down)</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Steering adjustment (in out)</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Multi function steering wheel</td>
                            <td className="pull-right">AUDIO</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Cruise control</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Computer</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Seats</td>
                            <td className="pull-right">5 -
                            {/* <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span> */}
                            </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Spare tire size</td>
                            <td className="pull-right">SPACESAVER</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Keyless Entry</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Interior Comfort</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Air conditioner</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Electric windows</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Electric window detail</td>
                            <td className="pull-right">FRONT + REAR</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Electric window 1-touch</td>
                            <td className="pull-right">DRIVER</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Radio</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Remote side mirrors</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Exterior</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Metallic paint</td>
                            <td className="pull-right">
                                OPTION
                        </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Colour coded bumpers</td>
                            <td className="pull-right">
                                PAINTED
                        </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Colour coded mirrors</td>
                            <td className="pull-right">
                                BODY
                        </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Alloy wheels</td>
                            <td className="pull-right">
                                YES
                        </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Boot capacity</td>
                            <td className="pull-right">
                                377 litres
                        </td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </section>
        <section>
            <h4 className="bg-tertiary mb1 px2 py1 i-amphtml-accordion-header" id="35_AMP_header_1" role="button"
                aria-controls="35_AMP_content_1" aria-expanded="false" tabindex="0">0.9 TURBO DYNAMIQUE <span
                    className="pull-right">R 331 900</span></h4>
            <div className="bg-tertiary i-amphtml-accordion-content" id="35_AMP_content_1" aria-labelledby="35_AMP_header_1"
                role="region">
                <table className="table-light">
                    <tbody>
                        <tr className="bg-darken-1">
                            <td colspan="2">Service / Maintenance / Warranty</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Service interval</td>
                            <td><span className="pull-right">15 000 km</span></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td className="align-top">Service Plan</td>
                            <td><span className="pull-right">36 months</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Warranty</td>
                            <td><span className="pull-right">5 YEAR/150000KM, 6 YEAR COROSSION, 3 YEAR/45000KM SERVICE
                                PLAN</span></td>
                        </tr>
                    </tbody>
                </table>
                <table className="table-light">
                    <tbody>
                        <tr className="bg-darken-1">
                            <td colspan="2">Active and Passive Safety</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">ABS</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Electronic brakeforce distribution (EBD)</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td className="align-top">Auto Windscreen Wipers</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Air bags</td>
                            <td><span className="pull-right">4</span></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td className="align-top">Automatic Lights</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Day Running Lights</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td className="align-top">Autolock</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Performance</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Engine size</td>
                            <td className="pull-right">898cc</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>CO2</td>
                            <td className="pull-right">115g/Km</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Engine</td>
                            <td className="pull-right">TRANSVERSE INLINE 3</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Power</td>
                            <td className="pull-right">66kW@5250</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Torque</td>
                            <td className="pull-right">135Nm@2500</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Valves</td>
                            <td className="pull-right">SOHC, 4 VALVES PER CYLINDER</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Fuel</td>
                            <td className="pull-right">UNLEADED</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Fuel capacity</td>
                            <td className="pull-right">45 litres</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>City fuel consumption</td>
                            <td className="pull-right">6 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Highway fuel consumption</td>
                            <td className="pull-right">4.3 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Average fuel consumption</td>
                            <td className="pull-right">4.9 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Top speed</td>
                            <td className="pull-right">171Km/h</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>0 - 100 Km/h</td>
                            <td className="pull-right">12.9sec</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Driving and Control Environment</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Gearbox</td>
                            <td className="pull-right">MANUAL</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Gears</td>
                            <td className="pull-right">5</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Drive</td>
                            <td className="pull-right">2</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Power steering</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Steering adjustment (up down)</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Steering adjustment (in out)</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Multi function steering wheel</td>
                            <td className="pull-right">AUDIO, PHONE, CRUISE</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Cruise control</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Computer</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Seats</td>
                            <td className="pull-right">5 -
                            {/* <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span> */}
                            </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Spare tire size</td>
                            <td className="pull-right">SPACESAVER</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Keyless Entry</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Interior Comfort</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Air conditioner</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Climate control</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Electric windows</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Electric window detail</td>
                            <td className="pull-right">FRONT + REAR</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Electric window 1-touch</td>
                            <td className="pull-right">DRIVER</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Radio</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Remote side mirrors</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Exterior</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Metallic paint</td>
                            <td className="pull-right">
                                OPTION
                        </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Colour coded bumpers</td>
                            <td className="pull-right">
                                PAINTED
                        </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Colour coded mirrors</td>
                            <td className="pull-right">
                                TWO-TONE BODY
                        </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Fog lamps - front</td>
                            <td className="pull-right">
                                YES
                        </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Park distance - rear</td>
                            <td className="pull-right">
                                YES
                        </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Satelite navigation</td>
                            <td className="pull-right">
                                YES
                        </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Alloy wheels</td>
                            <td className="pull-right">
                                YES
                        </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Boot capacity</td>
                            <td className="pull-right">
                                377 litres
                        </td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </section>
        <section>
            <h4 className="bg-tertiary mb1 px2 py1 i-amphtml-accordion-header" id="35_AMP_header_2" role="button"
                aria-controls="35_AMP_content_2" aria-expanded="false" tabindex="0">1.5 dCi DYNAMIQUE <span
                    className="pull-right">R 358 900</span></h4>
            <div className="bg-tertiary i-amphtml-accordion-content" id="35_AMP_content_2" aria-labelledby="35_AMP_header_2"
                role="region">
                <table className="table-light">
                    <tbody>
                        <tr className="bg-darken-1">
                            <td colspan="2">Service / Maintenance / Warranty</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Service interval</td>
                            <td><span className="pull-right">15 000 km</span></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td className="align-top">Service Plan</td>
                            <td><span className="pull-right">36 months</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Warranty</td>
                            <td><span className="pull-right">5 YEAR/150000KM, 6 YEAR COROSSION, 3 YEAR/45000KM SERVICE
                                PLAN</span></td>
                        </tr>
                    </tbody>
                </table>
                <table className="table-light">
                    <tbody>
                        <tr className="bg-darken-1">
                            <td colspan="2">Active and Passive Safety</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">ABS</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Electronic brakeforce distribution (EBD)</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td className="align-top">Auto Windscreen Wipers</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Air bags</td>
                            <td><span className="pull-right">4</span></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td className="align-top">Automatic Lights</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td className="align-top">Autolock</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">ISOfix (secure child seat)</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Performance</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Engine size</td>
                            <td className="pull-right">1461cc</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>CO2</td>
                            <td className="pull-right">95g/Km</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Engine</td>
                            <td className="pull-right">TRANSVERSE INLINE 4</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Power</td>
                            <td className="pull-right">66kW@4000</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Torque</td>
                            <td className="pull-right">220Nm@1750</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Valves</td>
                            <td className="pull-right">SOHC 2 VALVES PER CYLINDER</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Fuel</td>
                            <td className="pull-right">DIESEL</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Fuel capacity</td>
                            <td className="pull-right">45 litres</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>City fuel consumption</td>
                            <td className="pull-right">4 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Highway fuel consumption</td>
                            <td className="pull-right">3.4 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Average fuel consumption</td>
                            <td className="pull-right">3.6 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Top speed</td>
                            <td className="pull-right">171Km/h</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>0 - 100 Km/h</td>
                            <td className="pull-right">13.1sec</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Driving and Control Environment</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Gearbox</td>
                            <td className="pull-right">MANUAL</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Gears</td>
                            <td className="pull-right">5</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Drive</td>
                            <td className="pull-right">2</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Power steering</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Steering adjustment (up down)</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Steering adjustment (in out)</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Multi function steering wheel</td>
                            <td className="pull-right">AUDIO, PHONE, CRUISE</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Cruise control</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Computer</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Seats</td>
                            <td className="pull-right">5 -
                            {/* <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span> */}
                            </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Electric mirror fold</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Spare tire size</td>
                            <td className="pull-right">SPACESAVER</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Keyless Entry</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Interior Comfort</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Air conditioner</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Climate control</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Electric windows</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Electric window detail</td>
                            <td className="pull-right">FRONT + REAR</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Electric window 1-touch</td>
                            <td className="pull-right">DRIVER</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Radio</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Remote side mirrors</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Exterior</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Metallic paint</td>
                            <td className="pull-right">
                                OPTION
                        </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Colour coded bumpers</td>
                            <td className="pull-right">
                                PAINTED
                        </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Colour coded mirrors</td>
                            <td className="pull-right">
                                TWO-TONE BODY
                        </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Xenon headlamps</td>
                            <td className="pull-right">
                                LED
                        </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Park distance - rear</td>
                            <td className="pull-right">
                                YES
                        </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Satelite navigation</td>
                            <td className="pull-right">
                                YES
                        </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Alloy wheels</td>
                            <td className="pull-right">
                                YES
                        </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Boot capacity</td>
                            <td className="pull-right">
                                377 litres
                        </td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </section>
        <section>
            <h4 className="bg-tertiary mb1 px2 py1 i-amphtml-accordion-header" id="35_AMP_header_3" role="button"
                aria-controls="35_AMP_content_3" aria-expanded="false" tabindex="0">1.2 TURBO DYNAMIQUE 88kW <span
                    className="pull-right">R 358 900</span></h4>
            <div className="bg-tertiary i-amphtml-accordion-content" id="35_AMP_content_3" aria-labelledby="35_AMP_header_3"
                role="region">
                <table className="table-light">
                    <tbody>
                        <tr className="bg-darken-1">
                            <td colspan="2">Service / Maintenance / Warranty</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Service interval</td>
                            <td><span className="pull-right">15 000 km</span></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td className="align-top">Service Plan</td>
                            <td><span className="pull-right">36 months</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Warranty</td>
                            <td><span className="pull-right">5 YEAR/150000KM, 6 YEAR COROSSION, 3 YEAR/45000KM SERVICE
                                PLAN</span></td>
                        </tr>
                    </tbody>
                </table>
                <table className="table-light">
                    <tbody>
                        <tr className="bg-darken-1">
                            <td colspan="2">Active and Passive Safety</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">ABS</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Electronic brakeforce distribution (EBD)</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td className="align-top">Auto Windscreen Wipers</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Air bags</td>
                            <td><span className="pull-right">4</span></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td className="align-top">Automatic Lights</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td className="align-top">Autolock</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">ISOfix (secure child seat)</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Performance</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Engine size</td>
                            <td className="pull-right">1197cc</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>CO2</td>
                            <td className="pull-right">125g/Km</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Engine</td>
                            <td className="pull-right">TRANSVERSE INLINE 4</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Power</td>
                            <td className="pull-right">88kW@5000</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Torque</td>
                            <td className="pull-right">190Nm@2000</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Valves</td>
                            <td className="pull-right">DOHC 4 VALVES PER CYLINDER</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Fuel</td>
                            <td className="pull-right">UNLEADED</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Fuel capacity</td>
                            <td className="pull-right">45 litres</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>City fuel consumption</td>
                            <td className="pull-right">7 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Highway fuel consumption</td>
                            <td className="pull-right">4.7 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Average fuel consumption</td>
                            <td className="pull-right">5.5 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Top speed</td>
                            <td className="pull-right">182Km/h</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>0 - 100 Km/h</td>
                            <td className="pull-right">9.9sec</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Driving and Control Environment</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Gearbox</td>
                            <td className="pull-right">MANUAL</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Gears</td>
                            <td className="pull-right">5</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Drive</td>
                            <td className="pull-right">2</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Power steering</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Steering adjustment (up down)</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Steering adjustment (in out)</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Multi function steering wheel</td>
                            <td className="pull-right">AUDIO, PHONE, CRUISE</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Cruise control</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Computer</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Seats</td>
                            <td className="pull-right">5 -
                            {/* <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span> */}
                            </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Electric mirror fold</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Spare tire size</td>
                            <td className="pull-right">SPACESAVER</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Keyless Entry</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Interior Comfort</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Air conditioner</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Climate control</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Electric windows</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Electric window detail</td>
                            <td className="pull-right">FRONT + REAR</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Electric window 1-touch</td>
                            <td className="pull-right">DRIVER</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Radio</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Remote side mirrors</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Exterior</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Metallic paint</td>
                            <td className="pull-right">
                                OPTION
                        </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Colour coded bumpers</td>
                            <td className="pull-right">
                                PAINTED
                        </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Colour coded mirrors</td>
                            <td className="pull-right">
                                TWO-TONE BODY
                        </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Xenon headlamps</td>
                            <td className="pull-right">
                                LED
                        </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Park distance - rear</td>
                            <td className="pull-right">
                                YES
                        </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Satelite navigation</td>
                            <td className="pull-right">
                                YES
                        </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Alloy wheels</td>
                            <td className="pull-right">
                                YES
                        </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Boot capacity</td>
                            <td className="pull-right">
                                377 litres
                        </td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </section>
        <section>
            <h4 className="bg-tertiary mb1 px2 py1 i-amphtml-accordion-header" id="35_AMP_header_4" role="button"
                aria-controls="35_AMP_content_4" aria-expanded="false" tabindex="0">1.2 TURBO EDC DYNAMIQUE 88kW <span
                    className="pull-right">R 374 900</span></h4>
            <div className="bg-tertiary i-amphtml-accordion-content" id="35_AMP_content_4" aria-labelledby="35_AMP_header_4"
                role="region">
                <table className="table-light">
                    <tbody>
                        <tr className="bg-darken-1">
                            <td colspan="2">Service / Maintenance / Warranty</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Service interval</td>
                            <td><span className="pull-right">15 000 km</span></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td className="align-top">Service Plan</td>
                            <td><span className="pull-right">36 months</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Warranty</td>
                            <td><span className="pull-right">5 YEAR/150000KM, 6 YEAR COROSSION, 3 YEAR/45000KM SERVICE
                                PLAN</span></td>
                        </tr>
                    </tbody>
                </table>
                <table className="table-light">
                    <tbody>
                        <tr className="bg-darken-1">
                            <td colspan="2">Active and Passive Safety</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">ABS</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Electronic brakeforce distribution (EBD)</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td className="align-top">Auto Windscreen Wipers</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Air bags</td>
                            <td><span className="pull-right">4</span></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td className="align-top">Automatic Lights</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td className="align-top">Autolock</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">ISOfix (secure child seat)</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Performance</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Engine size</td>
                            <td className="pull-right">1197cc</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>CO2</td>
                            <td className="pull-right">125g/Km</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Engine</td>
                            <td className="pull-right">TRANSVERSE INLINE 4</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Power</td>
                            <td className="pull-right">88kW@4900</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Torque</td>
                            <td className="pull-right">190Nm@2000</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Valves</td>
                            <td className="pull-right">DOHC 4 VALVES PER CYLINDER</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Fuel</td>
                            <td className="pull-right">UNLEADED</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Fuel capacity</td>
                            <td className="pull-right">45 litres</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>City fuel consumption</td>
                            <td className="pull-right">6.6 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Highway fuel consumption</td>
                            <td className="pull-right">4.7 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Average fuel consumption</td>
                            <td className="pull-right">5.4 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Top speed</td>
                            <td className="pull-right">192Km/h</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>0 - 100 Km/h</td>
                            <td className="pull-right">10.9sec</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Driving and Control Environment</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Gearbox</td>
                            <td className="pull-right">AUTOMATIC</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Gears</td>
                            <td className="pull-right">6</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Drive</td>
                            <td className="pull-right">2</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Power steering</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Steering adjustment (up down)</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Steering adjustment (in out)</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Multi function steering wheel</td>
                            <td className="pull-right">AUDIO, PHONE, CRUISE</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Cruise control</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Computer</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Seats</td>
                            <td className="pull-right">5 -
                            {/* <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span> */}
                            </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Electric mirror fold</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Spare tire size</td>
                            <td className="pull-right">SPACESAVER</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Keyless Entry</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Interior Comfort</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Air conditioner</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Climate control</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Electric windows</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Electric window detail</td>
                            <td className="pull-right">FRONT + REAR</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Electric window 1-touch</td>
                            <td className="pull-right">DRIVER</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Radio</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Remote side mirrors</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Exterior</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Metallic paint</td>
                            <td className="pull-right">
                                OPTION
                        </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Colour coded bumpers</td>
                            <td className="pull-right">
                                PAINTED
                        </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Colour coded mirrors</td>
                            <td className="pull-right">
                                TWO-TONE BODY
                        </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Fog lamps - front</td>
                            <td className="pull-right">
                                YES
                        </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Xenon headlamps</td>
                            <td className="pull-right">
                                LED
                        </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Park distance - rear</td>
                            <td className="pull-right">
                                YES
                        </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Satelite navigation</td>
                            <td className="pull-right">
                                YES
                        </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Alloy wheels</td>
                            <td className="pull-right">
                                YES
                        </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Boot capacity</td>
                            <td className="pull-right">
                                377 litres
                        </td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </section>
    </amp-accordion>
)

export const dusterSpecs = (
    <amp-accordion className="i-amphtml-element i-amphtml-layout-container i-amphtml-built i-amphtml-layout"
        i-amphtml-layout="container">
        <section>
            <h4 className="bg-tertiary mb1 px2 py1 i-amphtml-accordion-header" id="0_AMP_header_0" role="button"
                aria-controls="0_AMP_content_0" aria-expanded="false" tabindex="0">1.6 EXPRESSION 4X2 <span
                    className="pull-right">R 289 900</span></h4>
            <div className="bg-tertiary i-amphtml-accordion-content" id="0_AMP_content_0" aria-labelledby="0_AMP_header_0"
                role="region">
                <table className="table-light">
                    <tbody>
                        <tr className="bg-darken-1">
                            <td colspan="2">Service / Maintenance / Warranty</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Service interval</td>
                            <td><span className="pull-right">15 000 km</span></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td className="align-top">Service Plan</td>
                            <td><span className="pull-right">36 months</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Warranty</td>
                            <td><span className="pull-right">5 YEAR/150000KM, 6 YEAR COROSSION, 5 YEAR ROADSIDE ASSISTANCE,
                                3 YEAR/45000KM SERVICE PLAN</span></td>
                        </tr>
                    </tbody>
                </table>
                <table className="table-light">
                    <tbody>
                        <tr className="bg-darken-1">
                            <td colspan="2">Active and Passive Safety</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">ABS</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Electronic brakeforce distribution (EBD)</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Air bags</td>
                            <td><span className="pull-right">2</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Day Running Lights</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td className="align-top">Autolock</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Performance</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Engine size</td>
                            <td className="pull-right">1598cc</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>CO2</td>
                            <td className="pull-right">162g/Km</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Engine</td>
                            <td className="pull-right">TRANSVERSE INLINE 4</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Power</td>
                            <td className="pull-right">84kW@5500</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Torque</td>
                            <td className="pull-right">156Nm@4000</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Valves</td>
                            <td className="pull-right">DOHC, 4 VALVES PER CYLINDER</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Fuel</td>
                            <td className="pull-right">UNLEADED</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Fuel capacity</td>
                            <td className="pull-right">50 litres</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>City fuel consumption</td>
                            <td className="pull-right">8.8 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Highway fuel consumption</td>
                            <td className="pull-right">5.9 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Average fuel consumption</td>
                            <td className="pull-right">7 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Top speed</td>
                            <td className="pull-right">167Km/h</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>0 - 100 Km/h</td>
                            <td className="pull-right">11sec</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Driving and Control Environment</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Gearbox</td>
                            <td className="pull-right">MANUAL</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Gears</td>
                            <td className="pull-right">5</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Drive</td>
                            <td className="pull-right">2</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Power steering</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Steering adjustment (up down)</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Steering adjustment (in out)</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Multi function steering wheel</td>
                            <td className="pull-right">AUDIO, PHONE</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Cruise control</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Computer</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Seats</td>
                            <td className="pull-right">5 -
                            {/* <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span> */}
                            </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Spare tire size</td>
                            <td className="pull-right">SPACESAVER</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Keyless Entry</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Interior Comfort</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Air conditioner</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Electric windows</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Electric window detail</td>
                            <td className="pull-right">FRONT</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Radio</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Remote side mirrors</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Exterior</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Metallic paint</td>
                            <td className="pull-right">
                                OPTION
                        </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Colour coded bumpers</td>
                            <td className="pull-right">
                                PAINTED
                        </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Colour coded mirrors</td>
                            <td className="pull-right">
                                BLACK
                        </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Boot capacity</td>
                            <td className="pull-right">
                                478 litres
                        </td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </section>
        <section>
            <h4 className="bg-tertiary mb1 px2 py1 i-amphtml-accordion-header" id="0_AMP_header_1" role="button"
                aria-controls="0_AMP_content_1" aria-expanded="false" tabindex="0">1.5 dCI DYNAMIQUE 4x4 <span
                    className="pull-right">R 361 900</span></h4>
            <div className="bg-tertiary i-amphtml-accordion-content" id="0_AMP_content_1" aria-labelledby="0_AMP_header_1"
                role="region">
                <table className="table-light">
                    <tbody>
                        <tr className="bg-darken-1">
                            <td colspan="2">Service / Maintenance / Warranty</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Service interval</td>
                            <td><span className="pull-right">15 000 km</span></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td className="align-top">Service Plan</td>
                            <td><span className="pull-right">36 months</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Warranty</td>
                            <td><span className="pull-right">5 YEAR/150000KM, 6 YEAR COROSSION, 5 YEAR ROADSIDE ASSISTANCE,
                                3 YEAR/45000KM SERVICE PLAN</span></td>
                        </tr>
                    </tbody>
                </table>
                <table className="table-light">
                    <tbody>
                        <tr className="bg-darken-1">
                            <td colspan="2">Active and Passive Safety</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">ABS</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Electronic brakeforce distribution (EBD)</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Air bags</td>
                            <td><span className="pull-right">4</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Day Running Lights</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td className="align-top">Autolock</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Performance</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Engine size</td>
                            <td className="pull-right">1461cc</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>CO2</td>
                            <td className="pull-right">138g/Km</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Engine</td>
                            <td className="pull-right">TRANSVERSE INLINE 4</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Power</td>
                            <td className="pull-right">80kW@4000</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Torque</td>
                            <td className="pull-right">260Nm@1750</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Valves</td>
                            <td className="pull-right">2 VALVES PER CYLINDER</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Fuel</td>
                            <td className="pull-right">DIESEL</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Fuel capacity</td>
                            <td className="pull-right">50 litres</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>City fuel consumption</td>
                            <td className="pull-right">5.6 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Highway fuel consumption</td>
                            <td className="pull-right">5 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Average fuel consumption</td>
                            <td className="pull-right">5.2 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Top speed</td>
                            <td className="pull-right">168Km/h</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>0 - 100 Km/h</td>
                            <td className="pull-right">12.5sec</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Driving and Control Environment</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Gearbox</td>
                            <td className="pull-right">MANUAL</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Gears</td>
                            <td className="pull-right">6</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Drive</td>
                            <td className="pull-right">4</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Power steering</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Steering adjustment (up down)</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Steering adjustment (in out)</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Multi function steering wheel</td>
                            <td className="pull-right">AUDIO, PHONE</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Cruise control</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Computer</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Seats</td>
                            <td className="pull-right">5 -
                            {/* <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span> */}
                            </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Spare tire size</td>
                            <td className="pull-right">FULLSIZE</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Keyless Entry</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Interior Comfort</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Air conditioner</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Electric windows</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Electric window detail</td>
                            <td className="pull-right">FRONT + REAR</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Radio</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Leather</td>
                            <td className="pull-right">OPTION</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Remote side mirrors</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Exterior</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Metallic paint</td>
                            <td className="pull-right">
                                OPTION
                        </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Colour coded bumpers</td>
                            <td className="pull-right">
                                PAINTED
                        </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Colour coded mirrors</td>
                            <td className="pull-right">
                                BLACK
                        </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Park distance - rear</td>
                            <td className="pull-right">
                                YES
                        </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Satelite navigation</td>
                            <td className="pull-right">
                                YES
                        </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Alloy wheels</td>
                            <td className="pull-right">
                                YES
                        </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Boot capacity</td>
                            <td className="pull-right">
                                414 litres
                        </td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </section>
        <section>
            <h4 className="bg-tertiary mb1 px2 py1 i-amphtml-accordion-header" id="0_AMP_header_2" role="button"
                aria-controls="0_AMP_content_2" aria-expanded="false" tabindex="0">1.5 dCI PRESTIGE EDC 4X2 <span
                    className="pull-right">R 374 900</span></h4>
            <div className="bg-tertiary i-amphtml-accordion-content" id="0_AMP_content_2" aria-labelledby="0_AMP_header_2"
                role="region">
                <table className="table-light">
                    <tbody>
                        <tr className="bg-darken-1">
                            <td colspan="2">Service / Maintenance / Warranty</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Service interval</td>
                            <td><span className="pull-right">15 000 km</span></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td className="align-top">Service Plan</td>
                            <td><span className="pull-right">36 months</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Warranty</td>
                            <td><span className="pull-right">5 YEAR/150000KM, 6 YEAR COROSSION, 5 YEAR ROADSIDE ASSISTANCE,
                                3 YEAR/45000KM SERVICE PLAN</span></td>
                        </tr>
                    </tbody>
                </table>
                <table className="table-light">
                    <tbody>
                        <tr className="bg-darken-1">
                            <td colspan="2">Active and Passive Safety</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">ABS</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Electronic brakeforce distribution (EBD)</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Air bags</td>
                            <td><span className="pull-right">4</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Day Running Lights</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td className="align-top">Autolock</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Performance</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Engine size</td>
                            <td className="pull-right">1461cc</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>CO2</td>
                            <td className="pull-right">126g/Km</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Engine</td>
                            <td className="pull-right">TRANSVERSE INLINE 4</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Power</td>
                            <td className="pull-right">80kW@4000</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Torque</td>
                            <td className="pull-right">250Nm@1750</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Valves</td>
                            <td className="pull-right">2 VALVES PER CYLINDER</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Fuel</td>
                            <td className="pull-right">DIESEL</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Fuel capacity</td>
                            <td className="pull-right">50 litres</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>City fuel consumption</td>
                            <td className="pull-right">5.2 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Highway fuel consumption</td>
                            <td className="pull-right">4.5 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Average fuel consumption</td>
                            <td className="pull-right">4.8 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Top speed</td>
                            <td className="pull-right">169Km/h</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>0 - 100 Km/h</td>
                            <td className="pull-right">11.9sec</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Driving and Control Environment</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Gearbox</td>
                            <td className="pull-right">AUTOMATIC</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Gears</td>
                            <td className="pull-right">6</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Drive</td>
                            <td className="pull-right">2</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Power steering</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Steering adjustment (up down)</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Steering adjustment (in out)</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Multi function steering wheel</td>
                            <td className="pull-right">AUDIO, PHONE</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Cruise control</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Computer</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Seats</td>
                            <td className="pull-right">5 -
                            {/* <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span> */}
                            </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Spare tire size</td>
                            <td className="pull-right">FULLSIZE</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Keyless Entry</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Interior Comfort</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Air conditioner</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Electric windows</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Electric window detail</td>
                            <td className="pull-right">FRONT + REAR</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Radio</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Leather</td>
                            <td className="pull-right">OPTION</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Remote side mirrors</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Exterior</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Metallic paint</td>
                            <td className="pull-right">
                                OPTION
                        </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Colour coded bumpers</td>
                            <td className="pull-right">
                                PAINTED
                        </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Colour coded mirrors</td>
                            <td className="pull-right">
                                CHROME
                        </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Park distance - rear</td>
                            <td className="pull-right">
                                YES
                        </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Satelite navigation</td>
                            <td className="pull-right">
                                YES
                        </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Alloy wheels</td>
                            <td className="pull-right">
                                YES
                        </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Boot capacity</td>
                            <td className="pull-right">
                                478 litres
                        </td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </section>
    </amp-accordion>
)

export const meganeSpecs = (
    <amp-accordion className="i-amphtml-element i-amphtml-layout-container i-amphtml-built i-amphtml-layout"
        i-amphtml-layout="container">
        <section>
            <h4 className="bg-tertiary mb1 px2 py1 i-amphtml-accordion-header" id="13_AMP_header_0" role="button"
                aria-controls="13_AMP_content_0" aria-expanded="false" tabindex="0">1.6 EXPRESSION <span
                    className="pull-right">R 326 900</span></h4>
            <div className="bg-tertiary i-amphtml-accordion-content" id="13_AMP_content_0" aria-labelledby="13_AMP_header_0"
                role="region">
                <table className="table-light">
                    <tbody>
                        <tr className="bg-darken-1">
                            <td colspan="2">Service / Maintenance / Warranty</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Service interval</td>
                            <td><span className="pull-right">15 000 km</span></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td className="align-top">Service Plan</td>
                            <td><span className="pull-right">60 months</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Warranty</td>
                            <td><span className="pull-right">5 YEAR/150000KM, 6 YEAR COROSSION, 5 YEAR ROADSIDE ASSISTANCE,
                                5 YEAR/90000KM SERVICE PLAN</span></td>
                        </tr>
                    </tbody>
                </table>
                <table className="table-light">
                    <tbody>
                        <tr className="bg-darken-1">
                            <td colspan="2">Active and Passive Safety</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">ABS</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Electronic brakeforce distribution (EBD)</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Air bags</td>
                            <td><span className="pull-right">4</span></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td className="align-top">Autolock</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Performance</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Engine size</td>
                            <td className="pull-right">1595cc</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>CO2</td>
                            <td className="pull-right">144g/Km</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Engine</td>
                            <td className="pull-right">TRANSVERSE INLINE 4</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Power</td>
                            <td className="pull-right">84kW@5500</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Torque</td>
                            <td className="pull-right">156Nm@4000</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Valves</td>
                            <td className="pull-right">DOHC 4 VALVES PER CYLINDER</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Fuel</td>
                            <td className="pull-right">UNLEADED</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Fuel capacity</td>
                            <td className="pull-right">47 litres</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>City fuel consumption</td>
                            <td className="pull-right">8.7 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Highway fuel consumption</td>
                            <td className="pull-right">5.1 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Average fuel consumption</td>
                            <td className="pull-right">6.4 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Top speed</td>
                            <td className="pull-right">191Km/h</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>0 - 100 Km/h</td>
                            <td className="pull-right">12.2sec</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Driving and Control Environment</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Gearbox</td>
                            <td className="pull-right">MANUAL</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Gears</td>
                            <td className="pull-right">5</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Drive</td>
                            <td className="pull-right">2</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Power steering</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Steering adjustment (up down)</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Steering adjustment (in out)</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Multi function steering wheel</td>
                            <td className="pull-right">AUDIO, PHONE</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Cruise control</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Computer</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Seats</td>
                            <td className="pull-right">5 -
                            {/* <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span> */}
                            </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Electric mirror fold</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Spare tire size</td>
                            <td className="pull-right">FULLSIZE</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Interior Comfort</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Air conditioner</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Electric windows</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Electric window detail</td>
                            <td className="pull-right">FRONT + REAR</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Electric window 1-touch</td>
                            <td className="pull-right">DRIVER</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Radio</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Remote side mirrors</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Hands-free phone</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Exterior</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Metallic paint</td>
                            <td className="pull-right">
                                OPTION
                        </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Colour coded bumpers</td>
                            <td className="pull-right">
                                PAINTED
                        </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Colour coded mirrors</td>
                            <td className="pull-right">
                                BODY
                        </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Satelite navigation</td>
                            <td className="pull-right">
                                YES
                        </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Alloy wheels</td>
                            <td className="pull-right">
                                YES
                        </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Boot capacity</td>
                            <td className="pull-right">
                                372 litres
                        </td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </section>
        <section>
            <h4 className="bg-tertiary mb1 px2 py1 i-amphtml-accordion-header" id="13_AMP_header_1" role="button"
                aria-controls="13_AMP_content_1" aria-expanded="false" tabindex="0">1.2 DYNAMIQUE <span
                    className="pull-right">R 370 900</span></h4>
            <div className="bg-tertiary i-amphtml-accordion-content" id="13_AMP_content_1" aria-labelledby="13_AMP_header_1"
                role="region">
                <table className="table-light">
                    <tbody>
                        <tr className="bg-darken-1">
                            <td colspan="2">Service / Maintenance / Warranty</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Service interval</td>
                            <td><span className="pull-right">15 000 km</span></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td className="align-top">Service Plan</td>
                            <td><span className="pull-right">60 months</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Warranty</td>
                            <td><span className="pull-right">5 YEAR/150000KM, 6 YEAR COROSSION, 5 YEAR ROADSIDE ASSISTANCE,
                                5 YEAR/90000KM SERVICE PLAN</span></td>
                        </tr>
                    </tbody>
                </table>
                <table className="table-light">
                    <tbody>
                        <tr className="bg-darken-1">
                            <td colspan="2">Active and Passive Safety</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">ABS</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Electronic brakeforce distribution (EBD)</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td className="align-top">Auto Windscreen Wipers</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Air bags</td>
                            <td><span className="pull-right">6</span></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td className="align-top">Automatic Lights</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td className="align-top">Autolock</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Performance</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Engine size</td>
                            <td className="pull-right">1197cc</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>CO2</td>
                            <td className="pull-right">119g/Km</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Engine</td>
                            <td className="pull-right">TRANSVERSE INLINE 4</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Power</td>
                            <td className="pull-right">97kW@5500</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Torque</td>
                            <td className="pull-right">205Nm@2000</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Valves</td>
                            <td className="pull-right">DOHC 4 VALVES PER CYLINDER, TURBO</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Fuel</td>
                            <td className="pull-right">UNLEADED</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Fuel capacity</td>
                            <td className="pull-right">47 litres</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>City fuel consumption</td>
                            <td className="pull-right">6.8 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Highway fuel consumption</td>
                            <td className="pull-right">4.5 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Average fuel consumption</td>
                            <td className="pull-right">5.3 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Top speed</td>
                            <td className="pull-right">197Km/h</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>0 - 100 Km/h</td>
                            <td className="pull-right">10.6sec</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Driving and Control Environment</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Gearbox</td>
                            <td className="pull-right">MANUAL</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Gears</td>
                            <td className="pull-right">6</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Drive</td>
                            <td className="pull-right">2</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Power steering</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Steering adjustment (up down)</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Steering adjustment (in out)</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Multi function steering wheel</td>
                            <td className="pull-right">AUDIO, PHONE</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Cruise control</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Computer</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Seats</td>
                            <td className="pull-right">5 -
                            {/* <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span> */}
                            </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Electric mirror fold</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Spare tire size</td>
                            <td className="pull-right">FULLSIZE</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Keyless Entry</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Interior Comfort</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Air conditioner</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Climate control</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Electric windows</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Electric window detail</td>
                            <td className="pull-right">FRONT + REAR</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Electric window 1-touch</td>
                            <td className="pull-right">DRIVER</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Radio</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Remote side mirrors</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Hands-free phone</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Exterior</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Metallic paint</td>
                            <td className="pull-right">
                                OPTION
                        </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Colour coded bumpers</td>
                            <td className="pull-right">
                                PAINTED
                        </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Colour coded mirrors</td>
                            <td className="pull-right">
                                BODY
                        </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Park distance - rear</td>
                            <td className="pull-right">
                                YES
                        </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Satelite navigation</td>
                            <td className="pull-right">
                                YES
                        </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Alloy wheels</td>
                            <td className="pull-right">
                                YES
                        </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Boot capacity</td>
                            <td className="pull-right">
                                372 litres
                        </td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </section>
        <section>
            <h4 className="bg-tertiary mb1 px2 py1 i-amphtml-accordion-header" id="13_AMP_header_2" role="button"
                aria-controls="13_AMP_content_2" aria-expanded="false" tabindex="0">1.2 DYNAMIQUE EDC <span
                    className="pull-right">R 401 900</span></h4>
            <div className="bg-tertiary i-amphtml-accordion-content" id="13_AMP_content_2" aria-labelledby="13_AMP_header_2"
                role="region">
                <table className="table-light">
                    <tbody>
                        <tr className="bg-darken-1">
                            <td colspan="2">Service / Maintenance / Warranty</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Service interval</td>
                            <td><span className="pull-right">15 000 km</span></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td className="align-top">Service Plan</td>
                            <td><span className="pull-right">60 months</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Warranty</td>
                            <td><span className="pull-right">5 YEAR/150000KM, 6 YEAR COROSSION, 5 YEAR ROADSIDE ASSISTANCE,
                                5 YEAR/90000KM SERVICE PLAN</span></td>
                        </tr>
                    </tbody>
                </table>
                <table className="table-light">
                    <tbody>
                        <tr className="bg-darken-1">
                            <td colspan="2">Active and Passive Safety</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">ABS</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Electronic brakeforce distribution (EBD)</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td className="align-top">Auto Windscreen Wipers</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Air bags</td>
                            <td><span className="pull-right">6</span></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td className="align-top">Automatic Lights</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td className="align-top">Autolock</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Performance</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Engine size</td>
                            <td className="pull-right">1197cc</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>CO2</td>
                            <td className="pull-right">119g/Km</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Engine</td>
                            <td className="pull-right">TRANSVERSE INLINE 4</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Power</td>
                            <td className="pull-right">97kW@5500</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Torque</td>
                            <td className="pull-right">205Nm@2000</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Valves</td>
                            <td className="pull-right">DOHC 4 VALVES PER CYLINDER, TURBO</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Fuel</td>
                            <td className="pull-right">UNLEADED</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Fuel capacity</td>
                            <td className="pull-right">47 litres</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>City fuel consumption</td>
                            <td className="pull-right">6.8 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Highway fuel consumption</td>
                            <td className="pull-right">4.5 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Average fuel consumption</td>
                            <td className="pull-right">5.3 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Top speed</td>
                            <td className="pull-right">197Km/h</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>0 - 100 Km/h</td>
                            <td className="pull-right">10.6sec</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Driving and Control Environment</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Gearbox</td>
                            <td className="pull-right">MANUAL</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Gears</td>
                            <td className="pull-right">6</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Drive</td>
                            <td className="pull-right">2</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Power steering</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Steering adjustment (up down)</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Steering adjustment (in out)</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Multi function steering wheel</td>
                            <td className="pull-right">AUDIO, PHONE</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Cruise control</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Computer</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Seats</td>
                            <td className="pull-right">5 -
                            {/* <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span> */}
                            </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Electric mirror fold</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Spare tire size</td>
                            <td className="pull-right">FULLSIZE</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Keyless Entry</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Interior Comfort</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Air conditioner</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Climate control</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Electric windows</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Electric window detail</td>
                            <td className="pull-right">FRONT + REAR</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Electric window 1-touch</td>
                            <td className="pull-right">DRIVER</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Radio</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Remote side mirrors</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Hands-free phone</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Exterior</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Metallic paint</td>
                            <td className="pull-right">
                                OPTION
                        </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Colour coded bumpers</td>
                            <td className="pull-right">
                                PAINTED
                        </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Colour coded mirrors</td>
                            <td className="pull-right">
                                BODY
                        </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Park distance - rear</td>
                            <td className="pull-right">
                                YES
                        </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Satelite navigation</td>
                            <td className="pull-right">
                                YES
                        </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Alloy wheels</td>
                            <td className="pull-right">
                                YES
                        </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Boot capacity</td>
                            <td className="pull-right">
                                372 litres
                        </td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </section>
    </amp-accordion>
)

export const traficSpecs = (
    <amp-accordion className="i-amphtml-element i-amphtml-layout-container i-amphtml-built i-amphtml-layout"
        i-amphtml-layout="container">
        <section>
            <h4 className="bg-tertiary mb1 px2 py1 i-amphtml-accordion-header" id="80_AMP_header_0" role="button"
                aria-controls="80_AMP_content_0" aria-expanded="false" tabindex="0">1.6 dCi PANEL VAN <span
                    className="pull-right">R 450 900</span></h4>
            <div className="bg-tertiary i-amphtml-accordion-content" id="80_AMP_content_0" aria-labelledby="80_AMP_header_0"
                role="region">
                <table className="table-light">
                    <tbody>
                        <tr className="bg-darken-1">
                            <td colspan="2">Service / Maintenance / Warranty</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Service interval</td>
                            <td><span className="pull-right">15 000 km</span></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td className="align-top">Service Plan</td>
                            <td><span className="pull-right">60 months</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Warranty</td>
                            <td><span className="pull-right">5 YEAR/150000KM, 6 YEAR COROSSION, 5 YEAR ROADSIDE ASSISTANCE,
                                5 YEAR/100000KM SERVICE PLAN</span></td>
                        </tr>
                    </tbody>
                </table>
                <table className="table-light">
                    <tbody>
                        <tr className="bg-darken-1">
                            <td colspan="2">Active and Passive Safety</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">ABS</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Electronic brakeforce distribution (EBD)</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Air bags</td>
                            <td><span className="pull-right">1</span></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td className="align-top">Autolock</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Performance</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Engine size</td>
                            <td className="pull-right">1598cc</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>CO2</td>
                            <td className="pull-right">199g/Km</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Engine</td>
                            <td className="pull-right">TRANSVERSE INLINE 4</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Power</td>
                            <td className="pull-right">88kW@3500</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Torque</td>
                            <td className="pull-right">320Nm@1500</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Valves</td>
                            <td className="pull-right">DOHC 4 VALVES PER CYLINDER</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Fuel</td>
                            <td className="pull-right">DIESEL</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Fuel capacity</td>
                            <td className="pull-right">80 litres</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>City fuel consumption</td>
                            <td className="pull-right">8.2 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Highway fuel consumption</td>
                            <td className="pull-right">7.3 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Average fuel consumption</td>
                            <td className="pull-right">7.6 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Top speed</td>
                            <td className="pull-right">165Km/h</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>0 - 100 Km/h</td>
                            <td className="pull-right">12sec</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Driving and Control Environment</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Gearbox</td>
                            <td className="pull-right">MANUAL</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Gears</td>
                            <td className="pull-right">6</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Drive</td>
                            <td className="pull-right">2</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Power steering</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Steering adjustment (in out)</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Computer</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Seats</td>
                            <td className="pull-right">2 -
                            {/* <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span> */}
                            </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Spare tire size</td>
                            <td className="pull-right">FULLSIZE</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Keyless Entry</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Interior Comfort</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Air conditioner</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Electric windows</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Electric window detail</td>
                            <td className="pull-right">FRONT</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Electric window 1-touch</td>
                            <td className="pull-right">DRIVER</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Radio</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Remote side mirrors</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Exterior</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Colour coded bumpers</td>
                            <td className="pull-right">
                                BLACK
                        </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Colour coded mirrors</td>
                            <td className="pull-right">
                                BLACK
                        </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Boot capacity</td>
                            <td className="pull-right">
                                6000 litres
                        </td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </section>
    </amp-accordion>
)

export const clioRSSpecs = (
    <amp-accordion className="i-amphtml-element i-amphtml-layout-container i-amphtml-built i-amphtml-layout"
        i-amphtml-layout="container">
        <section>
            <h4 className="bg-tertiary mb1 px2 py1 i-amphtml-accordion-header" id="54_AMP_header_0" role="button"
                aria-controls="54_AMP_content_0" aria-expanded="false" tabindex="0">18 F1 <span className="pull-right">R 459
                900</span></h4>
            <div className="bg-tertiary i-amphtml-accordion-content" id="54_AMP_content_0" aria-labelledby="54_AMP_header_0"
                role="region">
                <table className="table-light">
                    <tbody>
                        <tr className="bg-darken-1">
                            <td colspan="2">Service / Maintenance / Warranty</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Service interval</td>
                            <td><span className="pull-right">10 000 km</span></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td className="align-top">Service Plan</td>
                            <td><span className="pull-right">36 months</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Warranty</td>
                            <td><span className="pull-right">5 YEAR/150000KM, 6 YEAR COROSSION, 3 YEAR/30000KM SERVICE
                                PLAN</span></td>
                        </tr>
                    </tbody>
                </table>
                <table className="table-light">
                    <tbody>
                        <tr className="bg-darken-1">
                            <td colspan="2">Active and Passive Safety</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">ABS</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Electronic brakeforce distribution (EBD)</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td className="align-top">Auto Windscreen Wipers</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Air bags</td>
                            <td><span className="pull-right">4</span></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td className="align-top">Automatic Lights</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Day Running Lights</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td className="align-top">Autolock</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Performance</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Engine size</td>
                            <td className="pull-right">1618cc</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>CO2</td>
                            <td className="pull-right">135g/Km</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Engine</td>
                            <td className="pull-right">TRANSVERSE INLINE 4</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Power</td>
                            <td className="pull-right">162kW@6050</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Torque</td>
                            <td className="pull-right">260Nm@2000</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Valves</td>
                            <td className="pull-right">DOHC 4 VALVES PER CYLINDER</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Fuel</td>
                            <td className="pull-right">UNLEADED</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Fuel capacity</td>
                            <td className="pull-right">45 litres</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>City fuel consumption</td>
                            <td className="pull-right">7.6 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Highway fuel consumption</td>
                            <td className="pull-right">5.1 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Average fuel consumption</td>
                            <td className="pull-right">5.9 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Top speed</td>
                            <td className="pull-right">235Km/h</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>0 - 100 Km/h</td>
                            <td className="pull-right">6.6sec</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Driving and Control Environment</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Gearbox</td>
                            <td className="pull-right">AUTOMATIC</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Gears</td>
                            <td className="pull-right">6</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Drive</td>
                            <td className="pull-right">2</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Power steering</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Steering adjustment (up down)</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Steering adjustment (in out)</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Multi function steering wheel</td>
                            <td className="pull-right">AUDIO, PHONE, CRUISE</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Cruise control</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Computer</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Seats</td>
                            <td className="pull-right">5 -
                            {/* <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span> */}
                            </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Electric mirror fold</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Spare tire size</td>
                            <td className="pull-right">NA</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Keyless Entry</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Interior Comfort</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Air conditioner</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Climate control</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Electric windows</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Electric window detail</td>
                            <td className="pull-right">FRONT + REAR</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Electric window 1-touch</td>
                            <td className="pull-right">DRIVER</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Radio</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Leather</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Remote side mirrors</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Exterior</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Metallic paint</td>
                            <td className="pull-right">
                                YES
                        </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Colour coded bumpers</td>
                            <td className="pull-right">
                                PAINTED
                        </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Colour coded mirrors</td>
                            <td className="pull-right">
                                GLOSS-BLACK
                        </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Fog lamps - front</td>
                            <td className="pull-right">
                                LED
                        </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Xenon headlamps</td>
                            <td className="pull-right">
                                LED
                        </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Park distance - rear</td>
                            <td className="pull-right">
                                YES
                        </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Satelite navigation</td>
                            <td className="pull-right">
                                YES
                        </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Alloy wheels</td>
                            <td className="pull-right">
                                YES
                        </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Boot capacity</td>
                            <td className="pull-right">
                                300 litres
                        </td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </section>
    </amp-accordion>
)

export const koleosSpecs = (
    <amp-accordion className="i-amphtml-element i-amphtml-layout-container i-amphtml-built i-amphtml-layout"
        i-amphtml-layout="container">
        <section>
            <h4 className="bg-tertiary mb1 px2 py1 i-amphtml-accordion-header" id="17_AMP_header_0" role="button"
                aria-controls="17_AMP_content_0" aria-expanded="false" tabindex="0">2.5 EXPRESSION CVT <span
                    className="pull-right">R 464 900</span></h4>
            <div className="bg-tertiary i-amphtml-accordion-content" id="17_AMP_content_0" aria-labelledby="17_AMP_header_0"
                role="region">
                <table className="table-light">
                    <tbody>
                        <tr className="bg-darken-1">
                            <td colspan="2">Service / Maintenance / Warranty</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Service interval</td>
                            <td><span className="pull-right">15 000 km</span></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td className="align-top">Service Plan</td>
                            <td><span className="pull-right">60 months</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Warranty</td>
                            <td><span className="pull-right">5 YEAR/150000KM, 6 YEAR COROSSION, 5 YEAR/90000KM SERVICE
                                PLAN</span></td>
                        </tr>
                    </tbody>
                </table>
                <table className="table-light">
                    <tbody>
                        <tr className="bg-darken-1">
                            <td colspan="2">Active and Passive Safety</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">ABS</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Electronic brakeforce distribution (EBD)</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td className="align-top">Auto Windscreen Wipers</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Air bags</td>
                            <td><span className="pull-right">6</span></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td className="align-top">Automatic Lights</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">ISOfix (secure child seat)</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Performance</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Engine size</td>
                            <td className="pull-right">2488cc</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>CO2</td>
                            <td className="pull-right">188g/Km</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Engine</td>
                            <td className="pull-right">TRANSVERSE INLINE 4</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Power</td>
                            <td className="pull-right">126kW@6000</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Torque</td>
                            <td className="pull-right">233Nm@4000</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Valves</td>
                            <td className="pull-right">DOHC, 4 VALVES PER CYLINDER</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Fuel</td>
                            <td className="pull-right">UNLEADED</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Fuel capacity</td>
                            <td className="pull-right">60 litres</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>City fuel consumption</td>
                            <td className="pull-right">10.4 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Highway fuel consumption</td>
                            <td className="pull-right">6.7 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Average fuel consumption</td>
                            <td className="pull-right">8.1 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Top speed</td>
                            <td className="pull-right">185Km/h</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>0 - 100 Km/h</td>
                            <td className="pull-right">9.5sec</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Driving and Control Environment</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Gearbox</td>
                            <td className="pull-right">AUTOMATIC</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Gears</td>
                            <td className="pull-right">7</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Drive</td>
                            <td className="pull-right">2</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Power steering</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Steering adjustment (up down)</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Steering adjustment (in out)</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Multi function steering wheel</td>
                            <td className="pull-right">AUDIO, CRUISE, PHONE</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Cruise control</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Seats</td>
                            <td className="pull-right">5 -
                            {/* <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span> */}
                            </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Spare tire size</td>
                            <td className="pull-right">FULLSIZE</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Keyless Entry</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Interior Comfort</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Air conditioner</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Electric windows</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Electric window detail</td>
                            <td className="pull-right">FRONT + REAR</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Electric window 1-touch</td>
                            <td className="pull-right"></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Radio</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Remote side mirrors</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Exterior</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Metallic paint</td>
                            <td className="pull-right">
                                OPTION
                        </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Colour coded bumpers</td>
                            <td className="pull-right">
                                PAINTED
                        </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Colour coded mirrors</td>
                            <td className="pull-right">
                                BODY
                        </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Park distance - rear</td>
                            <td className="pull-right">
                                YES
                        </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Boot capacity</td>
                            <td className="pull-right">
                                464 litres
                        </td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </section>
        <section>
            <h4 className="bg-tertiary mb1 px2 py1 i-amphtml-accordion-header" id="17_AMP_header_1" role="button"
                aria-controls="17_AMP_content_1" aria-expanded="false" tabindex="0">2.5 DYNAMIQUE CVT <span
                    className="pull-right">R 514 900</span></h4>
            <div className="bg-tertiary i-amphtml-accordion-content" id="17_AMP_content_1" aria-labelledby="17_AMP_header_1"
                role="region">
                <table className="table-light">
                    <tbody>
                        <tr className="bg-darken-1">
                            <td colspan="2">Service / Maintenance / Warranty</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Service interval</td>
                            <td><span className="pull-right">15 000 km</span></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td className="align-top">Service Plan</td>
                            <td><span className="pull-right">60 months</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Warranty</td>
                            <td><span className="pull-right">5 YEAR/150000KM, 6 YEAR COROSSION, 5 YEAR/90000KM SERVICE
                                PLAN</span></td>
                        </tr>
                    </tbody>
                </table>
                <table className="table-light">
                    <tbody>
                        <tr className="bg-darken-1">
                            <td colspan="2">Active and Passive Safety</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">ABS</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Electronic brakeforce distribution (EBD)</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td className="align-top">Auto Windscreen Wipers</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Air bags</td>
                            <td><span className="pull-right">6</span></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td className="align-top">Automatic Lights</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">ISOfix (secure child seat)</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Performance</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Engine size</td>
                            <td className="pull-right">2488cc</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>CO2</td>
                            <td className="pull-right">188g/Km</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Engine</td>
                            <td className="pull-right">TRANSVERSE INLINE 4</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Power</td>
                            <td className="pull-right">126kW@6000</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Torque</td>
                            <td className="pull-right">233Nm@4000</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Valves</td>
                            <td className="pull-right">DOHC, 4 VALVES PER CYLINDER</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Fuel</td>
                            <td className="pull-right">UNLEADED</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Fuel capacity</td>
                            <td className="pull-right">60 litres</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>City fuel consumption</td>
                            <td className="pull-right">10.4 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Highway fuel consumption</td>
                            <td className="pull-right">6.7 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Average fuel consumption</td>
                            <td className="pull-right">8.1 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Top speed</td>
                            <td className="pull-right">185Km/h</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>0 - 100 Km/h</td>
                            <td className="pull-right">9.5sec</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Driving and Control Environment</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Gearbox</td>
                            <td className="pull-right">AUTOMATIC</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Gears</td>
                            <td className="pull-right">7</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Drive</td>
                            <td className="pull-right">2</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Power steering</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Steering adjustment (up down)</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Steering adjustment (in out)</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Multi function steering wheel</td>
                            <td className="pull-right">AUDIO, CRUISE, PHONE</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Cruise control</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Seats</td>
                            <td className="pull-right">5 -
                            {/* <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span> */}
                            </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Electric mirror fold</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Spare tire size</td>
                            <td className="pull-right">FULLSIZE</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Keyless Entry</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Interior Comfort</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Air conditioner</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Electric windows</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Electric window detail</td>
                            <td className="pull-right">FRONT + REAR</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Electric window 1-touch</td>
                            <td className="pull-right"></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Radio</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Leather</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Remote side mirrors</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Exterior</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Metallic paint</td>
                            <td className="pull-right">
                                OPTION
                        </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Colour coded bumpers</td>
                            <td className="pull-right">
                                PAINTED
                        </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Colour coded mirrors</td>
                            <td className="pull-right">
                                BODY
                        </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Park distance - rear</td>
                            <td className="pull-right">
                                YES
                        </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Boot capacity</td>
                            <td className="pull-right">
                                464 litres
                        </td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </section>
        <section>
            <h4 className="bg-tertiary mb1 px2 py1 i-amphtml-accordion-header" id="17_AMP_header_2" role="button"
                aria-controls="17_AMP_content_2" aria-expanded="false" tabindex="0">2.5 DYNAMIQUE 4X4 CVT <span
                    className="pull-right">R 554 900</span></h4>
            <div className="bg-tertiary i-amphtml-accordion-content" id="17_AMP_content_2" aria-labelledby="17_AMP_header_2"
                role="region">
                <table className="table-light">
                    <tbody>
                        <tr className="bg-darken-1">
                            <td colspan="2">Service / Maintenance / Warranty</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Service interval</td>
                            <td><span className="pull-right">15 000 km</span></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td className="align-top">Service Plan</td>
                            <td><span className="pull-right">60 months</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Warranty</td>
                            <td><span className="pull-right">5 YEAR/150000KM, 6 YEAR COROSSION, 5 YEAR/90000KM SERVICE
                                PLAN</span></td>
                        </tr>
                    </tbody>
                </table>
                <table className="table-light">
                    <tbody>
                        <tr className="bg-darken-1">
                            <td colspan="2">Active and Passive Safety</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">ABS</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Electronic brakeforce distribution (EBD)</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td className="align-top">Auto Windscreen Wipers</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Air bags</td>
                            <td><span className="pull-right">6</span></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td className="align-top">Automatic Lights</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">ISOfix (secure child seat)</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Performance</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Engine size</td>
                            <td className="pull-right">2488cc</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>CO2</td>
                            <td className="pull-right">192g/Km</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Engine</td>
                            <td className="pull-right">TRANSVERSE INLINE 4</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Power</td>
                            <td className="pull-right">126kW@6000</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Torque</td>
                            <td className="pull-right">233Nm@4000</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Valves</td>
                            <td className="pull-right">DOHC, 4 VALVES PER CYLINDER</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Fuel</td>
                            <td className="pull-right">UNLEADED</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Fuel capacity</td>
                            <td className="pull-right">60 litres</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>City fuel consumption</td>
                            <td className="pull-right">10.7 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Highway fuel consumption</td>
                            <td className="pull-right">6.9 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Average fuel consumption</td>
                            <td className="pull-right">8.3 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Top speed</td>
                            <td className="pull-right">199Km/h</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>0 - 100 Km/h</td>
                            <td className="pull-right">9.8sec</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Driving and Control Environment</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Gearbox</td>
                            <td className="pull-right">AUTOMATIC</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Gears</td>
                            <td className="pull-right">7</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Drive</td>
                            <td className="pull-right">4</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Power steering</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Steering adjustment (up down)</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Steering adjustment (in out)</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Multi function steering wheel</td>
                            <td className="pull-right">AUDIO, CRUISE, PHONE</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Cruise control</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Seats</td>
                            <td className="pull-right">5 -
                            {/* <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span> */}
                            </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Electric mirror fold</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Spare tire size</td>
                            <td className="pull-right">FULLSIZE</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Keyless Entry</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Interior Comfort</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Air conditioner</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Electric windows</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Electric window detail</td>
                            <td className="pull-right">FRONT + REAR</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Electric window 1-touch</td>
                            <td className="pull-right"></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Radio</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Leather</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Remote side mirrors</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Exterior</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Metallic paint</td>
                            <td className="pull-right">
                                OPTION
                        </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Colour coded bumpers</td>
                            <td className="pull-right">
                                PAINTED
                        </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Colour coded mirrors</td>
                            <td className="pull-right">
                                BODY
                        </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Park distance - rear</td>
                            <td className="pull-right">
                                YES
                        </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Boot capacity</td>
                            <td className="pull-right">
                                464 litres
                        </td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </section>
    </amp-accordion>
)

export const meganeRSSpecs = (
    <amp-accordion className="i-amphtml-element i-amphtml-layout-container i-amphtml-built i-amphtml-layout"
        i-amphtml-layout="container">
        <section>
            <h4 className="bg-tertiary mb1 px2 py1 i-amphtml-accordion-header" id="33_AMP_header_0" role="button"
                aria-controls="33_AMP_content_0" aria-expanded="false" tabindex="0">280 CUP <span className="pull-right">R
                634 900</span></h4>
            <div className="bg-tertiary i-amphtml-accordion-content" id="33_AMP_content_0" aria-labelledby="33_AMP_header_0"
                role="region">
                <table className="table-light">
                    <tbody>
                        <tr className="bg-darken-1">
                            <td colspan="2">Service / Maintenance / Warranty</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Service interval</td>
                            <td><span className="pull-right">10 000 km</span></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td className="align-top">Service Plan</td>
                            <td><span className="pull-right">60 months</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Warranty</td>
                            <td><span className="pull-right">5 YEAR/150000KM, 6 YEAR COROSSION, 5 YEAR/90000KM SERVICE
                                PLAN</span></td>
                        </tr>
                    </tbody>
                </table>
                <table className="table-light">
                    <tbody>
                        <tr className="bg-darken-1">
                            <td colspan="2">Active and Passive Safety</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">ABS</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Electronic brakeforce distribution (EBD)</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td className="align-top">Auto Windscreen Wipers</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Air bags</td>
                            <td><span className="pull-right">6</span></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td className="align-top">Automatic Lights</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td className="align-top">Autolock</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Performance</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Engine size</td>
                            <td className="pull-right">1798cc</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>CO2</td>
                            <td className="pull-right">163g/Km</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Engine</td>
                            <td className="pull-right">TRANSVERSE INLINE 4</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Power</td>
                            <td className="pull-right">205kW@6000</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Torque</td>
                            <td className="pull-right">390Nm@2400</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Valves</td>
                            <td className="pull-right">DOUHC, 4 VALVES PER CYLINDER</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Fuel</td>
                            <td className="pull-right">UNLEADED</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Fuel capacity</td>
                            <td className="pull-right">50 litres</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>City fuel consumption</td>
                            <td className="pull-right">9.1 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Highway fuel consumption</td>
                            <td className="pull-right">6.1 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Average fuel consumption</td>
                            <td className="pull-right">7.2 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Top speed</td>
                            <td className="pull-right">250Km/h</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>0 - 100 Km/h</td>
                            <td className="pull-right">5.8sec</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Driving and Control Environment</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Gearbox</td>
                            <td className="pull-right">MANUAL</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Gears</td>
                            <td className="pull-right">6</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Drive</td>
                            <td className="pull-right">2</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Power steering</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Steering adjustment (up down)</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Steering adjustment (in out)</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Multi function steering wheel</td>
                            <td className="pull-right">AUDIO, PHONE</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Cruise control</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Computer</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Seats</td>
                            <td className="pull-right">5 -
                            {/* <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span> */}
                            </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Electric mirror fold</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Spare tire size</td>
                            <td className="pull-right">NA</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Keyless Entry</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Interior Comfort</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Air conditioner</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Electric windows</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Electric window detail</td>
                            <td className="pull-right">FRONT + REAR</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Electric window 1-touch</td>
                            <td className="pull-right">ALL</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Radio</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Exterior</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Metallic paint</td>
                            <td className="pull-right">
                                OPTION
                        </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Colour coded bumpers</td>
                            <td className="pull-right">
                                PAINTED
                        </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Colour coded mirrors</td>
                            <td className="pull-right">
                                STARRY BLACK
                        </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Park distance - rear</td>
                            <td className="pull-right">
                                YES
                        </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Satelite navigation</td>
                            <td className="pull-right">

                            </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Alloy wheels</td>
                            <td className="pull-right">
                                YES
                        </td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </section>
        <section>
            <h4 className="bg-tertiary mb1 px2 py1 i-amphtml-accordion-header" id="33_AMP_header_1" role="button"
                aria-controls="33_AMP_content_1" aria-expanded="false" tabindex="0">280 LUX EDC <span
                    className="pull-right">R 634 900</span></h4>
            <div className="bg-tertiary i-amphtml-accordion-content" id="33_AMP_content_1" aria-labelledby="33_AMP_header_1"
                role="region">
                <table className="table-light">
                    <tbody>
                        <tr className="bg-darken-1">
                            <td colspan="2">Service / Maintenance / Warranty</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Service interval</td>
                            <td><span className="pull-right">10 000 km</span></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td className="align-top">Service Plan</td>
                            <td><span className="pull-right">60 months</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Warranty</td>
                            <td><span className="pull-right">5 YEAR/150000KM, 6 YEAR COROSSION, 5 YEAR/90000KM SERVICE
                                PLAN</span></td>
                        </tr>
                    </tbody>
                </table>
                <table className="table-light">
                    <tbody>
                        <tr className="bg-darken-1">
                            <td colspan="2">Active and Passive Safety</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">ABS</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Electronic brakeforce distribution (EBD)</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td className="align-top">Auto Windscreen Wipers</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Air bags</td>
                            <td><span className="pull-right">6</span></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td className="align-top">Automatic Lights</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td className="align-top">Autolock</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Performance</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Engine size</td>
                            <td className="pull-right">1798cc</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>CO2</td>
                            <td className="pull-right">158g/Km</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Engine</td>
                            <td className="pull-right">TRANSVERSE INLINE 4</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Power</td>
                            <td className="pull-right">205kW@6000</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Torque</td>
                            <td className="pull-right">390Nm@2400</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Valves</td>
                            <td className="pull-right">DOUHC, 4 VALVES PER CYLINDER</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Fuel</td>
                            <td className="pull-right">UNLEADED</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Fuel capacity</td>
                            <td className="pull-right">50 litres</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>City fuel consumption</td>
                            <td className="pull-right">8.5 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Highway fuel consumption</td>
                            <td className="pull-right">6.1 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Average fuel consumption</td>
                            <td className="pull-right">7 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Top speed</td>
                            <td className="pull-right">250Km/h</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>0 - 100 Km/h</td>
                            <td className="pull-right">5.8sec</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Driving and Control Environment</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Gearbox</td>
                            <td className="pull-right">AUTOMATIC</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Gears</td>
                            <td className="pull-right">6</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Drive</td>
                            <td className="pull-right">2</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Power steering</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Steering adjustment (up down)</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Steering adjustment (in out)</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Multi function steering wheel</td>
                            <td className="pull-right">AUDIO, PHONE, GEAR</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Cruise control</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Computer</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Seats</td>
                            <td className="pull-right">5 -
                            {/* <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span> */}
                            </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Electric mirror fold</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Spare tire size</td>
                            <td className="pull-right">NA</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Keyless Entry</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Interior Comfort</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Air conditioner</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Electric windows</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Electric window detail</td>
                            <td className="pull-right">FRONT + REAR</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Electric window 1-touch</td>
                            <td className="pull-right">ALL</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Radio</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Remote side mirrors</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Exterior</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Metallic paint</td>
                            <td className="pull-right">
                                OPTION
                        </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Colour coded bumpers</td>
                            <td className="pull-right">
                                PAINTED
                        </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Colour coded mirrors</td>
                            <td className="pull-right">
                                STARRY BLACK
                        </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Park distance - rear</td>
                            <td className="pull-right">
                                YES
                        </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Satelite navigation</td>
                            <td className="pull-right">

                            </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Alloy wheels</td>
                            <td className="pull-right">
                                YES
                        </td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </section>
        <section>
            <h4 className="bg-tertiary mb1 px2 py1 i-amphtml-accordion-header" id="33_AMP_header_2" role="button"
                aria-controls="33_AMP_content_2" aria-expanded="false" tabindex="0">300 TROPHY <span
                    className="pull-right">R 774 900</span></h4>
            <div className="bg-tertiary i-amphtml-accordion-content" id="33_AMP_content_2" aria-labelledby="33_AMP_header_2"
                role="region">
                <table className="table-light">
                    <tbody>
                        <tr className="bg-darken-1">
                            <td colspan="2">Service / Maintenance / Warranty</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Service interval</td>
                            <td><span className="pull-right">10 000 km</span></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td className="align-top">Service Plan</td>
                            <td><span className="pull-right">60 months</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Warranty</td>
                            <td><span className="pull-right">5 YEAR/150000KM, 6 YEAR COROSSION, 5 YEAR/90000KM SERVICE
                                PLAN</span></td>
                        </tr>
                    </tbody>
                </table>
                <table className="table-light">
                    <tbody>
                        <tr className="bg-darken-1">
                            <td colspan="2">Active and Passive Safety</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">ABS</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Electronic brakeforce distribution (EBD)</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td className="align-top">Auto Windscreen Wipers</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Air bags</td>
                            <td><span className="pull-right">6</span></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td className="align-top">Automatic Lights</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td className="align-top">Autolock</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Performance</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Engine size</td>
                            <td className="pull-right">1798cc</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>CO2</td>
                            <td className="pull-right">163g/Km</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Engine</td>
                            <td className="pull-right">TRANSVERSE INLINE 4</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Power</td>
                            <td className="pull-right">221kW@6000</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Torque</td>
                            <td className="pull-right">400Nm@2400</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Valves</td>
                            <td className="pull-right">DOUHC, 4 VALVES PER CYLINDER</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Fuel</td>
                            <td className="pull-right">UNLEADED</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Fuel capacity</td>
                            <td className="pull-right">50 litres</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Average fuel consumption</td>
                            <td className="pull-right">8.2 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Top speed</td>
                            <td className="pull-right">260Km/h</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>0 - 100 Km/h</td>
                            <td className="pull-right">5.7sec</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Driving and Control Environment</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Gearbox</td>
                            <td className="pull-right">MANUAL</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Gears</td>
                            <td className="pull-right">6</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Drive</td>
                            <td className="pull-right">2</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Power steering</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Steering adjustment (up down)</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Steering adjustment (in out)</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Multi function steering wheel</td>
                            <td className="pull-right">AUDIO, PHONE</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Cruise control</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Computer</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Seats</td>
                            <td className="pull-right">5 -
                            {/* <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span> */}
                            </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Electric mirror fold</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Spare tire size</td>
                            <td className="pull-right">NA</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Keyless Entry</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Interior Comfort</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Air conditioner</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Climate control</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Electric windows</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Electric window detail</td>
                            <td className="pull-right">FRONT + REAR</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Electric window 1-touch</td>
                            <td className="pull-right">ALL</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Radio</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Exterior</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Metallic paint</td>
                            <td className="pull-right">
                                OPTION
                        </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Colour coded bumpers</td>
                            <td className="pull-right">
                                PAINTED
                        </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Colour coded mirrors</td>
                            <td className="pull-right">
                                STARRY BLACK
                        </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Park distance - rear</td>
                            <td className="pull-right">
                                YES
                        </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Satelite navigation</td>
                            <td className="pull-right">

                            </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Alloy wheels</td>
                            <td className="pull-right">
                                YES
                        </td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </section>
        <section>
            <h4 className="bg-tertiary mb1 px2 py1 i-amphtml-accordion-header" id="33_AMP_header_3" role="button"
                aria-controls="33_AMP_content_3" aria-expanded="false" tabindex="0">300 TROPHY EDC <span
                    className="pull-right">R 799 900</span></h4>
            <div className="bg-tertiary i-amphtml-accordion-content" id="33_AMP_content_3" aria-labelledby="33_AMP_header_3"
                role="region">
                <table className="table-light">
                    <tbody>
                        <tr className="bg-darken-1">
                            <td colspan="2">Service / Maintenance / Warranty</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Service interval</td>
                            <td><span className="pull-right">10 000 km</span></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td className="align-top">Service Plan</td>
                            <td><span className="pull-right">60 months</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Warranty</td>
                            <td><span className="pull-right">5 YEAR/150000KM, 6 YEAR COROSSION, 5 YEAR/90000KM SERVICE
                                PLAN</span></td>
                        </tr>
                    </tbody>
                </table>
                <table className="table-light">
                    <tbody>
                        <tr className="bg-darken-1">
                            <td colspan="2">Active and Passive Safety</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">ABS</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Electronic brakeforce distribution (EBD)</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td className="align-top">Auto Windscreen Wipers</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td className="align-top">Air bags</td>
                            <td><span className="pull-right">6</span></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td className="align-top">Automatic Lights</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td className="align-top">Autolock</td>
                            <td><span className="pull-right">YES</span></td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Performance</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Engine size</td>
                            <td className="pull-right">1798cc</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>CO2</td>
                            <td className="pull-right">158g/Km</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Engine</td>
                            <td className="pull-right">TRANSVERSE INLINE 4</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Power</td>
                            <td className="pull-right">221kW@6000</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Torque</td>
                            <td className="pull-right">400Nm@2400</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Valves</td>
                            <td className="pull-right">DOUHC, 4 VALVES PER CYLINDER</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Fuel</td>
                            <td className="pull-right">UNLEADED</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Fuel capacity</td>
                            <td className="pull-right">50 litres</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Average fuel consumption</td>
                            <td className="pull-right">8.4 l/Km</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Top speed</td>
                            <td className="pull-right">255Km/h</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>0 - 100 Km/h</td>
                            <td className="pull-right">5.7sec</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Driving and Control Environment</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Gearbox</td>
                            <td className="pull-right">AUTOMATIC</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Gears</td>
                            <td className="pull-right">6</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Drive</td>
                            <td className="pull-right">2</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Power steering</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Steering adjustment (up down)</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Steering adjustment (in out)</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Multi function steering wheel</td>
                            <td className="pull-right">AUDIO, PHONE, GEAR</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Cruise control</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Computer</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Seats</td>
                            <td className="pull-right">5 -
                            {/* <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span>
                                <span className="material-icons">accessibility</span> */}
                            </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Electric mirror fold</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Spare tire size</td>
                            <td className="pull-right">NA</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Keyless Entry</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Interior Comfort</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Air conditioner</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Climate control</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Electric windows</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Electric window detail</td>
                            <td className="pull-right">FRONT + REAR</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Electric window 1-touch</td>
                            <td className="pull-right">ALL</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Radio</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Remote side mirrors</td>
                            <td className="pull-right">YES</td>
                        </tr>
                        <tr className="bg-darken-1">
                            <td colspan="2">Exterior</td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Metallic paint</td>
                            <td className="pull-right">
                                OPTION
                        </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Colour coded bumpers</td>
                            <td className="pull-right">
                                PAINTED
                        </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Colour coded mirrors</td>
                            <td className="pull-right">
                                STARRY BLACK
                        </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Park distance - rear</td>
                            <td className="pull-right">
                                YES
                        </td>
                        </tr>
                        <tr className="bg-lighten-4">
                            <td>Satelite navigation</td>
                            <td className="pull-right">

                            </td>
                        </tr>
                        <tr className="bg-lighten-3">
                            <td>Alloy wheels</td>
                            <td className="pull-right">
                                YES
                        </td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </section>
    </amp-accordion>
)