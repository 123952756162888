import React, { useContext } from 'react'
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { DEALERID, MASTER_DEALERID, BASE_URI, MASTER_MOTORGROUPID, MOTORGROUPID } from '../shared/Constants'
import { Service } from '@red-build/leadcentre';
import { DealerContext } from '../shared/contexts/DealerContext'

import PartsBanner from '../shared/assets/service.jpg'
const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    paddingTop: '60px',
    paddingBottom: '60px',
    backgroundImage: `url(${PartsBanner})`,
    backgroundColor: 'rgb(0 0 0 / 50%)',
    backgroundBlendMode: 'overlay',
    backgroundSize: "cover",
    backgroundPosition: 'center',
  },
  innerSection: {
    width: "100%",
    maxWidth: "1170px",
    display: "flex",
    justifyContent: "space-between",
    padding: "6px 15px",

    '@media (max-width: 959px)': {
      flexWrap: 'wrap',
      padding: "6px 0px",
    },
  },
  section: {
    width: '60%',

    '&:nth-child(1)': {
      marginRight: '40px',
      width: '40%',
      color: 'white',
    },
    '@media (max-width: 959px)': {
      width: '100%',
      margin: '15px',

      '&:nth-child(1)': {
        margin: '0px',
        padding: '0px 20px',
        width: '100%',

      },
    },
  },
  sectionC: {
    width: '60%',
    background: 'white',
    padding: '20px',
    borderRadius: '5px',
    '&:nth-child(1)': {
      marginRight: '40px',
      width: '40%',
    },
    '@media (max-width: 959px)': {
      width: '100%',
      margin: '15px',

      '&:nth-child(1)': {
        margin: '0px',
        padding: '0px 20px',
        width: '100%',

      },
    },
  },



  text: {
    fontSize: '16px',
    padding: '20px 0px'
  },
  titleTop: {
    fontSize: '44px',
    fontWeight: 'bold'
  },
  titleTopAccent: {
    fontSize: '52px',
    color: 'white',

    fontWeight: 'bold'
  },
}))




const BookService = () => {
  const classes = useStyles();

  const { dealerList, globalDealer } = useContext(DealerContext)


  const defaultColors = {
    primaryColor: "#36434c",
    secondaryColor: "#EFDF00",
  };

  return (
    <div className={classes.root}>
      <div className={classes.innerSection}>
        <div className={classes.section} >
          <Typography variant="h1" className={classes.titleTop}>
            BOOK A SERVICE
          </Typography>
          {/* <Typography variant="h1" className={classes.titleTopAccent}>

     
        </Typography> */}

          <Typography variant="body2" className={classes.text}>
            We will happily assist in the service of your vehicle, leave your details and one of our service advisors will contact you shortly.

          </Typography>

        </div>
        <div className={classes.sectionC}>

          <Service
            base_uri={BASE_URI}
            //serviceType='standard' 
            dealerName={globalDealer.id == MASTER_DEALERID ? "" : globalDealer?.name?.replace("Master", "")?.replace("Kia", "Group")}
            //dealerId={4}
            //     activeMake="CHERY"
            //    activeMakeId={8104}
            leadType={5}
            websiteColors={defaultColors}
            masterDealerId={MASTER_DEALERID}


            motorGroupId={MOTORGROUPID}
            dealerList={dealerList}
            //models={models} 
            hasMultiDealers={true}
          //sideBarDetailsHeader={partsDetailsHeading} 
          //sideBarDetails={partsDetails} 
          />

        </div>
      </div>
    </div>

  )
}

export default BookService