import React, { useContext, useEffect, useState } from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { MASTER_DEALERID, BASE_URI, DEALERID } from "../shared/Constants";
import { ContactUs as Form } from "@red-build/leadcentre";
import { DealerContext } from "../shared/contexts/DealerContext";
import logoIcon from "../shared/assets/box_icon.png";
import PartsBanner from '../shared/assets/contact.png'

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    paddingTop: "60px",
    paddingBottom: '60px',
    backgroundImage: `url(${PartsBanner})`,
    backgroundColor: 'rgb(0 0 0 / 50%)',
    backgroundBlendMode: 'overlay',
    backgroundSize: "cover",
    backgroundPosition: 'center',
  },
  innerSection: {
    width: "100%",
    maxWidth: "1170px",
    display: "flex",
    justifyContent: "space-between",
    padding: "6px 15px",

    "@media (max-width: 959px)": {
      flexWrap: "wrap",
      padding: "0px 0px",
    },
  },
  DealerSection: {
    width: "100%",
    maxWidth: "1170px",


    padding: "40px 0px ",
  },
  DealerSectionInner: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",

  },
  DealerSectionCard: {
    width: "100%",
    maxWidth: "1170px",
    width: "calc(100% /2 - 20px)",
    background: " #E9E9E9",
    margin: "10px 10px",
    borderRadius: '5px',
    transition: 'all 0.5s ease',
    //cursor:'pointer',
    display: 'flex',
    flexDirection: 'column',
    "&:hover": {
      transform: "scale(1.05)",

    },
  },

  DealerSectionCardImg: {
    background: "#36434c",
    padding: '5px 10px',
    paddingTop: '15px',
    borderRadius: '5px 5px 0px 0px ',
  },
  DealerSectionCardTextHolder: {

    padding: '5px 10px'

  },
  DealerSectionCardTextGroup: {

    padding: '5px 0px'

  },
  DealerSectionCardTextGroupHeading: {

    fontWeight: 'bold'

  },
  DealerSectionCardMap: {
    borderRadius: '0px 0px 5px 5px',
    height: '200px'

  },

  section: {
    width: "60%",

    "&:nth-child(1)": {
      marginRight: "40px",
      width: "40%",
      color: 'white',
    },
    "@media (max-width: 959px)": {
      width: "100%",
      margin: "15px",

      "&:nth-child(1)": {
        margin: "0px",
        padding: "0px 20px",
        width: "100%",
      },
    },
  },
  text: {
    fontSize: "16px",
    padding: "20px 0px",
  },
  textBold: {
    fontSize: "16px",
    fontWeight: "bold",
    marginTop: "20px",
  },
  titleTop: {
    fontSize: "44px",
    fontWeight: "bold",
  },
  titleTopAccent: {
    fontSize: "52px",
    color: "white",

    fontWeight: "regular",
  },
  titleAccent: {
    fontSize: "40px",
    color: "white",
    textAlign: "center",
    paddingBottom: "40px",
    fontWeight: "regular",
  },
  FormHolder: {
    background: 'white',
    padding: '20px',
    borderRadius: '5px',

  }
}));

const defaultColors = {

  secondaryColor: "#EFDF00",
};

const ContactUs = () => {
  const classes = useStyles();
  const { globalDealer, dealerList } = useContext(DealerContext);
  const [activeDealer, setActiveDealer] = useState();

  useEffect(() => {
    setActiveDealer(dealerList?.filter((d) => d.id == globalDealer?.id));
  }, [dealerList]);

  return (
    <div className={classes.root}>
      <div className={classes.innerSection}>
        <div className={classes.section}>
          <Typography variant="h1" className={classes.titleTop}>
            Contact KIA MOTORS
          </Typography>
          <Typography variant="h2" className={classes.titleTopAccent}>
            LEAVE US A MESSAGE
          </Typography>

          <Typography variant="body2" className={classes.text}>
            A member from our sales team will contact you shortly.
          </Typography>

          {/* <Typography variant="body2" className={classes.textBold}>
            Contact Us
          </Typography>

          <Typography variant="body2" className={classes.text}>
            Port Shepstone:<br/> Grant Gouveia - 0721828660 <br/><br/>
            Welkom:<br/> Barry Burger - 0824918106<br/><br/>
            Bethlehem:<br/> Nico Nieuwenhuizen - 0829243485<br/><br/>
            Upington:<br/> Alwyn Lubbe - 0727372043<br/>
          </Typography> */}
        </div>
        <div className={classes.section}>
          <div className={classes.FormHolder}>

            <Form
              base_uri={BASE_URI}
              leadTypeId={3}
              dealerId={globalDealer?.id}
              masterDealerId={MASTER_DEALERID}
              activeDealerId={globalDealer?.id}
              dealerName={globalDealer.id == MASTER_DEALERID ? "" : globalDealer?.name?.replace("Master", "")?.replace("Kia", "Group")}
              dealers={dealerList}
              dealerType="multi_dealer"
              websiteColors={defaultColors}
              note={true}
            />
          </div>
        </div>
      </div>
      <div className={classes.DealerSection}>
        <Typography variant="h2" className={classes.titleAccent}>
          Our Dealers
        </Typography>
        <div className={classes.DealerSectionInner}>



          {dealerList?.map((d, index) => {

            d.name = d.name.replace("LSG", "");
            return (
              <div className={classes.DealerSectionCard}>
                <div className={classes.DealerSectionCardImg}>
                  <img alt="KIA Logo" src={logoIcon} width="70px" />
                </div>

                <div className={classes.DealerSectionCardTextHolder} >

                  <div className={classes.DealerSectionCardTextGroup} >
                    <div className={classes.DealerSectionCardTextGroupHeading}>Branch</div>
                    <div>{d?.name}</div>
                  </div>


                  <div className={classes.DealerSectionCardTextGroup} >

                    <div className={classes.DealerSectionCardTextGroupHeading}>Address</div>
                    <div>{d?.address?.postalAddress}</div>
                  </div>



                  <div className={classes.DealerSectionCardTextGroup} >

                    <div className={classes.DealerSectionCardTextGroupHeading}>Contact</div>
                    <div>{d?.usedCarsPhoneNumber}</div>
                  </div>

                  {d?.name.trim() == "Kia Port Shepstone" && <div className={classes.DealerSectionCardTextGroup} >

                    <div className={classes.DealerSectionCardTextGroupHeading}>DP:</div>
                    <div>Grant Gouveia - 072 182 8660</div>
                  </div>
                  }

                  {d?.name.trim() == "Kia Welkom" && <div className={classes.DealerSectionCardTextGroup} >

                    <div className={classes.DealerSectionCardTextGroupHeading}>DP:</div>
                    <div>Barry Burger - 082 491 8106</div>
                  </div>
                  }

                  {d?.name.trim() == "Kia Bethlehem" && <div className={classes.DealerSectionCardTextGroup} >

                    <div className={classes.DealerSectionCardTextGroupHeading}>DP:</div>
                    <div>Nico Nieuwenhuizen - 082 924 3485</div>
                  </div>
                  }

                  {d?.name.trim() == "Kia Upington" && <div className={classes.DealerSectionCardTextGroup} >

                    <div className={classes.DealerSectionCardTextGroupHeading}>DP:</div>
                    <div>Alwyn Lubbe - 072 737 2043</div>
                  </div>
                  }

                  {/* <img src={`https://www.kiamotors.co.za/`+d?.websiteBannerImage} /> */}
                </div>
                <div className={classes.DealerSectionCardMap} >
                  <iframe title="Current dealer address" src={`https://maps.google.com/maps?q=${d.addressLongitude},${d.addressLatitude}&via=0&z=16&output=embed`} height="100%" width="100%" frameBorder="none"></iframe>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
