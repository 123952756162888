import React from "react";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { ImageContainer } from "../shared/ImageContainer";
import { CurrencyValue } from "../shared/TextMask";
import Button from "@material-ui/core/Button";
import { NavLink as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";
import AddIcon from "@material-ui/icons/Add";
import VehicleController from "./VehicleController";
import { BASE_URI, PAGE_SIZE, DEALERID, DEALERNAME } from "../shared/Constants";
import { SECONDARYCOLOR, BASECOLOR } from "../shared/Constants";

const useStyles = makeStyles((theme) => ({
  content: {
    padding: theme.spacing(10, 0, 6),
  },
  dividerTitle: {
    backgroundColor: `${SECONDARYCOLOR}`,
    width: "33%",
    marginBottom: "30px",
    height: "3px",
    margin: "20px auto",
  },
  bold: {
    fontWeight: 700,
  },
  title: {
    fontSize: "1.5rem",
    "&>span": {
      fontWeight: 400,
    },
  },
  vehicleContainer: {
    "& h5, & p": {
      color: "#7c7c7c",
    },
    "&:hover img, &:hover p, &:hover h5": {
      color: `${SECONDARYCOLOR}`,
      cursor: "pointer",
      textDecoration: "none",
    },
  },
  btn: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  image: {
    "& > div > div": {
      backgroundColor: `${BASECOLOR}`,
    },
  },
}));

const OurVehicles = (props) => {
  const classes = useStyles();
  const { loading, error, vehicles, total } = VehicleController();

  const cleanModel = (model) => {
    return model.toLowerCase().replace(/\//g, "-").replace(/ /g, "-");
  };

  return (
    <Container maxWidth="lg">
      <div className={classes.content}>
        <Typography
          gutterBottom
          variant="h5"
          align="center"
          color="textPrimary"
          fontWeight="500"
        >
          OUR VEHICLES
        </Typography>
        {!props.hide && 
     <>
        <Typography
          gutterBottom
          variant="h6"
          align="center"
          color="textPrimary"
          fontWeight="500"
        >
          It all starts with a passion for life
        </Typography>
       
        <Box mb={3} mt={3}>
          <Typography align="center" classes={{ root: classes.bold }}>
            DISCOVER {DEALERNAME}
          </Typography>
        </Box>
     </>
         }
        <Divider className={classes.dividerTitle} />
        <Grid container spacing={2}>
          {vehicles.map((vehicle, index) => {
            return (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Link
                  underline="none"
                  component={RouterLink}
                  to={"/models/" + cleanModel(vehicle.title) + "/" + vehicle?.ownedModelId}
                >
                  <Box className={classes.vehicleContainer}>
                    <Box className={classes.image}>
                      <ImageContainer
                        aspectratio={16 / 8}
                        height="100%"
                        src={vehicle.image}
                        alt={vehicle.title}
                      />
                    </Box>
                    <Box></Box>
                    <Box
                      mt={2}
                      mb={0}
                      textAlign="center"
                      component="h5"
                      className={`${classes.title} ${classes.bold}`}
                    >
                      {vehicle.title}
                    </Box>
                    <Box
                      mt={0}
                      mb={0}
                      textAlign="center"
                      component="h5"
                      className={`${classes.title} ${classes.bold}`}
                    >
                      <Box component="span">From</Box>
                      <CurrencyValue value={vehicle.price} />
                    </Box>
                    {!props.hide && 
                    <Box component="p" mt={0} mb={3} textAlign="center">
                      {"Single Model"}
                    </Box>
                    }
                   
                  </Box>
                </Link>
              </Grid>
            );
          })}
        </Grid>
      </div>
    </Container>
  );
};

export default OurVehicles;
