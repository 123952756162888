/* eslint-disable no-unused-vars */
/* eslint-disable no-fallthrough */
/* eslint-disable default-case */
/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Button, MenuItem, Checkbox } from "@material-ui/core";
import { Select, OutlinedInput, InputLabel, FormControl } from '@material-ui/core';
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  bold: {
    fontWeight: 700,
  },
  flexCard: {
    display: "flex",
    "@media (max-width: 900px)": {
      flexDirection: 'column',
    },
  },
  main: {
    display: "flex",
    flexWrap: 'wrap',
    justifyContent: 'end',
    "@media (max-width: 900px)": {
      display: "flex",
      flexDirection: 'column !important',
    },
  },
  orientColumn: {
    flexDirection: 'column',
  },
  orientRow: {
    flexDirection: 'row',
  },
  btn2Active: {
    //@ts-ignore
    minWidth: ({ orientation }) => `${orientation == "row" ? "95%" : "100%"}`,
    //@ts-ignore
    color: ({ btnText }) => `${btnText ? btnText : "black"} !important`,
    margin: '10px 0px',
    //@ts-ignore
    backgroundColor: ({ secondaryColor }) => `${secondaryColor} !important`,
    "@media (max-width: 900px)": {
      minWidth: "100%",
      margin: '10px 0px'
    },
    "&:hover": {
      //@ts-ignore
      backgroundColor: ({ secondaryColor }) => `${secondaryColor} !important`,
      opacity: 0.8,
    },
  },
  btn2ActiveClear: {
    background: 'red!important',
    margin: '10px 0px',
    //@ts-ignore
    minWidth: ({ orientation }) => `${orientation == "row" ? "95%" : "100%"}`,
    //@ts-ignore
    color: ({ btnText }) => `${btnText ? btnText : "black"} !important`,
    "@media (max-width: 900px)": {
      minWidth: "100%",
      margin: '10px 0px'
    },
  },
  btn2Inactive: {
    //@ts-ignore
    minWidth: ({ orientation }) => `${orientation == "row" ? "95%" : "100%"}`,
    //@ts-ignore
    color: ({ btnText }) => `${btnText ? btnText : "black"} !important`,
    margin: '10px 0px',
    //@ts-ignore
    backgroundColor: ({ secondaryColor }) => `${secondaryColor} !important`,
    opacity: '0.5',
    "@media (max-width: 900px)": {
      minWidth: "100%",
      margin: '10px 0px'
    },
  },
  content: {
    backgroundColor: theme.palette.background.paper,
  },
  headingHolder: {
    backgroundColor: "#06141f",
    textAlign: "center",
    marginBottom: "0px",
    color: "white",
    margin: "30px auto",
  },
  img: {
    width: "100%",
    height: "fit-content",
  },
  typoheading: {
    textAlign: "center",
    color: "textPrimary",
    fontWeight: 500,
  },
  search: {
    backgroundColor: '#f9f9f9',
    border: 'none',
    borderRadius: '0px',
    padding: '10px',
    color: 'black',
    width: '100%',
    '&:focus': {
      outline: 'none',
      border: 'none',
    }
  },
  searchHolder: {
    //@ts-ignore
    width: ({ orientation }) => `${orientation == "row" ? "25%" : "100%"}`,
    display: 'flex',
    marginBottom: '10px',
    justifyContent: 'end',
    "@media (max-width: 900px)": {
      width: "100%!important",
    },
  },
  filterApplied: {
    paddingBottom: "0.5rem",
  },
  appliedFilterText: {
    display: "inline-block",
    fontSize: "1rem",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  clearFilter: {
    textAlign: "right",
    cursor: "pointer",
    float: "right",
    color: "#ff2424",
  },
  appliedFilter: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: "1.15rem",
    margin: "5px",
  },
  marginSpacing: {
    width: "95%!important",
    "@media (max-width: 900px)": {
      width: "100%!important",
    },
  },
  flexHolder: {
    display: 'flex',
    justifyContent: 'end',

  },
  input: {
    margin: "5px 0px",
  },
  btnHolder: {
    display: 'flex',
    justifyContent: 'end',
  },
  inputlabel: {
    color: ({ labelColor }) => `${labelColor} !important`,
  }
}));

const menuProps = {
  PaperProps: {
    style: {
      maxHeight: 48 * 4.5 + 8,
      width: 250,
    },
  },
};

const defaultPriceRanges = [
  { min: 0, max: 100000 },
  { min: 100000, max: 200000 },
  { min: 200000, max: 300000 },
  { min: 300000, max: 400000 },
  { min: 400000, max: 500000 },
  { min: 500000, max: 600000 },
  { min: 600000, max: 700000 },
  { min: 700000, max: 800000 },
  { min: 800000, max: 900000 },
  { min: 900000, max: 1000000 },
  { min: 1000000, max: 1100000 },
  { min: 1100000, max: 1200000 }
];

const defaultInstallmentRanges = [
  { min: 1500, max: 2500 },
  { min: 2500, max: 3500 },
  { min: 3500, max: 4500 },
  { min: 4500, max: 6500 },
  { min: 6500, max: 7500 },
  { min: 7500, max: 8500 },
  { min: 8500, max: 9500 },
  { min: 9500, max: 20000 },
];

const UsedVehiclesFilter = ({
  base_uri,
  motorgroupId,
  pageSize = 20,
  websiteColors,
  transmissionHide,
  modelHide,
  hideList,
  instalmentsHide,
  dealerId,
  selectedModel,
  initialModel,
  updateFilteredVehicles,
  vehicles = [],
  orientation = "column",
  total,
  isLoading,
  multiSelect = false
}) => {
  const classes = useStyles({ ...websiteColors, orientation });
  const [filterApplied, setFilterApplied] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedMakes, setSelectedMakes] = useState([]);
  const [selectedModels, setSelectedModels] = useState(!initialModel ? [] : [initialModel]);
  const [selectedProvinces, setSelectedProvinces] = useState([]);
  const [selectedPriceRanges, setSelectedPriceRanges] = useState([]);
  const [selectedYears, setSelectedYears] = useState([]);
  const [selectedInstallments, setSelectedInstallments] = useState([]);
  const [selectedTransmissions, setSelectedTransmissions] = useState([]);

  const isOptionSelected =
    hasSelections(selectedMakes) ||
    hasSelections(selectedModels) ||
    hasSelections(selectedProvinces) ||
    hasSelections(selectedPriceRanges) ||
    hasSelections(selectedYears) ||
    hasSelections(selectedInstallments) ||
    hasSelections(selectedTransmissions);

  const makes = Array.from(new Set(vehicles.map((v) => toTitleCase(v?.brand))))
  const isSingleMake = makes.length === 1

  useEffect(() => {
    if (isOptionSelected) {
      filterVehicles();
    }
    const makes = Array.from(new Set(vehicles.map((v) => toTitleCase(v?.brand))))
      .sort();
    if (isSingleMake) {
      setSelectedMakes(makes);
    }
  }, [])

  useEffect(() => {
    if (total > 0) {
      pageVehicles(vehicles);
    }
  }, [total]);

  useEffect(() => {
    updateAndPageVehicles(vehicles);
  }, [vehicles]);

  useEffect(() => {
    if (!searchTerm.trim()) {
      updateAndPageVehicles(vehicles)
      filterVehicles();
      return;
    } else {
      let filtered = vehicles?.filter(v => (v.model?.toLowerCase()?.includes(searchTerm?.toLowerCase()) || v.stockNo?.toString()?.toLowerCase()?.includes(searchTerm?.toString()?.toLowerCase())));

      updateAndPageVehicles(filtered.length > 0 ? filtered : vehicles)
    }
  }, [searchTerm])

  const updateAndPageVehicles = (vehicles) => {
    const pagedVehicles = pageVehicles(vehicles);
    updateFilteredVehicles(pagedVehicles);

  }

  const getList = (
    makes = undefined,
    models = undefined,
    priceRanges = undefined,
    provinces = undefined,
    years = undefined,
    transmissions = undefined,
    installments = undefined,
    newUsed = undefined,
    body = undefined
  ) => {

    return vehicles
      .filter((v) => {
        return hasSelections(makes) ? makes?.some(make => v?.brand?.toUpperCase() === make?.toUpperCase()) : v;
      })
      .filter((v) => {
        return hasSelections(models) ? models?.some(modelRange => v?.modelRange?.toUpperCase() === modelRange?.toUpperCase()) : v;
      })
      .filter((v) => {
        return hasSelections(priceRanges) ? priceRanges?.some(priceRange => v?.price >= priceRange?.min && v?.price < priceRange?.max) : v;
      })
      .filter((v) => {
        return hasSelections(provinces) ? provinces?.some(province => v?.province?.toUpperCase() === province?.toUpperCase()) : v;
      })
      .filter((v) => {
        return hasSelections(years) ? years?.some(year => v?.year === year) : v;
      })
      .filter((v) => {
        return hasSelections(transmissions) ? transmissions?.some(transmission => v?.transmission?.toUpperCase() === transmission?.toUpperCase()) : v;
      })
      .filter((v) => {
        return hasSelections(installments)
          ? installments?.some(installment => calculateInstallment(v?.price) >= installment?.min && calculateInstallment(v?.price) < installment?.max)
          : v;
      })
      .filter((v) => {
        return newUsed ? v?.newUsed?.toUpperCase() === newUsed?.toUpperCase() : v;
      })
      .filter((v) => {
        return body ? v?.bodyType?.toUpperCase() === body?.toUpperCase() : v;
      });
  };

  const getPriceRanges = () => {
    try {
      const prices = Array.from(new Set(getList(selectedMakes, selectedModels).map((v) => v.price)));
      return defaultPriceRanges.filter(r => prices.some(p => p >= r.min && p < r.max));
    } catch {
      return defaultPriceRanges;
    }
  }

  const getInstallmentRanges = () => {
    try {

      const prices = Array.from(new Set(getList(selectedMakes, selectedModels, selectedPriceRanges, undefined, selectedYears, selectedTransmissions).map((v) => v.price)));
      return defaultInstallmentRanges.filter(r => prices.some(p => calculateInstallment(p) >= r.min && calculateInstallment(p) < r.max));

    } catch {
      return defaultInstallmentRanges;
    }
  }

  function filterVehicles() {

    setFilterApplied(true);

    const filteredList = getList(
      selectedMakes,
      selectedModels,
      selectedPriceRanges,
      undefined, //activeProvince,
      selectedYears,
      selectedTransmissions,
      selectedInstallments,
      undefined, //new|used
      undefined //body
    );

    updateAndPageVehicles(filteredList);

  }

  function clearFilter() {

    setSelectedMakes([]);
    setSelectedModels([]);
    setSelectedPriceRanges([]);
    setSelectedYears([]);
    setSelectedInstallments([]);
    setSelectedTransmissions([]);

    //clear filter values
    setFilterApplied(false);

    //reset vehicles list
    updateAndPageVehicles(vehicles);

  }

  function pageVehicles(tmpVehicles) {

    var numPages = tmpVehicles?.length / pageSize;
    var i = 0;
    var tmpPagesData = [];

    for (i = 0; i < numPages; i++) {
      const index = i * pageSize;
      tmpPagesData.push(tmpVehicles.slice(index, index + pageSize));
    }

    return tmpPagesData;

  }

  function calcPriceRanges(list) {

    const min = Math.min.apply(Math, list);
    const max = Math.max.apply(Math, list);

    let step = 0;
    var ranges = [];

    while (true) {
      if (min > step) {
        if (step < 100000) {
          step += 100000;
        } else {
          step += 100000;
        }
      } else {
        if (step >= 100000) {
          ranges.push({ min: step - 100000, max: step });
        } else {
          if (step === 0) {
            step += 100000;
            ranges.push({ min: 0, max: step });
          } else {
            ranges.push({ min: step - 100000, max: step });
          }
        }

        break;
      }
    }

    while (true) {
      if (step < max) {
        step += 100000;
        ranges.push({ min: step - 100000, max: step });
      } else {
        break;
      }
    }

    return ranges;

  }

  function hasSelections(selectionArr) {
    return (selectionArr && selectionArr.length > 0)
  }

  function setSelection(setterFn, selectionArr) {

    let newSelection = [];

    if (multiSelect === false) {

      newSelection = Array.isArray(selectionArr) ? [...selectionArr] : [selectionArr];

    } else if (hasSelections(selectionArr)) {
      //@ts-ignore
      newSelection = typeof selectionArr === 'string' ? selectionArr.split(',') : [...selectionArr];
    }

    setterFn(newSelection);

    return newSelection;

  }

  function clearSelection(selectionArr, selectedItem) {

    let i = selectionArr.findIndex(item => item == selectedItem);

    if (i >= 0) {
      selectionArr.splice(i, 1);
    }

    return selectionArr;

  }

  function handleChange(changeName) {

    /*
        NOTE:
        The break keyword is ommitted from each case statement in the switch such that each case
        block is executed successively depending on which dropdown called the function
     */

    let commonComparitor = (propName) => {
      return (v, c) => {
        //@ts-ignore
        return v[propName] == c;
      };
    };

    let rangeComparitor = (isInstallment = false) => {
      return (v, c) => {
        if (isInstallment === true) {
          return calculateInstallment(v.price) >= c.min && calculateInstallment(v.price) < c.max;
        } else {
          return v.price >= c.min && v.price < c.max;
        }
      };
    };

    switch (changeName) {
      case "make":

        removeInvalidSelections(getList(selectedMakes), setSelectedModels, selectedModels, commonComparitor("model"));

      case "model":

        removeInvalidSelections(getList(selectedMakes, selectedModels), setSelectedPriceRanges, selectedPriceRanges, rangeComparitor());

      case "range":

        removeInvalidSelections(getList(selectedMakes, selectedModels, selectedPriceRanges), setSelectedYears, selectedYears, commonComparitor("year"));

      case "year":

        removeInvalidSelections(getList(selectedMakes, selectedModels, selectedPriceRanges, undefined, selectedYears), setSelectedTransmissions, selectedTransmissions, commonComparitor("transmission"));

      case "transmission":

        removeInvalidSelections(getList(selectedMakes, selectedModels, selectedPriceRanges, undefined, selectedYears, selectedTransmissions), setSelectedInstallments, selectedInstallments, rangeComparitor(true));

        break;
    }
  }

  function removeInvalidSelections(filteredVehicles, setterFn, selectionArr, comparitor) {

    if (hasSelections(selectionArr)) {

      for (let i = 0; i < selectionArr.length; i++) {
        const selectedItem = selectionArr[i];

        //@ts-ignore
        if (!filteredVehicles.some((v) => comparitor(v, selectedItem))) {
          // Remove this item from the selection
          selectionArr.splice(i--, 1);
        }
      }

      setSelection(setterFn, selectionArr);

    }

  }

  function toTitleCase(str) {
    return str?.replace(/\w\S*/g, function (txt) {
      return txt?.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  function calculateInstallment(price) {
    return parseInt(Math.round(price / 60).toFixed(0));
  }

  function renderTransmissionOption(transmission) {

    switch (transmission?.charAt(0)) {
      case "A": return (<>Automatic</>);
      case "M": return (<>Manual</>);
      default: return (<>Other</>);
    }

  }

  return (
    <>
      <Grid item xs={12} spacing={2} className={`${classes.main} ${orientation == "row" ? classes.orientRow : classes.orientColumn}`}>
        {/* MAKES */}
        <Grid item xs={12} md={orientation == "row" ? 2 : 12} lg={orientation == "row" ? 2 : 12}>
          <FormControl fullWidth 
          className={orientation == "row" ? classes.marginSpacing : ""}
          >
            <InputLabel variant="standard" id="makes-label" 
            shrink={!multiSelect} style={{ padding: "0 15px" }}
            >
              {selectedMakes.length > 0 ? multiSelect === true ? "" : "Selected Make" : "All Makes"}
            </InputLabel>
            <Select
              labelId="makes-label"
              multiple={multiSelect}
              value={selectedMakes}
              variant="outlined"
              className={classes.input}
              onBlur={() => { if (multiSelect === false) handleChange("make") }}
              onClose={() => { if (multiSelect === true) handleChange("make") }}
              onChange={(e, c) => { setSelection(setSelectedMakes, e?.target?.value) }}
              renderValue={(val) => { return multiSelect === true ? `Select Makes (${val.length})` : val; }}
              MenuProps={menuProps}
              disabled={isSingleMake}
            >
              {
                Array.from(new Set(vehicles.map((v) => toTitleCase(v?.brand))))
                  .sort()
                  .map((option, i) => (
                    <MenuItem key={i} value={option}>
                      <Checkbox checked={selectedMakes.filter((v) => v == option).length == 1 ? true : false} />  {option}
                    </MenuItem>
                  ))
              }
            </Select>
          </FormControl>
          {!hideList &&
            <>
              {
                multiSelect === true && selectedMakes && selectedMakes.length > 0 &&
                selectedMakes.map((selectedMake, i) => (
                  <Grid className={classes.appliedFilter} key={`make${i}`}>
                    <span className={classes.appliedFilterText}>
                      {selectedMake}
                    </span>
                    {!isSingleMake && <span
                      className={classes.clearFilter}
                      title="Clear Selection"
                      onClick={() => {
                        clearSelection(selectedMakes, selectedMake);
                        setSelection(setSelectedMakes, selectedMakes.join());
                        handleChange("make");
                      }}
                    >
                      <CloseIcon />
                    </span>}
                  </Grid>
                ))
              }
            </>
          }
        </Grid>
        {!modelHide &&
          <Grid item xs={12} md={orientation == "row" ? 2 : 12} lg={orientation == "row" ? 2 : 12}>
            <FormControl fullWidth className={orientation == "row" ? classes.marginSpacing : ""}>
              <InputLabel variant="standard" id="models-label" shrink={!multiSelect} className={classes.inputlabel} style={{ padding: "0 15px" }}>
                {selectedModels.length > 0 ? multiSelect === true ? "" : "Selected Model" : "All Models"}
              </InputLabel>
              <Select
                labelId="models-label"
                multiple={multiSelect}
                variant="standard"
                className={classes.input}
                value={selectedModels}
                onBlur={() => { if (multiSelect === false) handleChange("model") }}
                onClose={() => { if (multiSelect === true) handleChange("model") }}
                onChange={(e, c) => { setSelection(setSelectedModels, e?.target?.value) }}
                input={<OutlinedInput />}
                //@ts-ignore
                renderValue={(val) => { return multiSelect === true ? `Select Models (${val.length})` : val; }}
              // MenuProps={menuProps}
              >
                {
                  Array.from(new Set(getList(selectedMakes).map((v) => v?.modelRange)))
                    .sort()
                    .map((option, i) => (
                      <MenuItem style={{ whiteSpace: 'pre-wrap', }} key={i} value={option}>
                        <Checkbox checked={selectedModels.filter((v) => v == option).length == 1 ? true : false} />  {option}
                      </MenuItem>
                    ))
                }
              </Select>
            </FormControl>
            {!hideList &&
              <>
                {
                  multiSelect === true && selectedModels && selectedModels.length > 0 &&
                  selectedModels.map((selectedModel, i) => (
                    <Grid className={classes.appliedFilter} key={`model${i}`}>
                      <span className={classes.appliedFilterText}>
                        {selectedModel}
                      </span>
                      <span
                        className={classes.clearFilter}
                        title="Clear Selection"
                        onClick={() => {
                          clearSelection(selectedModels, selectedModel);
                          setSelection(setSelectedModels, selectedModels.join());
                          handleChange("model");
                        }}
                      >
                        <CloseIcon />
                      </span>
                    </Grid>
                  ))
                }
              </>
            }
          </Grid>
        }
        {/* PRICE RANGES */}
        <Grid item xs={12} md={orientation == "row" ? 2 : 12} lg={orientation == "row" ? 2 : 12}>
          <FormControl fullWidth className={orientation == "row" ? classes.marginSpacing : ""}>
            <InputLabel id="priceRanges-label" shrink={!multiSelect} className={classes.inputlabel} style={{ padding: "0 15px" }}>
              {selectedPriceRanges.length > 0 ? multiSelect === true ? "" : "Selected Price Range" : "All Price Ranges"}
            </InputLabel>
            <Select
              labelId="priceRanges-label"
              multiple={multiSelect}
              className={classes.input}
              value={selectedPriceRanges}
              onBlur={() => { if (multiSelect === false) handleChange("range") }}
              onClose={() => { if (multiSelect === true) handleChange("range") }}
              onChange={(e, c) => { setSelection(setSelectedPriceRanges, e?.target?.value) }}
              input={<OutlinedInput />}
              //@ts-ignore
              renderValue={(val) => { return multiSelect === true ? `Select Price Ranges (${val.length})` : `R${val[0]?.min} - R${val[0]?.max - 1}`; }}
              MenuProps={menuProps}
            >

              {
                getPriceRanges().map((option, i) => (
                  //@ts-ignore
                  <MenuItem style={{ whiteSpace: 'pre-wrap', }} key={i} value={option}>
                    <Checkbox checked={selectedPriceRanges.filter((v) => v == option).length == 1 ? true : false} /> {`R${option?.min} - R${option?.max - 1}`}
                  </MenuItem>


                ))
              }
            </Select>
          </FormControl>
          {!hideList &&
            <>
              {
                multiSelect === true && selectedPriceRanges && selectedPriceRanges.length > 0 &&
                selectedPriceRanges.map((selectedPriceRange, i) => (
                  <Grid className={classes.appliedFilter} key={`range${i}`}>
                    <span className={classes.appliedFilterText}>
                      {/* @ts-ignore */}
                      {`R${selectedPriceRange?.min} - R${selectedPriceRange?.max - 1}`}
                    </span>
                    <span
                      className={classes.clearFilter}
                      title="Clear Selection"
                      onClick={() => {
                        clearSelection(selectedPriceRanges, selectedPriceRange);
                        setSelection(setSelectedPriceRanges, selectedPriceRanges);
                        handleChange("range");
                      }}
                    >
                      <CloseIcon />
                    </span>
                  </Grid>
                ))
              }
            </>}
        </Grid>

        {/* YEARS */}
        <Grid item xs={12} md={orientation == "row" ? 2 : 12} lg={orientation == "row" ? 2 : 12}>
          <FormControl fullWidth className={orientation == "row" ? classes.marginSpacing : ""}>
            <InputLabel id="years-label" shrink={!multiSelect} className={classes.inputlabel} style={{ padding: "0 15px" }}>
              {selectedYears.length > 0 ? multiSelect === true ? "" : "Selected Year" : "All Years"}
            </InputLabel>
            <Select
              labelId="years-label"
              multiple={multiSelect}
              value={selectedYears}
              className={classes.input}
              onBlur={() => { if (multiSelect === false) handleChange("year") }}
              onClose={() => { if (multiSelect === true) handleChange("year") }}
              onChange={(e, c) => { setSelection(setSelectedYears, e?.target?.value) }}
              input={<OutlinedInput />}
              //@ts-ignore
              renderValue={(val) => { return multiSelect === true ? `Select Years (${val.length})` : val; }}
              MenuProps={menuProps}
            >
              {
                Array.from(new Set(getList(selectedMakes, selectedModels, selectedPriceRanges).map((v) => v.year)))
                  .sort(function (a, b) {
                    return b - a;
                  })
                  .map((option, i) => (
                    <MenuItem style={{ whiteSpace: 'pre-wrap', }} key={i} value={option}>
                      <Checkbox checked={selectedYears.filter((v) => v == option).length == 1 ? true : false} />   {option}
                    </MenuItem>
                  ))
              }
            </Select>
          </FormControl>
          {!hideList &&
            <>
              {
                multiSelect === true && selectedYears && selectedYears.length > 0 &&
                selectedYears.map((selectedYear, i) => (
                  <Grid className={classes.appliedFilter} key={`year${i}`}>
                    <span className={classes.appliedFilterText}>
                      {selectedYear}
                    </span>
                    <span
                      className={classes.clearFilter}
                      title="Clear Selection"
                      onClick={() => {
                        clearSelection(selectedYears, selectedYear);
                        setSelection(setSelectedYears, selectedYears.join());
                        handleChange("year");
                      }}
                    >
                      <CloseIcon />
                    </span>
                  </Grid>
                ))
              }
            </>}
        </Grid>

        {/* TRANSMISSIONS */}
        {!transmissionHide &&


          <Grid item xs={12} md={orientation == "row" ? 2 : 12} lg={orientation == "row" ? 2 : 12}>
            <FormControl fullWidth className={orientation == "row" ? classes.marginSpacing : ""}>
              <InputLabel id="transmissions-label" shrink={!multiSelect} className={classes.inputlabel} style={{ padding: "0 15px" }}>
                {selectedTransmissions.length > 0 ? multiSelect === true ? "" : "Selected Transmission" : "All Transmissions"}
              </InputLabel>
              <Select
                labelId="transmissions-label"
                multiple={multiSelect}
                value={selectedTransmissions}
                className={classes.input}
                variant="standard"
                onBlur={() => { if (multiSelect === false) handleChange("transmission") }}
                onClose={() => { if (multiSelect === true) handleChange("transmission") }}
                onChange={(e, c) => { setSelection(setSelectedTransmissions, e?.target?.value) }}
                input={<OutlinedInput />}
                renderValue={(val) => { return multiSelect === true ? `Select Transmissions (${val.length})` : val }}
                MenuProps={menuProps}
              >
                {
                  Array.from(new Set(getList(selectedMakes, selectedModels, selectedPriceRanges, selectedProvinces, selectedYears).map((v) => v.transmission)))
                    .sort()
                    .map((option, i) => (
                      <MenuItem style={{ whiteSpace: 'pre-wrap', }} key={i} value={option}>
                        <Checkbox checked={selectedTransmissions.filter((v) => v == option).length == 1 ? true : false} />{
                          renderTransmissionOption(option)
                        }
                      </MenuItem>
                    ))
                }
              </Select>
            </FormControl>
            {!hideList &&
              <>
                {
                  multiSelect === true && selectedTransmissions && selectedTransmissions.length > 0 &&
                  selectedTransmissions.map((selectedTransmission, i) => (
                    <Grid className={classes.appliedFilter} key={`trans${i}`}>
                      <span className={classes.appliedFilterText}>
                        {renderTransmissionOption(selectedTransmission)}
                      </span>
                      <span
                        className={classes.clearFilter}
                        title="Clear Selection"
                        onClick={() => {
                          clearSelection(selectedTransmissions, selectedTransmission);
                          setSelection(setSelectedTransmissions, selectedTransmissions.join());
                          handleChange("transmission");
                        }}
                      >
                        <CloseIcon />
                      </span>
                    </Grid>
                  ))
                }
              </>}
          </Grid>
        }

        {/* INSTALLMENTS */}
        {!instalmentsHide &&
          <Grid item xs={12} md={orientation == "row" ? 2 : 12} lg={orientation == "row" ? 2 : 12}>
            <FormControl fullWidth className={orientation == "row" ? classes.marginSpacing : ""}>
              <InputLabel id="installment-label" shrink={!multiSelect} className={classes.inputlabel} style={{ padding: "0 15px" }}>
                {selectedInstallments.length > 0 ? multiSelect === true ? "" : "Selected Instalment" : "All Instalments"}
              </InputLabel>
              <Select
                labelId="installment-label"
                multiple={multiSelect}
                value={selectedInstallments}
                className={classes.input}
                onChange={(e, c) => { setSelection(setSelectedInstallments, e?.target?.value) }}
                input={<OutlinedInput />}
                //@ts-ignore
                renderValue={(val) => { return multiSelect === true ? `Select Instalments (${val.length})` : `R${val[0]?.min} - R${val[0]?.max - 1}`; }}
                MenuProps={menuProps}
              >
                {
                  getInstallmentRanges().sort().map((option, i) => (
                    //@ts-ignore
                    <MenuItem style={{ whiteSpace: 'pre-wrap', }} key={i} value={option}>
                      <Checkbox checked={selectedInstallments.filter((v) => v == option).length == 1 ? true : false} /> {`R${option?.min} - R${option?.max - 1}`}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
            {!hideList &&
              <>
                {
                  multiSelect === true && selectedInstallments && selectedInstallments.length > 0 &&
                  selectedInstallments.map((selectedInstallment, i) => (
                    <Grid className={classes.appliedFilter} key={`install${i}`}>
                      <span className={classes.appliedFilterText}>
                        {/* @ts-ignore */}
                        {`R${selectedInstallment?.min} - R${selectedInstallment?.max - 1}`}
                      </span>
                      <span
                        className={classes.clearFilter}
                        title="Clear Selection"
                        onClick={() => {
                          clearSelection(selectedInstallments, selectedInstallment);
                          setSelection(setSelectedInstallments, selectedInstallments);
                        }}
                      >
                        <CloseIcon />
                      </span>
                    </Grid>
                  ))
                }
              </>}
          </Grid>
        }
      </Grid>

      <div className={orientation == "row" ? classes.btnHolder : ""}>
        {/* FORM ACTIONS */}
        <Grid item xs={12} md={orientation == "row" ? 2 : 12} lg={orientation == "row" ? 2 : 12}>
          <Button
            className={isOptionSelected ? classes.btn2Active : classes.btn2Inactive}
            onClick={filterVehicles}
          >
            Apply
          </Button>
        </Grid>
        {
          filterApplied &&
          <Grid item xs={12} md={orientation == "row" ? 2 : 12} lg={orientation == "row" ? 2 : 12}>
            <Button
              className={classes.btn2ActiveClear}
              onClick={clearFilter}
            >
              Clear
            </Button>
          </Grid>
        }
      </div>

    </>
  );
};

export default UsedVehiclesFilter;
