import { useState, useEffect } from "react";
import axios from "axios";
import {
  BASE_URI,
  MOTORGROUPID,
  DEALERNAME,
  DEALERID,
} from "../shared/Constants";

export default function DealerDetailsController() {
  const [loading, setLoading] = useState(true);
  const [dealers, setDealers] = useState([]);
  const [dealerSocialMedia, setDealerSocialMedia] = useState({});

  useEffect(() => {
    let cancel;
    setLoading(true);
    setDealers([]);

    let url = `${BASE_URI}/Dealers/DealerContactDetailsByMotorgroup/${MOTORGROUPID}`;

    axios({
      method: "GET",
      url: url,
      cancelToken: new axios.CancelToken((c) => (cancel = c)),
    })
      .then((response) => {
        if (response?.data?.length > 0) {
          let relatedDealers = response?.data.filter(
            (x) => x.name.toLowerCase().indexOf(DEALERNAME.toLowerCase()) !== -1
          );
          setDealers(relatedDealers);
        }
        setLoading(false);

        //TODO: Missing Fields
        //dealer.businessHours
        //dealer.services
      })
      .catch((error) => {
        if (axios.isCancel(error)) return;
        setLoading(false);
      });
    return () => cancel();
  }, [MOTORGROUPID, DEALERNAME]);

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    setLoading(true);
    setDealerSocialMedia({});
    axios
      .get(`${BASE_URI}/DealerSocialMedia/${DEALERID}`, {
        cancelToken: source.token,
      })
      .then((response) => {
        setDealerSocialMedia(response?.data);
        setLoading(false);
      })
      .catch((error) => {
        if (axios.isCancel(error)) return;
        setLoading(false);
      });
  }, [DEALERID]);

  return { loading, dealers, dealerSocialMedia };
}
