import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { SECONDARYCOLOR, BASECOLOR } from "../shared/Constants";

const useStyles = makeStyles((theme) => ({
  promotionContainer: {
    position: "absolute",
    height: "100%",
    margin: "13% 33% 0px -32%;",

    padding: "0 1rem",
    "@media (max-width: 624px)": {
      position: "fixed",
      height: "auto",
      margin: "150px",
    },
  },
  content: {
    padding: "2rem",

    minWidth: "250px",
    " & > div": {
      color: `${BASECOLOR}`,
    },
  },
  vehicleName: {
    "@media (max-width: 426px)": {
      fontSize: "9px",
    },
    "& > h4": {
      fontWeight: 400,
      margin: "10px 0px",
    },
  },
  vehiclePrice: {
    "& > h4": {
      fontWeight: "lighter",
      margin: "10px 0px",
    },
  },
  callToAction: {
    width: "100%",
    background: "white",
    color: `${SECONDARYCOLOR}`,
    "&:hover": {
      color: "white",
      opacity: "1",
      background: `${SECONDARYCOLOR}`,
    },
  },
}));

const SlidePromotion = ({ vehicleName, tagline, price, callToAction, url }) => {
  const classes = useStyles();

  return (
    <Box className={classes.promotionContainer}>
      <Box className={classes.content}>
        <Box className={classes.vehicleName} variant="h4"></Box>
        <Box mb={2}>
          <b dangerouslySetInnerHTML={{ __html: tagline }}></b>
        </Box>

        <Box className={classes.vehiclePrice}>
          <Typography variant="h4">{price}</Typography>
        </Box>
        {!!callToAction?.trim() && (
          <Box mt={1}>
            <Button
              variant="contained"
              color="primary"
              href={url}
              className={classes.callToAction}
            >
              {callToAction}
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default SlidePromotion;
