import React, { useEffect} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { SECONDARYCOLOR } from '../shared/Constants'
const useStyles = makeStyles((theme) => ({
    background: {
        backgroundColor: 'black',
        padding: theme.spacing(1, 15, 1),
        display: 'flex',
        position:'fixed',
        alignItems: 'center',
        bottom: '0',
        width: '100%',
        '@media (max-width: 426px)': {
            padding: theme.spacing(1, 1, 1 ,1),
            flexDirection:'column',
        },
        '@media (min-width: 426px) and (max-width: 1024px)': {
            padding: theme.spacing(1, 1, 1 ,1),
            flexDirection:'column',
        },
    },
    btn: {
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center',
        width: '80%',
        padding: theme.spacing(1, 5, 1),
        background:`${SECONDARYCOLOR}`,
        color: 'white',
        border: 'none',
        borderRadius: '0px',
        marginRight:'10px',
        fontSize: '12px',
        whiteSpace:'nowrap',
        '@media (max-width: 426px)': {
            margin: theme.spacing(1, 1, 1 ,1),
            textAlign:'center'
        },
        '@media (min-width: 426px) and (max-width: 1024px)': {
            margin: theme.spacing(1, 5, 1 ,2),
            textAlign:'center'
        },
    },
    text: {
        color: 'white',
        margin: theme.spacing(1, 1, 1 ,1),
        width: '80%',
        fontSize:'14px',
        '@media (max-width: 426px)': {
            margin: theme.spacing(1, 1, 1 ,1),
            textAlign:'center'
        },
        '@media (min-width: 426px) and (max-width: 1024px)': {
            margin: theme.spacing(1, 5, 1 ,2),
            textAlign:'center'
        },
    },
    buttonHolder: {
        display: 'flex',
        padding: theme.spacing(0, 0, 0),
        height: '100%',
        alignItems: 'center',
        '@media (max-width: 426px)': {
            flexDirection:'column',
        },
    },
}));



let Banner;

export default function POPIAPopUp() {

    const writeCookie = (name, value, days) => {
        var date, expires;
        if (days) {
            date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toGMTString();

        } else {
            expires = "";
        }
        document.cookie = name + "=" + value + expires + "; path=/used-vehicles";

    }

    const getCookieValue = (a) => {

        var b = document.cookie.match('(^|;)\\s*' + a + '\\s*=\\s*([^;]+)');
        return b ? b.pop() : '';

    };
   
    const classes = useStyles();

    if (document.cookie !== null) {
        Banner = 
        <div className={classes.background}>
        <p className={classes.text}>We use cookies to ensure you get the best experience ou our website. Are you happy for us to use cookies?</p>
        <div className={classes.buttonHolder}>
        <button 
        className={classes.btn}>
            Ok
        </button>
        <button 
        className={classes.btn}>
            No
        </button>
        <button 
        className={classes.btn}>
            Cookie policy
        </button>
        </div>;
        </div>;
        } else {
            Banner = ""
      
        }

    return (
        <div>
            {Banner}
        </div>
    )
}
