import React, { useState, useContext, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { BASE_URI, DEALERID, HAS_MULTIPLE_DEALERSHIPS, DEALERNAME } from "./Constants"
import axios from "axios";
import Button from "@material-ui/core/Button";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { Switch } from 'formik-material-ui'
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { useSnackbar } from "notistack";
import { CircularProgress } from "material-ui";
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
import { FinanceContext } from './contexts/FinanceContext';
import MenuItem from "@material-ui/core/MenuItem";
import { NavLink as RouterLink } from 'react-router-dom'
import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme) => ({
    stepPadding: {
        marginLeft: "0.5rem",
        marginRight: "0.5rem",
        marginTop: "2rem",
    },
    stepPaddingBelow: {
        marginRight: "0.5rem",
        marginTop: "0.5rem",
    },
    stepPaddingButton: {
        marginTop: "0.5rem",
    },
    stepPaddingTitle: {
        marginTop: "1rem",
        marginBottom: "1rem",
    },
    card: {
        width: "100%"
    },
    flex: {
        display: "flex"
    },
    button: {
        width: "100%",
    },
    backButton: {
        marginTop: "2rem",
    },
    image: {
        minWidth: "100%",
        height: 550,
    },
    uppercase: {
        textTransform: 'uppercase',
        color: 'white'
    },
}));

export default function GetInfoContainer(props) {
    const classes = useStyles();
    const [creditCheck, setCreditCheck] = useState(false)
    const [licenseCheck, setLicenseCheck] = useState(false)
    const [debtReview, setDebtReview] = useState(false)
    const [tsAndCs, setTsAndCs] = useState(false)
    const [preQualSuccess, setPreQualSuccess] = useState(0)
    const { enqueueSnackbar } = useSnackbar();
    const [loader, setLoader] = useState(false)
    let { globalFinance, setGlobalLead, globalColor, globalModel } = useContext(FinanceContext);
    const dealers = [
        {
            id: 20,
            name: `${DEALERNAME} Bethlehem`
        },
        {
            id: 25,
            name: `${DEALERNAME} Upington`
        },
        {
            id: 26,
            name: `${DEALERNAME} Port Shepstone`
        },
        {
            id: 27,
            name: `${DEALERNAME} Welkom`
        }
    ];

    let idRegex = `(((\\d{2}((0[13578]|1[02])(0[1-9]|[12]\\d|3[01])|(0[13456789]|1[012])(0[1-9]|[12]\\d|30)|02(0[1-9]|1\\d|2[0-8])))|([02468][048]|[13579][26])0229))(( |-)(\\d{4})( |-)(\\d{3})|(\\d{7}))`
    let phoneRegExp = /^0(6|7|8){1}[0-9]{1}[0-9]{7}$/;

    const validationSchema = Yup.object({
        id: Yup.string("Enter an ID number").required(
            "ID number is required"
        ).matches(idRegex, "ID Number invalid"),
        name: Yup.string("Enter a name").required(
            "Name is required"
        ),
        email: Yup.string("Enter your email")
            .email("Enter a valid email")
            .required("Email is required"),
        phone: Yup.string("Enter your phone number")
            .required("Phone number is required")
            .matches(phoneRegExp, "Phone number is not valid")
    });

    let values = {
        id: "",
        netSalary: "",
        totalExpenses: "",
        name: "",
        surname: ""
    };

    const checkValid = (validForm) => {
        return (validForm && creditCheck && licenseCheck && !debtReview && tsAndCs)
    }

    function createLead(values) {

        var sourceId = 5;

        if (document.referrer) {
            if (document.referrer.indexOf("facebook") > -1) {
                sourceId = 2;
            }
        }

        setLoader(true);

        let params = {}

        params.leadStatusId = 1
        params.dealerId = !HAS_MULTIPLE_DEALERSHIPS ? DEALERID : values.dealerId
        params.name = values.name + ' ' + values.lastName
        params.firstName = values.name;
        params.lastName = values.lastName;
        params.phoneNumber = values.phone
        params.leadSourceId = sourceId
        params.TypeCode = "NEW"
        params.leadTypeId = 1
        params.notes = ""

        if (globalModel && globalModel.stockId) {
            params.stockId = globalModel.stockId;
            params.leadTypeId = 2;
        }

        let m = new Date()
        m = m.getUTCFullYear() + "-" + (m.getUTCMonth() + 1) + "-" + m.getUTCDate() + " " + (('0' + m.getHours()).slice(-2)) + ":" + (('0' + m.getMinutes()).slice(-2)) + ":" + (('0' + m.getSeconds()).slice(-2));
        params.dateUpdated = new Date().toISOString();

        params.emailAddress = values.email
        setGlobalLead(params);

        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        axios({
            method: 'POST',
            url: `${BASE_URI}/leads`,
            data: params,
            cancelToken: source.token
        }).then((response) => {
            values.leadId = response.data.id;

            if (globalModel && globalModel.variantId) {
                createVehicleLead(values);
            }
            else {
                submitForPQ(values);
            }

        }).catch(error => {
            if (axios.isCancel(error)) return
            enqueueSnackbar("Unable to complete the request", { variant: 'error' });
            setLoader(false);

        })
    }

    const createVehicleLead = (values) => {

        let vehicleParam = {}
        vehicleParam.LeadId = values.leadId;
        vehicleParam.VariantId = globalModel ? globalModel.variantId : 0;
        vehicleParam.OwnedVariantExteriorsId = globalColor ? globalColor.id : 0

        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        axios({
            method: 'POST',
            url: `${BASE_URI}/leadVehicles`,
            data: vehicleParam,
            cancelToken: source.token
        }).then((responseSecond) => {
            submitForPQ(values);
        }).catch(error => {
            if (axios.isCancel(error)) return
            enqueueSnackbar("Unable to get complete the request", { variant: 'error' });
            //setLoader(false);
        })
    }

    function submitForPQ(values) {

        let params = {}
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        params.idNumber = values.id;
        params.leadId = parseInt(values.leadId);
        params.netSalary = parseInt(values.netSalary);
        params.totalExpenses = parseInt(values.totalExpenses);
        params.desiredAmount = globalFinance ? globalFinance.purchasePrice : 162900;
        params.underDebtReview = debtReview;
        params.licenseCheck = licenseCheck;

        axios({
            method: 'POST',
            url: `${BASE_URI}/applications/pq`,
            data: params,
            cancelToken: source.token
        }).then((response) => {

            fbq('track', 'Lead');  // eslint-disable-line
            setLoader(false);

            window.location.href = response.data;

        }).catch(error => {
            if (axios.isCancel(error)) return
            enqueueSnackbar(`Unable to complete request. ${error}`, { variant: 'error' });
            setLoader(false);
        })
    }

    const handleSelectChange = (e, value, setFieldValue, fieldName, fieldId) => {
        setFieldValue(fieldName, e.target.value)
        setFieldValue(fieldId, value.props.id)
    }

    return (
        <React.Fragment>
            <MuiThemeProvider>
                <Grid container direction="row">
                    <Grid item xs={12}>
                        <Card className={classes.content} elevation={0}>
                            <CardContent>
                                <Formik
                                    initialValues={values}
                                    isInitialValid={false}
                                    validationSchema={validationSchema}
                                    onSubmit={async (values, actions) => {
                                        createLead(values)
                                    }}
                                >
                                    {(props) => {
                                        let {
                                            values: {
                                                id,
                                                netSalary,
                                                totalExpenses,
                                                name,
                                                lastName,
                                                phone,
                                                email,
                                            },
                                            touched,
                                            errors,
                                            handleBlur,
                                            isValid,
                                            submitForm,
                                            setFieldTouched,
                                            setFieldValue,
                                        } = props;
                                        return (
                                            <Form>
                                                <Grid container className={classes.root} direction="row">
                                                    <Grid item className={classes.stepPaddingButton} xs={12} md={12}
                                                        lg={12}>
                                                        <TextField
                                                            id="id"
                                                            name="id"
                                                            label="Your SA ID"
                                                            value={id}
                                                            fullWidth
                                                            variant="outlined"
                                                            helperText={touched.id ? errors.id : ""}
                                                            error={touched.id && Boolean(errors.id)}
                                                            onChange={(event) => {
                                                                setFieldValue("id", event.target.value)
                                                                setFieldTouched("id", true, false);
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item className={classes.stepPaddingButton} xs={12} md={12}
                                                    >
                                                        <TextField
                                                            id="name"
                                                            name="name"
                                                            label="First Name"
                                                            value={name}
                                                            fullWidth
                                                            variant="outlined"
                                                            helperText={touched.name ? errors.name : ""}
                                                            error={touched.name && Boolean(errors.name)}
                                                            onChange={(event) => {
                                                                setFieldValue("name", event.target.value)
                                                                setFieldTouched("name", true, false);
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item className={classes.stepPaddingButton} xs={12} md={12}
                                                    >
                                                        <TextField
                                                            id="lastName"
                                                            name="lastName"
                                                            label="Last Name"
                                                            value={lastName}
                                                            fullWidth
                                                            variant="outlined"
                                                            helperText={touched.lastName ? errors.lastName : ""}
                                                            error={touched.lastName && Boolean(errors.lastName)}
                                                            onChange={(event) => {
                                                                setFieldValue("lastName", event.target.value)
                                                                setFieldTouched("lastName", true, false);
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item className={classes.stepPaddingButton} xs={12} md={12}
                                                        lg={12}>
                                                        <TextField
                                                            id="email"
                                                            name="email"
                                                            type="email"
                                                            label="Email"
                                                            fullWidth
                                                            value={email}
                                                            variant="outlined"
                                                            helperText={touched.email ? errors.email : ""}
                                                            error={touched.email && Boolean(errors.email)}
                                                            onChange={(event) => {
                                                                setFieldValue("email", event.target.value)
                                                                setFieldTouched("email", true, false);
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item className={classes.stepPaddingButton} xs={12} md={12}
                                                        lg={12}>
                                                        <TextField
                                                            id=" phone"
                                                            name="phone"
                                                            type="tel"
                                                            label="Cellphone"
                                                            fullWidth
                                                            value={phone}
                                                            variant="outlined"
                                                            helperText={touched.phone ? errors.phone : ""}
                                                            error={touched.phone && Boolean(errors.phone)}
                                                            onChange={(event) => {
                                                                setFieldValue("phone", event.target.value)
                                                                setFieldTouched("phone", true, false);
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item className={classes.stepPaddingButton} xs={12}>
                                                        <TextField
                                                            id="netSalary"
                                                            name="netSalary"
                                                            label="Your Nett Salary"
                                                            value={netSalary}
                                                            fullWidth
                                                            variant="outlined"
                                                            helperText={touched.netSalary ? errors.netSalary : ""}
                                                            error={touched.netSalary && Boolean(errors.netSalary)}
                                                            onChange={(event) => {
                                                                setFieldValue("netSalary", event.target.value)
                                                                setFieldTouched("netSalary", true, false);
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item className={classes.stepPaddingButton} xs={12}>
                                                        <TextField
                                                            id="totalExpenses"
                                                            name="totalExpenses"
                                                            label="Your Total Expenses"
                                                            value={totalExpenses}
                                                            fullWidth
                                                            variant="outlined"
                                                            helperText={touched.totalExpenses ? errors.totalExpenses : ""}
                                                            error={touched.totalExpenses && Boolean(errors.totalExpenses)}
                                                            onChange={(event) => {
                                                                setFieldValue("totalExpenses", event.target.value)
                                                                setFieldTouched("totalExpenses", true, false);
                                                            }}
                                                        />
                                                    </Grid>
                                                    {HAS_MULTIPLE_DEALERSHIPS &&
                                                        <Grid item className={classes.stepPaddingButton} xs={12}>
                                                            <TextField
                                                                variant="outlined"
                                                                fullWidth
                                                                id="dealerId"
                                                                select
                                                                //required
                                                                label="Dealership"
                                                                value={values.dealerId}
                                                                helperText={errors.dealerId && touched.dealerId ? errors.dealerId : ''}
                                                                error={errors.dealerId && touched.dealerId}
                                                                onBlur={handleBlur("dealerId")}
                                                                onChange={(e, child) => handleSelectChange(e, child, setFieldValue, 'dealerId', 'dealerId')}
                                                            >
                                                                {dealers.map((option) => (
                                                                    <MenuItem key={option.id} id={option.id} value={option.name}>
                                                                        {option.name}
                                                                    </MenuItem>
                                                                ))}
                                                            </TextField>
                                                        </Grid>
                                                    }
                                                    <Grid item className={classes.flex} xs={12} md={12}
                                                        lg={12}>
                                                        <FormControlLabel
                                                            control={
                                                                <Field component={Switch}
                                                                    color="primary"
                                                                    type="checkbox"
                                                                    name="lisecenseCheck"
                                                                    onClick={() => {
                                                                        setLicenseCheck(licenseCheck ? false : true)
                                                                    }}
                                                                />
                                                            }
                                                        />
                                                          <span>
                                                          Do you have a valid South African Driver’s License? 
                                                        </span>
                                                    </Grid>
                                                    <Grid item className={classes.flex} xs={12} md={12}
                                                        lg={12}>
                                                        <FormControlLabel
                                                            control={
                                                                <Field component={Switch}
                                                                    color="primary"
                                                                    type="checkbox"
                                                                    name="creditCheck"
                                                                    onClick={() => {
                                                                        setCreditCheck(creditCheck ? false : true)
                                                                    }}
                                                                />
                                                            }
                                                        
                                                        />
                                                         <span>
                                                         Do you consent to a credit check?  
                                                        </span>
                                                    </Grid>
                                                    <Grid item className={classes.flex} xs={12} md={12}
                                                        lg={12}>
                                                        <FormControlLabel
                                                            control={
                                                                <Field component={Switch}
                                                                    color="primary"
                                                                    type="checkbox"
                                                                    name="debtReview"
                                                                    onClick={() => {
                                                                        setDebtReview(debtReview ? false : true)
                                                                    }}
                                                                />
                                                            }
                                                        />
                                                        <span>
                                                        Are you under debt review?   
                                                        </span>
                                                    </Grid>
                                                    <Grid item className={classes.flex} xs={12} md={12}
                                                        lg={12}>
                                                        <FormControlLabel
                                                            control={
                                                                <Field component={Switch}
                                                                    color="primary"
                                                                    type="checkbox"
                                                                    name="popi"
                                                                    onClick={() => {
                                                                        setTsAndCs(tsAndCs ? false : true)
                                                                    }}
                                                                />
                                                            }
                                                            
                                                        />
                                                       <span>
                                                       By submitting this form I agree to the <Link component={RouterLink} to="/terms" className={classes.link}>terms and conditions</Link> and <Link component={RouterLink} to="/privacy" className={classes.link}>privacy policies.</Link>   
                                                        </span>
                                                    </Grid>
                                                    <Grid container direction="row">
                                                        <Grid item className={classes.stepPaddingButton} xs={12} md={12}
                                                            lg={12}>

                                                            {!loader &&
                                                                <Button className={classes.button} variant={"contained"}
                                                                    color="primary"
                                                                    disabled={!checkValid(isValid) }
                                                                    onClick={() => {
                                                                        submitForm();
                                                                    }}
                                                                >
                                                                    Apply now
                                                        </Button>
                                                            }
                                                            {loader == true && (
                                                                <Button className={classes.button} variant={"contained"}
                                                                    color="primary"
                                                                    disabled={true}
                                                                >
                                                                    <CircularProgress />
                                                                </Button>
                                                            )}

                                                        </Grid>

                                                    </Grid>
                                                </Grid>
                                            </Form>
                                        );
                                    }}
                                </Formik>

                                {preQualSuccess === -1 && <Typography variant="body1" align={'center'} className={classes.uppercase}>Unfortunately, You do not qualify to purchase this vehicle.</Typography>}
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </MuiThemeProvider>
        </React.Fragment>
    );
}