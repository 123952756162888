import React, { useContext } from 'react';
import { Route, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import NavbarComponent from './NavbarComponent'
import HomeComponent from './homePage/HomeComponent'
import FooterComponent from './homePage/FooterComponent'
import ApplyFinance from './applyFinancePage/ApplyFinance'
import PreApplication from './applyFinancePage/PreApplication'
import PostMfc from './applyFinancePage/PostMfc'
import PQSuccess from './applyFinancePage/PQSuccess'
import PQDecline from './applyFinancePage/PQDecline'
import AfterSalesComponent from './afterSalesPages/AfterSalesComponent'
import SpecialDealsComponent from './specialDeals/SpecialDealsComponent'
import DetailedUsedVehicle from './DetailedUsedVehicle/DetailedUsedVehicle';
import CarProfileContainer from './carProfile/CarProfileContainer'
import CarProfileContainerDeals from './carProfile/CarProfileContainerDeals'
import UsedCar from './carProfile/UsedCar'
import OurVehicles from './ourVehiclesPage/OurVehicles';
import UsedVehicles from './usedVehicles/UsedVehicles';
import ContactUs from './contactUsPage/ContactUs';
import Service from './servicePage/Service';
import Parts from './partsPage/Parts';
import Terms from './legalPage/Terms';
import Privacy from './legalPage/Privacy';
import Cookies from './legalPage/Cookies';
import Popia from './legalPage/Popia';
import ScrollUpButton from "react-scroll-up-button";
import Complaints from "./legalPage/Complaints";
import WarrantyAndServicePlan from "./legalPage/Warranty-Service.js";
import ConflictsOfInterest from "./legalPage/Conflicts.js";
import UsedCarUsedVehiclesPage from "./usedCarUsedVehiclesPage/usedCarUsedVehiclesPage";
import BookATestDrive from './testDrivePage/TestDrive';
import { MetaTags } from '@red-build/leadcentre';
import { DealerContext } from "../components/shared/contexts/DealerContext";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& .MuiTextField-root": {
      margin: theme.spacing(2),
      width: "45ch",
    },

  },
  container: {
    // paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  appBarSpacer: {},
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
}));

export default function DashboardContainer() {
  const classes = useStyles();
  let { globalDealer } = useContext(DealerContext);
  const { pathname } = useLocation();

  return (
    <React.Fragment>
      <main>
        <NavbarComponent />
        <Route exact
          path="/"
          render={(props) => <HomeComponent />} />
        <Route exact path='/apply-for-finance' component={ApplyFinance} />
        <Route exact path='/apply-for-finance/done/:leadId' component={PreApplication} />
        <Route exact path='/pqComplete/leadId/:leadId/a/:a/i/:i' component={PostMfc} />
        <Route exact path='/pqSuccess/:leadId' component={PQSuccess} />
        <Route exact path='/:dealerId/0/buy/pqDecline/:leadId' component={PQDecline} />
        <Route path={['/maintenance', '/warranty', '/tomtom-live', '/service-plans', '/bodyshops', '/roadside-assistance']} render={props => <AfterSalesComponent {...props} />} />
        <Route exact
          path="/special-deals"
          component={SpecialDealsComponent} />
        {/*<Route exact path="/new/:model/" component={CarProfileContainer}/>*/}
        <Route exact path="/deal/:carId/:dealId?" component={CarProfileContainerDeals} />
        <Route exact path="/models/:carId/:ownedModelId?" component={CarProfileContainer} />
        <Route exact path="/requestergws" component={Popia} />
        <Route exact path="/stock/:stockId?" component={DetailedUsedVehicle} />
        <Route path='/our-vehicles' component={OurVehicles} />
        <Route exact path="/book-a-testdrive" render={(props) => <BookATestDrive />} />
        <Route exact path="/book-a-testdrive/:stockId" render={(props) => <BookATestDrive />} />
        <Route path='/used-vehicles' component={UsedCarUsedVehiclesPage} />
        <Route path='/contact-us' component={ContactUs} />
        <Route path='/service' component={Service} />
        <Route path='/parts' component={Parts} />
        <Route path='/terms' component={Terms} />
        <Route path='/privacy' component={Privacy} />
        <Route path='/cookies' component={Cookies} />
        <Route path='/complaints' component={Complaints} />
        <Route path='/warranty-and-service-plan' component={WarrantyAndServicePlan} />
        <Route path='/conflicts-of-interest' component={ConflictsOfInterest} />
        <FooterComponent />
        <ScrollUpButton />
      </main>
      <MetaTags
        dealer={globalDealer}
        pathname={pathname}
      />
    </React.Fragment>
  );
}