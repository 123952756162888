import React, { useState, useRef, useEffect, useContext } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import Box from "@material-ui/core/Box";
import PaymentDetails from "../shared/PaymentDetails";
import { ImageContainer } from "../shared/ImageContainer";
import Carousel from "../homePage/CarouselSingle";
import { SpecificationsComponent } from "../shared/SpecificationsComponent";
import { DealerContext } from '../shared/contexts/DealerContext'
import { useParams, useHistory, useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";
import axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";
import { BASE_URI } from "../shared/Constants";
import Link from "@material-ui/core/Link";
import Container from "@material-ui/core/Container";
import MenuItem from "@material-ui/core/MenuItem";
import { CurrencyValue } from "../shared/TextMask";
import { FinanceContext } from "../shared/contexts/FinanceContext";
import CheckIcon from "@material-ui/icons/Check";
import ApplySteps from "../shared/ApplySteps";
import TextField from "@material-ui/core/TextField";
import { Formik, Form } from "formik";
import { SECONDARYCOLOR, BASECOLOR } from "../shared/Constants";
import DealsFilter from "../specialDeals/DealsFilter";
import { useMetaTags } from '@red-build/leadcentre';

const useStyles = makeStyles((theme) => ({
  background: {

    padding: theme.spacing(10, 0, 6),
  },
  largeButton: {
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    justifyContent: "center",
    width: "100%",
    "& h6": {
      fontSize: "28.8px",
    },
  },
  buttonIconLg: {
    fontSize: "5rem",
    color: `${SECONDARYCOLOR}`,
  },
  sectionPadding: {
    margin: "3rem 0",
  },
  paragraphPadding: {
    padding: "1.5rem 3rem !important",
  },
  heading: {
    borderBottom: `5px solid ${SECONDARYCOLOR}`,
    textTransform: "capitalize",
    paddingBottom: ".5rem",
    fontWeight: "700",
    display: "inline-block",
  },
  yellowBorder: {
    borderBottom: `5px solid ${SECONDARYCOLOR}`,
  },
  secondaryHeading: {
    textTransform: "uppercase",
    paddingBottom: "1rem",
    fontWeight: "400",
  },
  slideMargin: {
    width: "calc(100% - 20px)",
    height: "calc(100% - 20px)",
    left: "10px",
    top: "10px",
  },
  topHeader: {
    fontWeight: 700,
    textTransform: "uppercase",
    textAlign: "center",
    fontSize: "2.5rem",
  },
  singleHeader: {
    position: "relative",
    paddingTop: "120px;",
  },
  yellow: {
    color: `${SECONDARYCOLOR}`,
  },
  specs: {
    "& .specs": {
      borderBottom: "0 !important",
      marginTop: "-25px",
    },
  },
  formControl: {
    minWidth: 400,
    "@media (max-width: 450px)": {
      minWidth: "100%",
    },
  },
  headerLine: {
    height: "1.5px",
    width: "50%",
    backgroundColor: `${SECONDARYCOLOR}`,
    padding: "0 !important",
  },
  smallHeading: {
    fontSize: "1.3rem",
  },
  stepPaddingColours: {
    marginLeft: "1em",
    marginRight: "1em",
    marginTop: "0.5em",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  colorsContainer: {
    display: "flex",
    overflow: "auto",
    "& > div:first-child": {
      marginLeft: 0,
    },
  },
  colourName: {
    textTransform: "uppercase",
    fontSize: "0.8rem",
    marginTop: "0.6rem",
  },
  colourPrice: {
    fontWeight: 700,
  },
  stepOneButtons: {
    display: "flex",
    "@media (max-width: 500px)": {
      flexDirection: "column",
      alignItems: "center",
      "& > a": {
        marginLeft: "0 !important",
        marginRight: "0 !important",
      },
    },
  },
  topDetailSection: {
    "@media screen and (max-width: 959px)": {
      display: "flex",
      flexDirection: "column",
      "& > div:first-child": {
        order: 2,
        marginTop: "2rem",
      },
      "& > div:nth-child(2)": {
        order: 3,
      },
      "& > div:nth-child(3)": {
        order: 4,
      },
      "& > div:nth-child(4)": {
        marginTop: "-1.5rem",
      },
    },
  },
}));

export default function CarProfileContainerDeals(props) {
  const classes = useStyles();
  let { carId, dealId } = useParams();
  const history = useHistory();
  const { state } = props.location;
  const [colors, setColours] = useState([]);
  const [loading, setLoading] = useState(true);
  const [colorsLoading, setColorsLoading] = useState(true);
  const mountedRef = useRef(true);
  const { enqueueSnackbar } = useSnackbar();
  const [financeOptions, setFinanceOptions] = useState([]);
  const [financeDetails, setFinanceDetails] = useState({});
  const [headline, setHeadline] = useState("");
  const [variantList, setVariantList] = React.useState([]);
  const [modelInfo, setModelInfo] = useState(null);
  const [modelData, setModelData] = useState(null);
  const [variantId, setVariantId] = React.useState(0);
  const [colorData, setColorData] = React.useState([]);
  const [selectedColor, setSelectedColor] = React.useState();
  const [colorId, setColorId] = React.useState(0);
  const [relatedModel, setRelatedModel] = useState(null);
  const [activeDeal, setActiveDeal] = useState([]);
  const { deals } = DealsFilter();
  const { updateMetaTags } = useMetaTags();
  const { pathname } = useLocation();

  const { globalDealer } = useContext(DealerContext);

  useEffect(() => {
    setActiveDeal(deals.filter((d) => d.offerId == dealId)[0])
    setModelInfo(deals.filter((d) => d.offerId == dealId)[0])
    setRelatedModel(deals.filter((d) => d.offerId == dealId)[0])
    setModelData(deals.filter((d) => d.offerId == dealId)[0])

  }, [dealId, deals])

  let {
    setGlobalFinance,
    setGlobalVehicle,
    setGlobalColor,
    setGlobalVariantId,
    setGlobalModel,
    globalModel,
    globalVehicles,
  } = useContext(FinanceContext);

  const values = {
    model: "",
    variantId: "",
  };

  const handleModelChange = async (e, value, setFieldValue) => {
    setFieldValue("model", e.target.value);
    setFieldValue("variantId", value.props.id);
    setVariantId(parseInt(value.props.id));
    var variant = variantList.filter((itm) => itm.id == value.props.id)[0];

    setModelData({
      price: variant.priceRange,
      id: variant.modelId,
    });

    var tmpFinanceDetails = {
      ...financeDetails,
      originalPrice: variant.priceRange,
      purchasePrice: variant.priceRange,
    };

    let monthly = calculateMonthly(tmpFinanceDetails);

    tmpFinanceDetails = { ...tmpFinanceDetails, monthlyInstallment: monthly };

    setFinanceDetails(tmpFinanceDetails);
    setGlobalFinance(tmpFinanceDetails);
    setGlobalModel(variant);
  };

  const calculateMonthly = (data) => {
    var deposit = data.purchasePrice * (data.deposit / 100);
    var totalPrice = data.purchasePrice;
    var initFee = 0;
    var principal = totalPrice - deposit + initFee;

    var balloonPerc = data.balloonPayment;

    var balloonAmt = (totalPrice * balloonPerc) / 100;

    var interestRate = data.rate;
    var interestPM = interestRate / 100 / 12;

    var repaymentPeriod = data.term;
    var days = 1;

    var v = 1 / (1 + interestPM);
    var d = 1 - v;
    var y = Math.pow(v, repaymentPeriod - 1);
    var comp = (1 - y) / d;
    var fp = principal * Math.pow(1 + interestPM, days / (365 / 12).toFixed());
    var monthly = 0;

    if (parseInt(balloonAmt) > 0) {
      var comp = (1 - Math.pow(v, repaymentPeriod - 1)) / d;
      monthly = (fp - balloonAmt * Math.pow(v, repaymentPeriod - 1)) / comp;
    } else {
      var comp = (1 - Math.pow(v, repaymentPeriod)) / d;
      monthly = fp / comp;
    }

    return round(monthly) + initFee;
  };

  function round(x) {
    return Math.round(x * 100) / 100;
  }



  useEffect(() => {
    if (!variantId || variantId == 0) {
      return;
    }

    setGlobalVariantId(variantId);
    setColorsLoading(true);
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const result = axios
      .get(`${BASE_URI}/colours/exteriors?dealerVariantId=${variantId}`, {
        cancelToken: source.token,
      })
      .then((result) => {
        let data = result?.data?.list;
        setColorData(data);

        setColours(
          data.map((itm) => {
            return { img: itm.image, id: itm.id };
          })
        );
        setColorsLoading(false);
      })
      .catch((error) => {
        console.error(
          "Failed to get exterior colors for variant ID:",
          variantId,
          error
        );
        source.cancel();
        setColorsLoading(false);
      });
  }, [variantId]);

  useEffect(() => {
    if (!modelInfo || !modelData) {
      return;
    }
    const CancelToken = axios.CancelToken;
    setLoading(true);
    const source = CancelToken.source();
    const getOffer = async () => {
      try {
        const result = await axios.get(`${BASE_URI}/Offers/${dealId}`, {
          cancelToken: source.token,
        });
        let data = result.data;

        setHeadline(result.data.headline);
        let tmpFinanceOptions = [];

        tmpFinanceOptions.push({
          variant: result.data.variant,
          variantId: result.data.variantId,
          monthlyInstallment: result.data.monthlyInstallment,
          id: result.data.id,
          model: result.data.model,
        });

        setFinanceOptions(tmpFinanceOptions);

        let tmpFinanceDetails = {
          purchasePrice: data.price,
          monthlyInstallment: data.monthlyInstallment,
          term: data.term,
          rate: data.rate,
          deposit: data.deposit,
          balloonPayment: data.balloonPayment,
          termsAndConditions: data.termsAndConditions,
          dealDescription: data.metaDescription,
        };

        setFinanceDetails(tmpFinanceDetails);
        setGlobalFinance(tmpFinanceDetails);
      } catch (error) {
        setLoading(false);
        enqueueSnackbar("Unable to get offer details", { variant: "error" });
      }
    };

    if (dealId) {
      getOffer().then(() => {
        window.scrollTo(0, 0);

        setLoading(false);

        if (!mountedRef.current) return null;
      });
    } else {
      let financeDetails = {
        balloonPayment:
          modelInfo.dealer?.balloonPayment ?? modelInfo.deal.balloonPayment,
        deposit: modelInfo.dealer?.deposit ?? modelInfo.deal?.deposit,
        rate: modelInfo.dealer?.offerInterestRate ?? modelInfo.deal?.rate,
        term: 72,
        purchasePrice: modelData.price,
        specs: modelInfo.features,
      };

      let monthly = calculateMonthly(financeDetails);
      financeDetails.monthlyInstallment = monthly;

      let financeOptions = {
        id: 0,
        modelId: modelData.id,
        monthlyInstallment: monthly || 0,
      };

      setFinanceDetails(financeDetails);
      setGlobalFinance(financeDetails);
      setFinanceOptions(financeOptions);

      setLoading(false);
    }

    return () => {
      mountedRef.current = false;
      source.cancel();
    };
  }, [carId, dealId, modelInfo, modelData]);

  useEffect(() => {
    setSelectedColor(null);
    if (colorId == 0) {
      return;
    }

    var tmpFinanceDetails;
    var color = colorData.filter((itm) => itm.id == colorId)[0];

    if (color.price > 0) {
      tmpFinanceDetails = { ...financeDetails, purchasePrice: color.price };
    } else {
      tmpFinanceDetails = {
        ...financeDetails,
        purchasePrice: modelData.price,
      };
    }

    let monthly = calculateMonthly(tmpFinanceDetails);

    tmpFinanceDetails = { ...tmpFinanceDetails, monthlyInstallment: monthly };

    setFinanceDetails(tmpFinanceDetails);
    setGlobalFinance(tmpFinanceDetails);

    setGlobalColor(color);
    setSelectedColor(color);
  }, [colorId]);

  const setActiveColor = (index) => {
    setColorId(colors[index].id);
  };

  const padZero = (str, len) => {
    len = len || 2;
    let zeros = new Array(len).join("0");
    return (zeros + str).slice(-len);
  };

  const invertColor = (hex) => {
    if (hex.indexOf("#") === 0) {
      hex = hex.slice(1);
    }
    // convert 3-digit hex to 6-digits.
    if (hex.length === 3) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    if (hex.length !== 6) {
      throw new Error("Invalid HEX color.");
    }
    // invert color components
    let r = (255 - parseInt(hex.slice(0, 2), 16)).toString(16),
      g = (255 - parseInt(hex.slice(2, 4), 16)).toString(16),
      b = (255 - parseInt(hex.slice(4, 6), 16)).toString(16);
    // pad each with zeros and return
    return "#" + padZero(r) + padZero(g) + padZero(b);
  };

  const stepsState = {
    one: {
      active: true,
      completed: false,
    },
    two: {
      active: true,
      completed: false,
    },
    three: {
      active: true,
      completed: false,
    },
  };

  const getContentImage = (type) => {
    return modelInfo?.contentImages && modelInfo?.contentImages?.length > 0
      ? modelInfo.contentImages.filter((x) => x.imageName == type)[0].image
        .imageUrl
      : "";
  };

  const getMainImage = () => {
    var image = getContentImage("main");

    if (selectedColor) {
      image = selectedColor.image;
    }

    return activeDeal.imageUrl;
  };

  const getGalleryImages = () => {
    if (modelInfo?.galleryImages && modelInfo?.galleryImages.length > 0) {
      return modelInfo.galleryImages.map((x) => x.image.imageUrl);
    }
    return [];
  };

  // Meta Tags
  useEffect(() => {
    const headline = activeDeal?.headline;
    const variant = activeDeal?.variant?.substring(0, activeDeal?.variant?.indexOf("(") - 1);
    const price = activeDeal?.price || '';
    const description = activeDeal?.introParagraph;
    const imageUrl = activeDeal?.imageUrl;
    const dealerName = globalDealer?.name && typeof globalDealer.name === 'string' ? globalDealer.name.replace('Master', '') : '';

    updateMetaTags({
      pathname: pathname,
      title: [headline, variant, 'Special Deals', dealerName],
      description: [headline, variant, price, dealerName, description],
      image: imageUrl,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeDeal]);

  return (
    <React.Fragment>
      <main>
        <Container maxWidth="lg">
          {loading && (
            <Grid
              item
              container
              justifyContent="center"
              justify="center"
              alignContent="center"
              xs={12}
            >
              <CircularProgress size={80} />
            </Grid>
          )}
          {!loading && !modelInfo && (
            <Typography
              gutterBottom
              variant="h4"
              color="textPrimary"
              fontWeight="500"
              className={classes.singleHeader}
            >
              <Box mb={5} className={classes.topHeader}>
                {activeDeal?.id > 0 || relatedModel?.id > 0
                  ? `Please load brochure information for the "${activeDeal?.model ?? relatedModel?.title
                  }" in DASH`
                  : "No linked dealer model found"}
              </Box>
            </Typography>
          )}
          {!loading && modelInfo && (
            <div className={classes.background}>
              <Typography
                gutterBottom
                variant="h4"
                color="textPrimary"
                fontWeight="500"
              >
                <Box mb={5} className={classes.topHeader}>
                  {!dealId ? (
                    <span>
                      <span className={classes.yellow}>Step 1</span> let's
                      <span className={classes.yellow}>configure</span> your car
                    </span>
                  ) : (
                    <span>{financeDetails.dealDescription}</span>
                  )}
                </Box>
              </Typography>

              <Grid container spacing={4} style={{ marginBottom: "1rem" }}>
                <Grid item xs={12} md={6}>
                  <Box style={{ padding: " 0 0.6rem" }}>
                    {selectedColor ? (
                      <ImageContainer
                        aspectratio={16 / 10}
                        src={getMainImage()}
                        alt="MainImage"
                      />
                    ) : (
                      <ImageContainer
                        aspectratio={16 / 6}
                        src={getMainImage()}
                        alt="MainImage"
                      />
                    )}
                  </Box>
                  {colors.length > 0 && (
                    <Box style={{ marginBottom: "1rem" }}>
                      {
                        <Carousel
                          naturalSlideWidth={100}
                          naturalSlideHeight={60}
                          totalSlides={colors.length}
                          visibleSlides={4}
                          isPlaying={true}
                          infinite
                          innerClass={classes.slideMargin}
                          slides={colors}
                          onSlideClick={(index) => setActiveColor(index)}
                        />
                      }
                    </Box>
                  )}
                  <Box className={classes.specs}>

                  </Box>
                  <Box>
                    <Card
                      style={{
                        marginTop: "1.5rem",
                        border: "1px solid #36434c",
                        padding: "1rem",
                      }}
                    >
                      <Typography variant="h4" align="center">
                        Apply online in 3 easy steps
                      </Typography>
                      <ApplySteps disabledStates={stepsState} />
                    </Card>
                  </Box>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Formik
                    initialValues={values}
                    isInitialValid={false}
                    onSubmit={(values, actions) => {
                      setTimeout(() => {
                        actions.setSubmitting(true);
                      }, 100);
                    }}
                  >
                    {(props) => {
                      const {
                        values,
                        touched,
                        errors,
                        handleBlur,
                        handleChange,
                        isSubmitting,
                        handleReset,
                        setFieldValue,
                      } = props;

                      return (
                        <Form>
                          <Box mt={2} ml={1}>
                            <Typography className={classes.smallHeading}>
                              {![0, 5].includes(activeDeal?.leadTypeId) && (
                                <p>{activeDeal?.model}</p>
                              )}
                            </Typography>
                            <Typography variant="h4" color="textPrimary" >
                              <strong>
                                <CurrencyValue
                                  value={activeDeal.isCashDeal ? financeDetails.purchasePrice : financeDetails.monthlyInstallment}
                                />
                                {activeDeal.isCashDeal ? "" : "pm"}
                              </strong>
                            </Typography>
                          </Box>

                          <div className={classes.headerLine}></div>

                          {!dealId && (
                            <Box style={{ marginTop: 24 }}>
                              <TextField
                                fullWidth
                                id="model"
                                variant="outlined"
                                select
                                required
                                label="Select a model"
                                value={values.model}
                                helperText={
                                  errors.model && touched.model
                                    ? errors.model
                                    : ""
                                }
                                error={errors.model && touched.model}
                                onBlur={handleBlur("make")}
                                onChange={(e, child) => {
                                  handleModelChange(e, child, setFieldValue);
                                }}
                              >
                                {variantList &&
                                  variantList.map((option) => (
                                    <MenuItem
                                      key={option.id}
                                      id={option.id}
                                      value={option.title}
                                    >
                                      {option.title} (From
                                      <CurrencyValue
                                        value={option.priceRange}
                                      />
                                      )
                                    </MenuItem>
                                  ))}
                              </TextField>
                            </Box>
                          )}
                          {!colorsLoading && colorData && colorData.length > 0 && (
                            <Box style={{ marginTop: 24 }}>
                              <Typography className={classes.smallHeading}>
                                Select Colour
                              </Typography>
                              <Box className={classes.colorsContainer}>
                                {colorData.map((color) => {
                                  return (
                                    <Box
                                      className={classes.stepPaddingColours}
                                      key={`color-${color.id}`}
                                    >
                                      <Button
                                        style={{
                                          backgroundColor: `${color.colour}`,
                                          height: "60px",
                                          width: "60px",
                                          minWidth: "60px",
                                          borderRadius: "50%",
                                        }}
                                        onClick={(v) => {
                                          setColorId(color.id);
                                        }}
                                        variant={"contained"}
                                      >
                                        {colorId === color.id && (
                                          <CheckIcon
                                            style={{
                                              color: invertColor(
                                                `${color.colour}`
                                              ),
                                            }}
                                          />
                                        )}
                                      </Button>
                                      <Typography
                                        className={classes.colourName}
                                      >
                                        {color.title}
                                      </Typography>
                                      <Typography
                                        className={classes.colourPrice}
                                      >
                                        {/* <CurrencyValue value={colour.price} /> */}
                                      </Typography>
                                    </Box>
                                  );
                                })}
                              </Box>
                            </Box>
                          )}
                          <Box display="flex" mt={2} ml={1}>
                            <Typography variant="h5" color="textPrimary">
                              <strong>QUICK SUMMARY</strong>
                            </Typography>
                          </Box>
                          <p style={{ marginLeft: '7px' }}>{activeDeal.introParagraph}</p>

                          {!activeDeal.isCashDeal ?

                            <PaymentDetails
                              displayPrice={false}
                              details={financeDetails}
                            /> : <></>
                          }
                          <Box
                            mb={3}
                            mt={3}
                            className={classes.stepOneButtons}
                            style={{ width: "initial", display: "flex" }}
                          >
                            {!activeDeal.isCashDeal ? <Link style={{ margin: "0 10px 10px 0" }}>
                              <Button
                                variant="contained"
                                gutterBottom
                                onClick={() => {
                                  history.push(`/apply-for-finance`);
                                }}
                                color="primary"
                                disabled={activeDeal?.variantId ? false : true && colorId <= 0 ? true : false}
                              >
                                Pre-Qualify Now
                              </Button>
                            </Link>

                              : <></>}

                            <Link style={{ margin: "0 10px 10px 0" }} >
                              <Button
                                variant="contained"
                                gutterBottom
                                onClick={() => {
                                  history.push(`/contact-us`, activeDeal?.model ? activeDeal?.model : globalModel);
                                }}
                                color="primary"
                                disabled={activeDeal?.variantId ? false : true && colorId <= 0 ? true : false}
                              >
                                Request Call back
                              </Button>
                            </Link>
                          </Box>
                          <Box>
                            <Typography
                              style={{ marginTop: ".5rem" }}
                              variant="caption"
                              color="textSecondary"
                            >
                              {financeDetails.termsAndConditions}
                            </Typography>
                          </Box>
                        </Form>
                      );
                    }}
                  </Formik>
                </Grid>
              </Grid>

              <Grid container className={classes.sectionPadding}>
                <Grid item xs={12}>
                  <ImageContainer
                    aspectratio={16 / 6}
                    src={activeDeal.imageUrl}
                    alt="MainImage"
                  />
                </Grid>
              </Grid>

              <Grid
                container
                spacing={4}
                style={{ marginTop: "3rem", marginBottom: "3rem" }}
              >
                <Grid
                  item
                  container
                  alignContent="center"
                  justify="center"
                  xs={12}
                >
                  <Typography
                    className={classes.heading}
                    align="center"
                    id="discover"
                    gutterBottom
                    variant="h4"
                    color="textPrimary"
                    fontWeight="500"
                  >
                    {modelInfo?.introductionTitle}
                  </Typography>
                </Grid>


              </Grid>
              {false && (
                <div>
                  <Grid
                    container
                    alignContent="center"
                    justify="center"
                    className={classes.sectionPadding}
                  >
                    <Grid
                      item
                      xs={8}
                      className={classes.yellowBorder}
                      md={4}
                    ></Grid>
                  </Grid>
                </div>
              )}

            </div>
          )}
        </Container>
      </main>
    </React.Fragment>
  );
}
